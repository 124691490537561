// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3ReroQKIkyfIV6Inh1MAlf {\n    margin-right: 16px;\n    margin-bottom: 40px;\n}\n\n._19HkbEONTrmhiC7qQStjpD {\n    align-self: center;\n    line-height: 28px;\n    font-weight: bold;\n    font-size: 20px;\n    margin: 0;\n}\n\n._2bEiklQV1HpgzzOg5q7y2V {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 40px;\n}\n\n._2SwfN1-9nx-VYcr1T6UcX {\n    display: flex;\n    justify-content: flex-end;\n    gap: 12px;\n    width: 100%;\n    margin: 0 16px;\n}\n", ""]);
// Exports
exports.locals = {
	"property": "_3ReroQKIkyfIV6Inh1MAlf",
	"title": "_19HkbEONTrmhiC7qQStjpD",
	"title-row": "_2bEiklQV1HpgzzOg5q7y2V",
	"button-row": "_2SwfN1-9nx-VYcr1T6UcX"
};
module.exports = exports;
