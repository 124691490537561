// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2YuTiCeCxYfR19720CAOOU {\n    margin-bottom: 32px;\n}\n\n._2YuTiCeCxYfR19720CAOOU > svg:not(:first-of-type) {\n    margin-left: 24px;\n}\n\n._2Lo12gl7Ojz17Ach1bY_1U {\n    margin-bottom: 24px;\n    font-size: 24px;\n    font-weight: 700;\n}\n\n.yiWkxcKrZNn3Fr9a2CpwG {\n    font-size: 20px;\n}", ""]);
// Exports
exports.locals = {
	"icon-container": "_2YuTiCeCxYfR19720CAOOU",
	"title": "_2Lo12gl7Ojz17Ach1bY_1U",
	"text": "yiWkxcKrZNn3Fr9a2CpwG"
};
module.exports = exports;
