// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2uHxRni15jDakPrDFohbIW {\n    display: flex;\n    flex-direction: row;\n    gap: 1rem;\n    margin-bottom: 2rem;\n\n    h2 {\n        margin: 0;\n    }\n}", ""]);
// Exports
exports.locals = {
	"container": "_2uHxRni15jDakPrDFohbIW"
};
module.exports = exports;
