import React, { useCallback, useState } from 'react'
import { useAtom, useAtomValue } from 'jotai/react'
import {
  DataProperty,
  ModalController,
  Modal,
  useToaster,
  ToastDuration,
  ToastColor,
  Label,
} from '@extend/zen'
import { DatePicker } from '@helloextend/merchants-ui'
import type { ServiceOrder } from '@helloextend/extend-api-client'
import { isStartRepairModalVisibleAtom } from '../../../atoms/start-repair-modal'
import { claimDetailsAtom } from '../../../atoms/claim-details'
import { useGetContractQuery } from '../../../queries/contract'
import { useStartRepairMutation } from '../../../queries/service-orders'
import { CONTRACTS_API_VERSION_FEB_2022 } from '../../../constants/const'
import styles from './start-repair-modal.module.css'
import { useLimitOfLiability } from '../../../hooks/use-limit-of-liability'

export function StartRepairModal({ serviceOrder }: { serviceOrder: ServiceOrder }): JSX.Element {
  const [isVisible, setIsVisible] = useAtom(isStartRepairModalVisibleAtom)
  const [date, setDate] = useState<Date | null>(null)
  const [dateError, setDateError] = useState<string | undefined>(undefined)

  const claim = useAtomValue(claimDetailsAtom)
  const { data: contract, isLoading: isLoadingContract } = useGetContractQuery({
    contractId: claim.contractId,
    apiVersion: CONTRACTS_API_VERSION_FEB_2022,
  })
  const { remainingAmount, isLoading: isLoadingEntitlements } = useLimitOfLiability(contract)

  const { mutateAsync: startRepair } = useStartRepairMutation()

  const { toast } = useToaster()

  const closeModal = useCallback((): void => {
    setDateError(undefined)
    setDate(null)
    setIsVisible(false)
  }, [setIsVisible])

  const onChange = useCallback(
    (newDate: Date | null): void => {
      setDate(newDate)
      if (newDate) {
        setDateError(undefined)
      }
    },
    [setDate, setDateError],
  )

  const handleSubmit = useCallback((): Promise<void> | void => {
    if (!date) {
      setDateError('Required')
      return
    }
    try {
      setDateError(undefined)
      closeModal()
      toast({
        message: 'Repair started!',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.green,
      })
      startRepair({
        serviceOrderId: serviceOrder.id,
        repairStartedAt: new Date(date).getMilliseconds(),
      })
    } catch (err) {
      toast({
        message: 'There was an error starting the repair. Please try again',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }
  }, [closeModal, date, serviceOrder.id, startRepair, toast])

  return (
    <ModalController isOpen={isVisible}>
      <Modal
        heading="Start Repair"
        onDismissRequest={closeModal}
        primaryButtonProps={{
          text: 'Start',
          onClick: handleSubmit,
          isProcessing: isLoadingContract || isLoadingEntitlements,
        }}
      >
        <div className={styles.container}>
          <DataProperty label="Service Type" value={serviceOrder.serviceType} />
          <DataProperty label="Remaining LOL" value={remainingAmount} />
          <DataProperty label="Item Requiring Fulfillment" value={contract?.product?.title || ''} />
          <DataProperty label="Purchased From" value={contract?.sellerName || ''} />
          <DataProperty label="Serial Number" value={contract?.product?.serialNumber || ''} />
          <DataProperty label="Failure Type" value={claim.incident.failureType} />
          <DataProperty label="Incident Description" value={claim.incident.description} />
          <div />
          <div className={styles.input}>
            <Label isMuted htmlFor="repair-started-date">
              Repair Started Date
            </Label>
            <DatePicker
              selected={date}
              id="repair-started-date"
              onChange={onChange}
              errorFeedback={dateError}
              placeHolder=""
            />
          </div>
        </div>
      </Modal>
    </ModalController>
  )
}
