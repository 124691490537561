// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3TDbYGUr41eN4Oojky9dFe {\n    margin-top: 32px;\n    font-size: 20px;\n}\n\n._2STv-mnlU9N5xD9jcWbrv {\n    border: none;\n    background-color: inherit;\n    font-size: inherit;\n    text-decoration: inherit;\n    color: var(--blue-800);\n}\n\n._2STv-mnlU9N5xD9jcWbrv:visited {\n    color: var(--blue-800);\n}\n\n._2STv-mnlU9N5xD9jcWbrv:hover {\n    text-decoration: underline;\n    cursor: pointer;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "_3TDbYGUr41eN4Oojky9dFe",
	"contact": "_2STv-mnlU9N5xD9jcWbrv"
};
module.exports = exports;
