import type { FC } from 'react'
import React, { useMemo, useState } from 'react'
import { currency } from '@extend/client-helpers'
import {
  Modal,
  ModalController,
  Checkbox,
  useToaster,
  ToastDuration,
  ToastColor,
} from '@extend/zen'
import { useReportRefundByIdMutation } from '../queries/refunds'
import { CONTRACTS_API_VERSION_FEB_2022 } from '../constants/const'
import styles from './refund-modal.module.css'

export type RefundModalProps = {
  isVisible: boolean
  contractId: string
  amount: number | undefined
  closeModal: () => void
}

export const RefundModal: FC<RefundModalProps> = ({
  isVisible,
  contractId,
  amount,
  closeModal,
}) => {
  const [isCheckBoxSelected, setIsCheckBoxSelected] = useState(false)
  const { mutateAsync: reportRefundById, isLoading } = useReportRefundByIdMutation()
  const { toast } = useToaster()

  const submitUserRefund = async (): Promise<void> => {
    try {
      await reportRefundById({ contractId, contractsApiVersion: CONTRACTS_API_VERSION_FEB_2022 })
      toast({
        message: 'Refund reported',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.blue,
      })
      closeModal()
    } catch {
      toast({
        message: `The refund couldn’t be reported. Please try again later`,
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }
  }

  const toggleCheckBox = (): void => {
    setIsCheckBoxSelected(!isCheckBoxSelected)
  }

  const handleCloseModal = (): void => {
    closeModal()
    setIsCheckBoxSelected(false)
  }

  const refundAmount = useMemo(() => {
    if (typeof amount !== 'number') {
      return '–'
    }

    return currency.format(amount)
  }, [amount])

  return (
    <ModalController isOpen={isVisible}>
      <Modal
        heading="Product Protection Cancelation and Refund"
        primaryButtonProps={{
          onClick: submitUserRefund,
          text: 'Submit',
          isDisabled: !isCheckBoxSelected,
          isProcessing: isLoading,
          type: 'button',
        }}
        secondaryButtonProps={{
          onClick: handleCloseModal,
          text: 'Back',
        }}
      >
        <p className={styles['refund-amount-text']}>
          <strong>Customer Refund:</strong> {refundAmount}
        </p>
        <p className={styles['detail-text']}>
          You are about to cancel and process a refund for your customer’s Extend Product
          Protection. Refund them the above amount via your own payment system. Then, click Submit
          to notify Extend. We will credit your refund in the next invoice cycle.
        </p>
        <Checkbox
          checked={isCheckBoxSelected}
          name="submitContractRefund"
          disabled={isLoading}
          onChange={toggleCheckBox}
          label="I refunded my customer and understand Extend will cancel their contract."
        />
      </Modal>
    </ModalController>
  )
}
