import { EXTEND_API_HOST } from '@helloextend/client-constants'
import type { Lead, LeadSearchResponse, Product } from '@helloextend/extend-api-client'
import type { QueryObserverResult, UseBaseMutationResult } from '@tanstack/react-query'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import { useAtomValue } from 'jotai/react'
import { getNonEmptyParams } from '../utils/get-non-empty-params'
import { v3AccessTokenAtom } from '../atoms/auth'
import { getActiveStoreIdAtom } from '../atoms/stores'
import { safeBtoa } from '../utils/base64-encoding'

export type LeadSearchQueryStringOptions = {
  storeId: string
  minLimit?: number
  searchKey?: string
  searchValue?: string
  productTransactionDateStart?: string
  productTransactionDateEnd?: string
  cursor?: string
}

const BASE_URL = `https://${EXTEND_API_HOST}/leads`

const LEADS_CACHE_KEY = 'Leads'

const COMMON_HEADERS = {
  'Content-Type': 'application/json',
  accept: 'application/json; version=latest',
}

export class CreateLeadErrorWithMessage extends Error {}

const buildCacheKey = (qs: LeadSearchQueryStringOptions): string => {
  const { cursor, minLimit, ...qsWithoutPagination } = qs
  return safeBtoa(JSON.stringify(qsWithoutPagination))
}

export function useGetLeadQuery(leadToken: string): QueryObserverResult<Lead, Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  const queryFn = useCallback(async () => {
    const response = await fetch(`${BASE_URL}/${leadToken}`, {
      headers: {
        ...COMMON_HEADERS,
        'x-extend-access-token': accessToken ?? '',
      },
      method: 'GET',
    })

    if (!response.ok) {
      throw new Error('Unable to fetch lead')
    }

    if (response.status === 204) {
      return JSON.stringify('')
    }

    return response.json()
  }, [accessToken, leadToken])

  return useQuery<Lead, Error>({
    queryKey: [LEADS_CACHE_KEY],
    queryFn,
    enabled: Boolean(leadToken),
  })
}

export function useSearchLeadsQuery({
  params,
  enabled = true,
  refetchOnMount = false,
}: {
  params: LeadSearchQueryStringOptions
  enabled?: boolean
  refetchOnMount?: boolean
}): QueryObserverResult<LeadSearchResponse, Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  const queryFn = useCallback(async () => {
    const nonEmptyParams = getNonEmptyParams(params)
    const parsedParams = new URLSearchParams(nonEmptyParams)

    const response = await fetch(`${BASE_URL}/search?${parsedParams}`, {
      headers: {
        ...COMMON_HEADERS,
        'x-extend-access-token': accessToken ?? '',
      },
      method: 'GET',
    })

    if (!response.ok) {
      throw new Error('Unable to get leads search results')
    }

    return response.json()
  }, [accessToken, params])

  return useQuery<LeadSearchResponse, Error>({
    queryKey: [LEADS_CACHE_KEY, { id: buildCacheKey(params) }],
    queryFn,
    enabled,
    refetchOnMount,
  })
}

export function useExportLeadsMutation(): UseBaseMutationResult<
  void,
  Error,
  LeadSearchQueryStringOptions,
  void
> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  return useMutation({
    mutationFn: async (params: LeadSearchQueryStringOptions): Promise<void> => {
      const nonEmptyParams = getNonEmptyParams<LeadSearchQueryStringOptions>(params)
      const parsedParams = new URLSearchParams(nonEmptyParams)

      const response = await fetch(`${BASE_URL}/export?${parsedParams}`, {
        headers: {
          ...COMMON_HEADERS,
          'x-extend-access-token': accessToken,
        },
        method: 'POST',
      })

      if (!response.ok) {
        throw new Error('Unable to export leads')
      }
    },
  })
}

export function useLazyDownloadLeadsQuery(keyName: string): QueryObserverResult<string, Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  const queryFn = useCallback(async () => {
    const response = await fetch(`${BASE_URL}/download?keyName=${encodeURIComponent(keyName)}`, {
      headers: {
        ...COMMON_HEADERS,
        'x-extend-access-token': accessToken,
      },
      method: 'GET',
    })

    if (!response.ok) {
      throw new Error('Unable to get leads download url')
    }

    return response.json()
  }, [accessToken, keyName])

  return useQuery({
    queryKey: [LEADS_CACHE_KEY],
    queryFn,
    enabled: false,
  })
}

export function useCreateLeadMutation(): UseBaseMutationResult<
  Lead,
  Error,
  CreateLeadRequest,
  void
> {
  const client = useQueryClient()
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''
  const sellerId = useAtomValue(getActiveStoreIdAtom)

  return useMutation({
    mutationFn: async ({
      transactionId,
      product,
      lineItemPrice,
      transactionDate,
      quantity,
      email,
    }: CreateLeadRequest): Promise<Lead> => {
      const response = await fetch(`${BASE_URL}`, {
        headers: {
          ...COMMON_HEADERS,
          'x-extend-access-token': accessToken,
        },
        body: JSON.stringify({
          product: {
            transactionId,
            purchasePrice: {
              amount: lineItemPrice,
            },
            listPrice: product.price,
            manufacturerWarrantyLengthLabor: product.mfrWarranty?.labor || 12,
            manufacturerWarrantyLengthParts: product.mfrWarranty?.parts || 12,
            title: product.title,
            referenceId: product.referenceId,
            transactionDate,
          },
          customer: {
            email,
          },
          category: product.category,
          quantity,
          sellerId,
        }),
        method: 'POST',
      })

      const responseJson = await response.json()
      if (!response.ok) {
        if (typeof responseJson?.message === 'string') {
          throw new CreateLeadErrorWithMessage(responseJson.message)
        }
        throw new Error('Unable to create lead')
      }

      return responseJson
    },
    onSuccess: () => {
      client.invalidateQueries([LEADS_CACHE_KEY])
    },
  })
}

export type CreateLeadRequest = {
  transactionId: string
  product: Partial<Product>
  lineItemPrice: number
  quantity: number
  email: string
  transactionDate: number
}
