// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._36cbfcju-6iq5C9Gxax3l9 {\n  position: absolute;\n  border: 1px solid #e5e5e5;\n  margin-top: 0.3rem;\n  margin-left: -3rem;\n  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);\n  z-index: 1;\n}", ""]);
// Exports
exports.locals = {
	"datepicker": "_36cbfcju-6iq5C9Gxax3l9"
};
module.exports = exports;
