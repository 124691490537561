// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".T1serOlxF4oNAl5kektR4 {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n}\n\n._1gfVcX9phVYDK1hYh5Wgm5 {\n    border: 1px solid var(--neutral-300);\n    border-radius: 4px;\n    padding: 40px;\n}\n\n.VLOGquKakEDpkDfrvwMm1 {\n    width: 100%;\n    font-size: 12px;\n    border-collapse: collapse;\n    table-layout: fixed;\n}\n\n.VLOGquKakEDpkDfrvwMm1 tbody {\n    border: none;\n}\n\n.JhTTlRMSo9xl_DzqaeBId {\n    border-top: 1px solid var(--neutral-300);\n    border-bottom: 1px solid var(--neutral-300);\n    background-color: transparent;\n    transition: background-color .2s ease-in;\n}\n\n.JhTTlRMSo9xl_DzqaeBId:hover {\n    background-color: var(--neutral-100);\n}\n\n._3fq6zjrIuNcPZzKNm3Ost- {\n    text-align: left;\n    padding-left: 24px;\n    text-transform: uppercase;\n    font-weight: bold;\n    line-height: 4px;\n    width: 100%;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    overflow: hidden;\n    color: var(--neutral-1000);\n}\n\n._2ks5QMpWtBvM26Eh5BtUpt {\n    color: var(--neutral-700)\n}\n\n._2KFTKs9_QzmBrZQUgotQvu {\n    padding-right: 12px;\n}\n\n._1znERZUHpX6Gl0xsJjH9p9 {\n    font-size: 14px;\n}\n\n._1znERZUHpX6Gl0xsJjH9p9 p {\n    font-size: 14px;\n    margin: 0;\n}", ""]);
// Exports
exports.locals = {
	"container": "T1serOlxF4oNAl5kektR4",
	"expenses-table-wrapper": "_1gfVcX9phVYDK1hYh5Wgm5",
	"table": "VLOGquKakEDpkDfrvwMm1",
	"table-row": "JhTTlRMSo9xl_DzqaeBId",
	"table-foot": "_3fq6zjrIuNcPZzKNm3Ost-",
	"total-label-cell": "_2ks5QMpWtBvM26Eh5BtUpt",
	"total-amount-cell": "_2KFTKs9_QzmBrZQUgotQvu",
	"alert-wrapper": "_1znERZUHpX6Gl0xsJjH9p9"
};
module.exports = exports;
