// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Mv3r7LHVaM7Emx27tuEie {\n    margin-bottom: 32px;\n}\n\n._69mlk04tqsU6ISeHr49JK {\n    display: inline-flex;\n    align-items: center;\n    line-height: 24px;\n}\n\n._69mlk04tqsU6ISeHr49JK.KsDakD1Gi4PsHKr5Px1KB:hover {\n    background-color: var(--neutral-100);\n    border-radius: 4px;\n}\n\n._69mlk04tqsU6ISeHr49JK:hover div {\n    opacity: 1;\n}\n\n.ShKhCeIxFuDRjon5ZLlXZ {\n    transition: opacity .25s ease-out;\n    display: flex;\n    opacity: 0;\n}\n\n.ShKhCeIxFuDRjon5ZLlXZ._1sfGdm5rP_QqpmnT9F-UjX {\n    opacity: 1;\n}\n\n.VxEyduW0Gfj59uablot06._1OwBvzNfQ3Z9JgGYkS8cH1 {\n    color: var(--neutral-1000);\n}\n\n._3a1fcy8CkJk_z4lEkYDKV5 {\n    gap: 4px;\n    display: flex;\n    align-items: center;\n    padding: 4px 0;\n}\n\n._3a1fcy8CkJk_z4lEkYDKV5 span[data-tooltip] {\n    display: inline-flex;\n}\n\n._3a1fcy8CkJk_z4lEkYDKV5 span[data-tooltip] button {\n    transform: translateY(-1px);\n}", ""]);
// Exports
exports.locals = {
	"search-wrapper": "Mv3r7LHVaM7Emx27tuEie",
	"header-text-wrapper": "_69mlk04tqsU6ISeHr49JK",
	"is-sortable": "KsDakD1Gi4PsHKr5Px1KB",
	"icon": "ShKhCeIxFuDRjon5ZLlXZ",
	"visible": "_1sfGdm5rP_QqpmnT9F-UjX",
	"header-text": "VxEyduW0Gfj59uablot06",
	"sorted": "_1OwBvzNfQ3Z9JgGYkS8cH1",
	"header": "_3a1fcy8CkJk_z4lEkYDKV5"
};
module.exports = exports;
