// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._34lWst2ZWM_3n_KHAjYQCI {\n    margin: 155px auto;\n    text-align: center;\n    max-width: 550px;\n    padding: 8px;\n    color: var(--neutral-800);\n}\n\n.nXRpj_bJFW8hpLMkSOm_R {\n    display: flex;\n    justify-content: center;\n    margin-top: 56px;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "_34lWst2ZWM_3n_KHAjYQCI",
	"button-group-container": "nXRpj_bJFW8hpLMkSOm_R"
};
module.exports = exports;
