// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1aWmt-c-oYs7xN95YDXmmR {\n    max-width: 530px;\n    margin: 0;\n}\n\n._2kajlw3J3TihEJFn2WcFp_ {\n    display: flex;\n    flex-direction: column;\n    gap: 30px;\n}", ""]);
// Exports
exports.locals = {
	"success-message": "_1aWmt-c-oYs7xN95YDXmmR",
	"container": "_2kajlw3J3TihEJFn2WcFp_"
};
module.exports = exports;
