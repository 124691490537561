// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3hYZcxa12hQJPa_apM6Ec1 {\n    align-items: center;\n    background-color: var(--neutral-100);\n    display: flex;\n    justify-content: center;\n    line-height: 2rem;\n    min-height: 100vh;\n    text-align: center;\n    width: 100%;\n}\n\n._3hYZcxa12hQJPa_apM6Ec1 h2 {\n    font-size: 24px;\n    font-weight: 700;\n    margin-bottom: 20px;\n}\n\n._3hYZcxa12hQJPa_apM6Ec1 strong {\n    font-weight: 600;\n}\n\n._3hYZcxa12hQJPa_apM6Ec1 a {\n    color: var(--bright-blue);\n}\n\n._3AoGW2a7iRzeE6_HAef1kT {\n    max-height: 50px;\n}", ""]);
// Exports
exports.locals = {
	"wrapper": "_3hYZcxa12hQJPa_apM6Ec1",
	"logo": "_3AoGW2a7iRzeE6_HAef1kT"
};
module.exports = exports;
