// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3Aat-TFoemdKWNVuBe4Pzt {\n    background-color: var(--blue-500);\n    color: var(--white);\n    font-weight: normal;\n    font-size: 14px;\n    line-height: 1.7;\n    text-align: center;\n    padding: 5px 20px;\n    margin: 0;\n}", ""]);
// Exports
exports.locals = {
	"environment-banner": "_3Aat-TFoemdKWNVuBe4Pzt"
};
module.exports = exports;
