// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2_uEqVc-pkg6R6jDh0esU1 {\n    margin-bottom: 32px;\n}\n\n.QooFt2El2Dstg6FMiUGRa {\n    font-size: 32px;\n    font-weight: 700;\n    margin: 0 0 10px 0;\n}\n\n._3rjIYGxpMXoyFP6-RO1mFV {\n    font-size: 20px;\n}", ""]);
// Exports
exports.locals = {
	"container": "_2_uEqVc-pkg6R6jDh0esU1",
	"heading": "QooFt2El2Dstg6FMiUGRa",
	"subheading": "_3rjIYGxpMXoyFP6-RO1mFV"
};
module.exports = exports;
