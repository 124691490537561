// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1LnrIRifF4ZaaIaJY8i9TW {\n    display: flex;\n    flex-direction: row;\n    gap: 32px;\n}\n\n.nzWRKDoNZ6I06_P-PuCW1 {\n    flex-grow: 1;\n}\n\n._1JlV9aZydkFoGWd00ViaZ5 {\n    flex-grow: 0;\n}\n\n._1P4SyO8DArL85OL9WaQzrp {\n    margin-top: 8px;\n    width: 172px;\n    height: 172px;\n}\n\n._3x9xgaOvvQUsPle7leJK3N {\n    display: inline-flex;\n    position: absolute;\n    margin-left: 16px;\n    align-items: center;\n}\n\n._171kinc_iG5cl5yfx5GJWu {\n    padding-left: 4px;\n}\n\n.Rh2E5uNvlU7Nz6uY4UXTM {\n    color: var(--neutral-1000);\n    font-size: 24px;\n    line-height: 32px;\n    margin-top: 0;\n    margin-bottom: 16px;\n    padding: 0;\n}\n\n._3FFW5HOKGKT4yzzRkRwbDm {\n    margin: 0 auto;\n}\n", ""]);
// Exports
exports.locals = {
	"row": "_1LnrIRifF4ZaaIaJY8i9TW",
	"flex-grow": "nzWRKDoNZ6I06_P-PuCW1",
	"flex-no-grow": "_1JlV9aZydkFoGWd00ViaZ5",
	"title-aside": "_1P4SyO8DArL85OL9WaQzrp",
	"badge-container": "_3x9xgaOvvQUsPle7leJK3N",
	"descriptions-container": "_171kinc_iG5cl5yfx5GJWu",
	"heading": "Rh2E5uNvlU7Nz6uY4UXTM",
	"buttons-container": "_3FFW5HOKGKT4yzzRkRwbDm"
};
module.exports = exports;
