// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2mGMkb0eI6ly5Jbyx_CBiR {\n    display: grid;\n    grid-template-columns: 1fr 1fr 2fr;\n    grid-auto-flow: row;\n    gap: 20px;\n    margin-bottom: 20px;\n}\n\n._3z-lb_WKgs2g8gbA2JQFh7 {\n    margin-left: 8px;\n}\n\n._88Ag-cv3D4FFlvzXaJUmZ {\n    display: flex;\n    justify-content: flex-end;\n    margin: 40px 40px 40px 0;\n}\n\n._88Ag-cv3D4FFlvzXaJUmZ button:nth-of-type(2) {\n    margin-left: 16px;\n}\n\n._21RP2HAYoqCQB0cCevmO4z {\n    color: var(--neutral-600);\n    font-size: 14px;\n    margin: 0 0 5px 0;\n}\n\n._2rzdBIXcYbkcFDJl-0lGJc {\n    display: flex;\n    align-items: center;\n}\n\n.Bq-WeylxE_fpvtJRomT4s {\n    margin: 0;\n}", ""]);
// Exports
exports.locals = {
	"row": "_2mGMkb0eI6ly5Jbyx_CBiR",
	"submit-button-wrapper": "_3z-lb_WKgs2g8gbA2JQFh7",
	"button-group": "_88Ag-cv3D4FFlvzXaJUmZ",
	"user-detail-label": "_21RP2HAYoqCQB0cCevmO4z",
	"flex-center": "_2rzdBIXcYbkcFDJl-0lGJc",
	"no-margin": "Bq-WeylxE_fpvtJRomT4s"
};
module.exports = exports;
