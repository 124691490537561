import type { FC } from 'react'
import React, { useMemo, useEffect, useRef } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import styled from '@emotion/styled'
import { useGetClaimPhotosQuery } from '@helloextend/extend-api-rtk-query'
import type { MerchantClaim } from '../../../../types/claims'
import { TabMenu } from '../../../../components/tab-menu/tab-menu'
import { LDFlag } from '../../../../constants/ld-flags'
import { IncidentReport } from './tabs/incident-report'
import { Attachments } from './tabs/attachments'
import { FraudReport } from './tabs/fraud-report'
import { AdjudicationTab } from './tabs/adjudication'
import { useAtom } from 'jotai'
import { DEFAULT_CLAIM_TAB, Tab, activeTabAtom } from '../../../../atoms/claim-tab-section'

interface ClaimTabSectionProps {
  claim: MerchantClaim
}

const ClaimTabSection: FC<ClaimTabSectionProps> = ({ claim }) => {
  const {
    [LDFlag.NewClaimAttachments]: FF_NEW_CLAIM_ATTACHMENTS,
    [LDFlag.MerchantReviewClaims]: FF_MERCHANT_REVIEW_CLAIMS,
  } = useFlags()
  const sectionRef = useRef<null | HTMLDivElement>(null)

  const [{ activeTab, clickedAt }, setActiveTab] = useAtom(activeTabAtom)

  useEffect(() => {
    if (activeTab !== DEFAULT_CLAIM_TAB) {
      sectionRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
    // depending on clickedAt allows us to scroll back to the section
    // even if the current tab is already active
  }, [activeTab, clickedAt])

  // Reset active tab when component unmounts
  useEffect(() => {
    return () => {
      setActiveTab({ activeTab: DEFAULT_CLAIM_TAB })
    }
  }, [])

  const handleTabClick = (tab: Tab): void => {
    setActiveTab({ activeTab: tab })
  }

  const { data: photoData } = useGetClaimPhotosQuery(
    {
      claimId: claim.id,
    },
    { skip: !FF_NEW_CLAIM_ATTACHMENTS || !claim.id },
  )

  const tabs = useMemo(() => {
    const claimTabs: Array<{
      text: string | React.ReactNode
      key: Tab
      'data-cy'?: string
    }> = [{ text: 'Incident Report', key: Tab.incidentReport, 'data-cy': 'incident-report-tab' }]

    if (FF_MERCHANT_REVIEW_CLAIMS) {
      claimTabs.push(
        { text: 'Adjudication', key: Tab.adjudication, 'data-cy': 'adjudication-tab' },
        { text: 'Fraud Report', key: Tab.fraudReport, 'data-cy': 'fraud-report-tab' },
      )
    }

    if (FF_NEW_CLAIM_ATTACHMENTS) {
      claimTabs.push({
        text: 'Attachments',
        key: Tab.attachments,
        'data-cy': 'attachments-tab',
      })
    }

    return claimTabs
  }, [FF_NEW_CLAIM_ATTACHMENTS, FF_MERCHANT_REVIEW_CLAIMS])

  return (
    <div id="claim-tabs-section" data-cy="claim-tab-section" ref={sectionRef}>
      <TabMenu tabs={tabs} onClick={(tab) => handleTabClick(tab)} activeTab={activeTab} />
      <TabContent>
        {activeTab === Tab.incidentReport && <IncidentReport claim={claim}></IncidentReport>}
        {activeTab === Tab.adjudication && <AdjudicationTab claim={claim}></AdjudicationTab>}
        {activeTab === Tab.fraudReport && <FraudReport claim={claim}></FraudReport>}
        {activeTab === Tab.attachments && (
          <Attachments claim={claim} photoData={photoData}></Attachments>
        )}
      </TabContent>
    </div>
  )
}

const TabContent = styled.div({
  marginTop: 20,
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
})

export { ClaimTabSection }
