// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2i1uq1RQldTyfuceUpnFad {\n    display: flex;\n    align-items: end;\n}\n\n.oGYgWgy4cNi6z1UVE99yq button {\n    padding: 0 10px;\n    height: 40px;\n}\n\n._2i1uq1RQldTyfuceUpnFad input[type=\"text\"] {\n    width: 542px;\n}\n\n.d720aQRYbiPKzgovoVOlI {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n}", ""]);
// Exports
exports.locals = {
	"input-wrapper": "_2i1uq1RQldTyfuceUpnFad",
	"copy-button-wrapper": "oGYgWgy4cNi6z1UVE99yq",
	"wrapper": "d720aQRYbiPKzgovoVOlI"
};
module.exports = exports;
