// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1vFbrvXZ0Nc_XiNilaZSOd {\n    font-size: 14px;\n    font-weight: bold;\n    text-transform: uppercase;\n    margin-bottom: 40px;\n}", ""]);
// Exports
exports.locals = {
	"shipment-label": "_1vFbrvXZ0Nc_XiNilaZSOd"
};
module.exports = exports;
