// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BeiacBUcYNPQL79OnUmyR {\n    border: none;\n    height: 1px;\n    background: var(--neutral-300);\n    margin-top: 24px;\n}\n\n.IwDOMZs7X3BhT0n6FoHUG {\n    display: flex;\n    flex-direction: column;\n    margin-right: 48px;\n    max-width: 496px;\n}\n\n._3MMnP_lsJrosdQYJhxqyUx {\n    display: flex;\n    margin: 24px 0;\n    max-width: 334px;\n    gap: 8px\n}\n\n._3A4AcwChtga0I9pIGxkzQp {\n    max-width: 334px;\n}\n\n._3PgCaiB1uDxngqfE0jtmRC {\n    display: flex;\n    align-items: flex-end;\n    gap: 16px;\n}\n\n._17toijev2WgiGfLaue2Pit {\n    font-weight: 700;\n    font-size: 14px;\n    line-height: 18px;\n}\n\n.eBAk4EHCFrTPcafqDzkkL {\n    margin: 16px 0;\n}\n\n._3FAAYFbgDdxceiF9pmUXZk {\n    margin: 8px 0;\n}\n\n._1tFtbizk9JD4NfKqCcUSom {\n    display: flex;\n    justify-content: center;\n    margin-top: 16px;\n}\n\n._2K-lpCRbgtoKukmZyboCxc {\n    margin-bottom: 20px;\n}\n", ""]);
// Exports
exports.locals = {
	"divider": "BeiacBUcYNPQL79OnUmyR",
	"fields": "IwDOMZs7X3BhT0n6FoHUG",
	"input": "_3MMnP_lsJrosdQYJhxqyUx",
	"color": "_3A4AcwChtga0I9pIGxkzQp",
	"dropdown": "_3PgCaiB1uDxngqfE0jtmRC",
	"preview-label": "_17toijev2WgiGfLaue2Pit",
	"subheader": "eBAk4EHCFrTPcafqDzkkL",
	"css-override-spacer": "_3FAAYFbgDdxceiF9pmUXZk",
	"spinner": "_1tFtbizk9JD4NfKqCcUSom",
	"image-input": "_2K-lpCRbgtoKukmZyboCxc"
};
module.exports = exports;
