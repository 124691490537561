// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1_VatRjoEv42NMLVsUf8HD {\n    display: flex;\n    flex-direction: row;\n    margin-bottom: 16px;\n}\n\n._1_VatRjoEv42NMLVsUf8HD > * {\n    flex: 1;\n}\n\n._1_VatRjoEv42NMLVsUf8HD > *:nth-of-type(2) {\n    margin-left: 16px;\n}\n\n.iEybrG7tGJqMUY6VIH4te {\n    margin-bottom: 16px;\n}\n", ""]);
// Exports
exports.locals = {
	"name-input": "_1_VatRjoEv42NMLVsUf8HD",
	"input": "iEybrG7tGJqMUY6VIH4te"
};
module.exports = exports;
