// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2rxrXmtp_xRabBR5lYn4qR {\n  margin-bottom: 34px;\n  flex: 1;\n}\n\n.L-IdBFGsgHaGSbfJoHdHe {\n  font-size: 14px;\n  font-weight: 700;\n  margin-bottom: 12px;\n}\n\n._3rtEv_uFiQc9at1aW3Xx4h {\n  cursor: not-allowed\n}\n\n._3rtEv_uFiQc9at1aW3Xx4h > div {\n  pointer-events: none;\n}", ""]);
// Exports
exports.locals = {
	"slider-wrapper": "_2rxrXmtp_xRabBR5lYn4qR",
	"slider-text": "L-IdBFGsgHaGSbfJoHdHe",
	"not-allowed": "_3rtEv_uFiQc9at1aW3Xx4h"
};
module.exports = exports;
