// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1-DXQltptgNqmfgQupLZar {\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n\n.LtY0CQftsZUkYyLd5_mPG {\n    display: flex;\n    margin-bottom: 28px;\n}\n\n.uqzV-xFFpNzlu6aLem1w- {\n    border: 1px solid var(--neutral-300);\n    margin-top: 32px;\n    padding: 20px 40px;\n}\n\n.iIJJye_XXH39J8JgnWZ_Y {\n    color: var(--neutral-600);\n    font-weight: bold;\n    font-size: 14px;\n    margin-bottom: 10px;\n}\n", ""]);
// Exports
exports.locals = {
	"value": "_1-DXQltptgNqmfgQupLZar",
	"row": "LtY0CQftsZUkYyLd5_mPG",
	"details": "uqzV-xFFpNzlu6aLem1w-",
	"label": "iIJJye_XXH39J8JgnWZ_Y"
};
module.exports = exports;
