import type { FC } from 'react'
import React, { useMemo } from 'react'
import { Button, InlineAlert, InlineAlertColor, Error } from '@extend/zen'
import type { ServiceOrder, ServiceType } from '@helloextend/extend-api-client'
import { useSetAtom } from 'jotai/react'
import { ResolveServiceOrderModal } from './resolve-service-order-modal'
import styles from './service-order-information.module.css'
import { DataPropertyGrid } from '../../../components/data-property-grid'
import { useGetServiceOrderInformation } from '../../../hooks/use-get-service-order-information'
import { isResolveServiceOrderModalVisibleAtom } from '../../../atoms/resolve-service-order-modal'
import { LDFlag } from '../../../constants/ld-flags'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useListServiceOrderExpensesQuery } from '../../../queries/service-orders'
import styled from '@emotion/styled'

const PAYMENT_SUBMISSION_STATUSES = ['fulfilled', 'payment_requested']

export const ServiceOrderInformation: FC<{
  serviceOrder: ServiceOrder | null
  isReadonly?: boolean
}> = ({ serviceOrder, isReadonly = false }) => {
  const setIsResolveServiceOrderModalVisible = useSetAtom(isResolveServiceOrderModalVisibleAtom)
  const { [LDFlag.ExpenseUpdates]: FF_EXPENSE_UPDATES } = useFlags()
  const { data: expenses } = useListServiceOrderExpensesQuery({
    serviceOrderId: serviceOrder?.id || '',
  })
  const { shouldShowResolveServiceOrder, dataPropertyValues, remainingAmount } =
    useGetServiceOrderInformation(serviceOrder, isReadonly)

  const expenseTotal = useMemo(() => {
    if (!expenses) return 0
    return expenses.reduce((sum, expense) => {
      if (
        expense.status === 'voided' ||
        expense.isGoodwill ||
        expense.incurredBy === 'extend' ||
        expense.incurredBy === 'manufacturer'
      )
        return sum
      const totalCost = expense.totalCost ?? expense.cost.amount * (expense.quantity ?? 1)
      const netTotal = totalCost - (expense.goodwillMetaData?.amount.amount ?? 0)
      return sum + netTotal
    }, 0)
  }, [expenses])

  const shouldShowExpenseAlert =
    FF_EXPENSE_UPDATES &&
    expenseTotal > +remainingAmount * 100 &&
    PAYMENT_SUBMISSION_STATUSES.includes(serviceOrder?.status || '')

  return (
    <>
      {shouldShowExpenseAlert && (
        <AlertWrapper>
          <InlineAlert color={InlineAlertColor.red} icon={Error} data-cy="expense-alert">
            Expenses exceed the remaining Limit of Liability. Update the expenses before finalizing
            payment.
          </InlineAlert>
        </AlertWrapper>
      )}
      <DataPropertyGrid values={dataPropertyValues} className={styles.grid} />
      {shouldShowResolveServiceOrder && (
        <>
          <Button
            text="Resolve Service Order"
            isDisabled={!serviceOrder?.contractId}
            onClick={() => setIsResolveServiceOrderModalVisible(true)}
          />
          <ResolveServiceOrderModal
            serviceOrderType={serviceOrder?.serviceType as ServiceType}
            serviceOrderId={serviceOrder?.id || ''}
            contractId={serviceOrder?.contractId || ''}
            fulfillmentMethod={serviceOrder?.configurations?.repairFulfillmentMethod}
          />
        </>
      )}
    </>
  )
}

const AlertWrapper = styled.div({
  marginBottom: 20,
})
