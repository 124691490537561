// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2wks-P52ITCZTbeX-AC1xg {\n    font-size: 16px;\n    line-height: 24px;  \n}\n\n._2wks-P52ITCZTbeX-AC1xg p { \n    margin-top: 0; \n}\n\n._2cwYZfyI55zVWkmwN5TA3v {\n    display: flex;\n    gap: 16px;\n    margin-bottom: 16px;\n    flex: 1;\n}\n\n._2cwYZfyI55zVWkmwN5TA3v > div {\n    flex: 1;\n}", ""]);
// Exports
exports.locals = {
	"container": "_2wks-P52ITCZTbeX-AC1xg",
	"input-row": "_2cwYZfyI55zVWkmwN5TA3v"
};
module.exports = exports;
