// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "h3 {\n    padding: 0;\n    margin-top: 0;\n    margin-bottom: 1rem;\n}\n\nh4 {\n    margin-bottom: 1rem;\n    font-size: 14px;\n}\n\n._29tm1yGTRLcJSrdHDBJp5Z {\n    margin-top: 1rem;\n}\n", ""]);
// Exports
exports.locals = {
	"customer-email-text": "_29tm1yGTRLcJSrdHDBJp5Z"
};
module.exports = exports;
