import React, { useMemo, useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'
import type { FC } from 'react'
import { useAtomValue } from 'jotai/react'
import type { CategoryContract } from '@helloextend/extend-api-client'
import { ContractType } from '@helloextend/extend-api-client'
import styles from './contract-details.module.css'
import { CustomerInformationFormGrid } from './customer-information-form-grid'
import { getContractDetailsFieldsFromContract } from '../../../../utils/contract-details-utils'
import { ProductInformationFormGrid } from './product-information-form-grid'
import { contractDetailsAtom } from '../../../../atoms/contract-details'
import { ProductsAndShippingSection } from './products-and-shipping-section'
import { CollapsibleSection } from '../../../../components/collapsible-section'
import { DataPropertyGrid } from '../../../../components/data-property-grid'
import { CategoryProductsTable } from './category-products-table'
import { CategoryProductModal } from './category-product-modal'
import { BunldeProductsTable } from './bundle-products-table'

export const ContractDetails: FC = () => {
  const location = useLocation()
  const contract = useAtomValue(contractDetailsAtom)
  const fields = useMemo(() => getContractDetailsFieldsFromContract(contract), [contract])

  useLayoutEffect(() => {
    const productSection = document.getElementById('category-products-table-wrapper')
    if (location.hash === '#products' && productSection) {
      productSection.scrollIntoView({ behavior: 'smooth' })
    }
  }, [location])

  return (
    <div data-cy="contract-container" className={styles.container}>
      <CustomerInformationFormGrid fields={fields} />
      <CollapsibleSection heading="Transaction Information" isExpanded>
        <DataPropertyGrid
          values={fields.transactionInformation}
          className={styles['grid-33-33-33']}
        />
      </CollapsibleSection>
      <CollapsibleSection heading="Store Information" isExpanded>
        <DataPropertyGrid values={fields.storeInformation} className={styles['grid-33-66']} />
      </CollapsibleSection>
      <CollapsibleSection heading="Contract Information" isExpanded>
        <DataPropertyGrid
          values={fields.contractInformation}
          className={styles['contract-information-section']}
        />
      </CollapsibleSection>
      {contract.type === ContractType.PCRS && <ProductInformationFormGrid />}
      {contract.type === ContractType.SHIPPING_PROTECTION && <ProductsAndShippingSection />}
      {contract.type === ContractType.CATEGORY && (
        <div id="category-products-table-wrapper">
          <CategoryProductsTable />
          <CategoryProductModal contract={contract as unknown as CategoryContract} />
        </div>
      )}
      {contract.type === ContractType.PRODUCT_PROTECTION_BUNDLE && <BunldeProductsTable />}
    </div>
  )
}
