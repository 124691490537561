// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._8PesTgdSq8gWe5frAv904 {\n    display: flex;\n    margin-bottom: 28px;\n}\n\n._8PesTgdSq8gWe5frAv904 > div {\n    max-width: 100%;\n}\n\n._2OHaAOVwuusGuE1_Q6mNOZ {\n    border: 1px solid var(--neutral-300);\n    margin-top: 32px;\n    padding: 20px 40px;\n}\n\n._37-O5ZcwJLn06yvsM0uCV4 {\n    display: flex;\n    flex-direction: column;\n}\n\n._37-O5ZcwJLn06yvsM0uCV4 > div {\n    max-width: 100%;\n}\n", ""]);
// Exports
exports.locals = {
	"row": "_8PesTgdSq8gWe5frAv904",
	"lead": "_2OHaAOVwuusGuE1_Q6mNOZ",
	"column": "_37-O5ZcwJLn06yvsM0uCV4"
};
module.exports = exports;
