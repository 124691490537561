// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3V2K9BDngxRRCjE2Fmrime {\n    margin: 0;\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 24px;\n}\n\n.-lsD-qEwu6Y68KgoP-BLL {\n    display: flex;\n    gap: 28px;\n    margin-top: 32px;\n    margin-bottom: 48px;\n}\n\n._1QvCBBCzwo2dYG37ZMXi4U {\n    width: 50%;\n}\n\n._18FDwObdQZrmUfeb7I41bU {\n    font-weight: 700;\n    font-size: 14px;\n    line-height: 18px;\n}\n\n.G833NwR9k7G3YHT6Hlz4z {\n    font-weight: 700;\n    font-size: 14px;\n    line-height: 22px;\n    color: var(--neutral-600);\n    margin: 0 0 4px 0;\n}\n\n._1OYkoLDuDXBrMovxUluPnk {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 24px;\n}\n\n._3iiaPDRIjAhI6bTWWynVuP {\n    width: 184px;\n    height: 64px;\n    background-size: cover;\n}\n\n._2De6LdLF51tiHgRzGZkOiq {\n    display: flex;\n    width: 255px;\n    justify-content: space-between;\n    align-items: center;\n}\n", ""]);
// Exports
exports.locals = {
	"text": "_3V2K9BDngxRRCjE2Fmrime",
	"content": "-lsD-qEwu6Y68KgoP-BLL",
	"half-width": "_1QvCBBCzwo2dYG37ZMXi4U",
	"column-title": "_18FDwObdQZrmUfeb7I41bU",
	"row-title": "G833NwR9k7G3YHT6Hlz4z",
	"row-item": "_1OYkoLDuDXBrMovxUluPnk",
	"logo-img": "_3iiaPDRIjAhI6bTWWynVuP",
	"logo": "_2De6LdLF51tiHgRzGZkOiq"
};
module.exports = exports;
