// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._19x3iS36_6mIbSnkjtdvmS {\n    display: grid;\n    grid-template-columns: 1fr 1fr 2fr;\n    grid-auto-flow: row;\n    gap: 20px;\n    align-items: center;\n    margin-bottom: 40px;\n}\n\n._1K6wxnwzRLyh2GNI_SKqY6 {\n    color: var(--neutral-600);\n    font-size: 14px;\n    margin: 0 0 5px 0;\n}\n\n._3Z-vDgAhAjw0tcj6qgHS5 {\n    width: 100%;\n    display: flex;\n    flex-wrap: wrap;\n}\n\n.dpthXhonL_uJ-NzD9ziiF {\n    font-size: 14px;\n    text-transform: uppercase;\n    margin-top: 0;\n}\n\n._2QB_GxPsMC2bhGhWa6kmU3 {\n    display: flex;\n    padding: 0.5em 0.1em;\n    width: 100%;\n    border-bottom: 1px solid var(--neutral-300);\n}\n\n._2QB_GxPsMC2bhGhWa6kmU3:last-child {\n    border-bottom: none;\n}\n\n._2CxfQL8tjW5UQlJzg2IeE4 {\n    width: calc(25% - 16px);\n}\n\n._22X1bNVizWbH-6LZE61zPg {\n    width: calc(75% - 16px);\n}\n\n._1xs825I3POzPYG7cGJL_WP {\n    margin-bottom: 0;\n}\n\n._1pbbuIo6fs7XAfzpjSU4P6 {\n    margin-bottom: 4px;\n}", ""]);
// Exports
exports.locals = {
	"row": "_19x3iS36_6mIbSnkjtdvmS",
	"user-detail-label": "_1K6wxnwzRLyh2GNI_SKqY6",
	"container": "_3Z-vDgAhAjw0tcj6qgHS5",
	"subtitle": "dpthXhonL_uJ-NzD9ziiF",
	"org-row": "_2QB_GxPsMC2bhGhWa6kmU3",
	"quarter-width": "_2CxfQL8tjW5UQlJzg2IeE4",
	"three-quarter-width": "_22X1bNVizWbH-6LZE61zPg",
	"no-bottom-margin": "_1xs825I3POzPYG7cGJL_WP",
	"margin-bottom-4": "_1pbbuIo6fs7XAfzpjSU4P6"
};
module.exports = exports;
