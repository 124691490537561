// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1_Ag6u4nzU7CoAfnGRs4hh {\n  display: grid;\n}\n\n.Gs8Ifj1oNCviyB3g-FsNF {\n  display: grid;\n  column-gap: 16px;\n  row-gap: 16px;\n  grid-template-columns: calc(50% - 16px) calc(50% - 16px);\n}\n\n/* Form Input Grid Styles */\n.Er7EG5WGoTt-58C2_ntBl {\n  display: grid;\n  column-gap: 16px;\n  row-gap: 16px;\n  grid-template-columns: calc(33% - 16px) calc(33% - 16px) calc(33% - 16px);\n}\n\n@media (min-width: 768px) {\n  ._2cTWK9vWR9-96MKMe_2iRz {\n      display: grid;\n      row-gap: 16px;\n      grid-template-columns: repeat(2, 1fr);\n  }\n\n  ._3XRRfMf4_HHlsI0zXbU6bD {\n    display: grid;\n    row-gap: 16px;\n    grid-template-columns: repeat(3, 1fr);\n  }\n}\n\n._2cTWK9vWR9-96MKMe_2iRz label, ._2cTWK9vWR9-96MKMe_2iRz div, ._3XRRfMf4_HHlsI0zXbU6bD label, ._3XRRfMf4_HHlsI0zXbU6bD div {\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n}\n\n.bLuMXS3MSHjms13QEAH4C {\n  display: flex;\n  gap: 16px;\n  margin-bottom: 16px;\n}\n\n.bLuMXS3MSHjms13QEAH4C > div {\n  flex: 1;\n}\n\n._1xJI1xa9Go-clWbFGsq59s {\n  font-size: 14px;\n  line-height: 18px;\n}\n", ""]);
// Exports
exports.locals = {
	"form-container": "_1_Ag6u4nzU7CoAfnGRs4hh",
	"four-input-grid": "Gs8Ifj1oNCviyB3g-FsNF",
	"six-input-grid": "Er7EG5WGoTt-58C2_ntBl",
	"grid": "_2cTWK9vWR9-96MKMe_2iRz",
	"addressGrid": "_3XRRfMf4_HHlsI0zXbU6bD",
	"input-row": "bLuMXS3MSHjms13QEAH4C",
	"h4-styles": "_1xJI1xa9Go-clWbFGsq59s"
};
module.exports = exports;
