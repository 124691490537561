// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3STe8ukaPlDOuUahegAbH1 {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n}\n\n._2EHSKnWwTjCKKuwEfW3yc0 {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n}\n\n._2cYul2eLJpBspouF-ppATp {\n    width: 50%;\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 20px;\n}\n\n._2cYul2eLJpBspouF-ppATp label {\n    color: var(--neutral-1000);\n}", ""]);
// Exports
exports.locals = {
	"container": "_3STe8ukaPlDOuUahegAbH1",
	"grid": "_2EHSKnWwTjCKKuwEfW3yc0",
	"date-container": "_2cYul2eLJpBspouF-ppATp"
};
module.exports = exports;
