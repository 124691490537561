// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Y57BmcDhG1kx9z5pgjPrl {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n}\n\n.HxElD2wSec-QgAHxwHQn_ {\n    display: flex;\n    gap: 8px;\n    align-items: center;\n}\n", ""]);
// Exports
exports.locals = {
	"form": "Y57BmcDhG1kx9z5pgjPrl",
	"buttons": "HxElD2wSec-QgAHxwHQn_"
};
module.exports = exports;
