// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2VMVb-zQvre2IXWdooUJZ- {\n  font-size: 16px;\n  line-height: 24px;  \n}\n\n._2VMVb-zQvre2IXWdooUJZ- p { margin-top: 0; }\n\n._2VMVb-zQvre2IXWdooUJZ- ul {\n  padding-left: 20px;\n}\n\n._2VMVb-zQvre2IXWdooUJZ- ul li {\n  margin-bottom: 10px;\n  font-size: 16px;\n  line-height: 24px;\n}", ""]);
// Exports
exports.locals = {
	"container": "_2VMVb-zQvre2IXWdooUJZ-"
};
module.exports = exports;
