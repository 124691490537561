// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1kcy1n1Vsp1SjcHbyDxZ5M {\n  margin-bottom: 1rem;\n}\n", ""]);
// Exports
exports.locals = {
	"alert": "_1kcy1n1Vsp1SjcHbyDxZ5M"
};
module.exports = exports;
