// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2Bxivy2wt0EP2RsiUjUEVv {\n    padding: 10px 12px;\n}\n\n._3UeDAE4sQzs53eXQQTbvtO {\n    font-weight: 700;\n    font-size: 12px;\n    color: var(--neutral-700);\n    letter-spacing: 1px;\n    background: var(--white);\n    margin: 0;\n    text-transform: uppercase;\n    padding: 10px 12px;\n}\n\n._1A1XucyxZRVaSCmq-uWih2 {\n    border-bottom: 1px solid var(--neutral-300);\n}", ""]);
// Exports
exports.locals = {
	"spinner-container": "_2Bxivy2wt0EP2RsiUjUEVv",
	"role-switcher-title": "_3UeDAE4sQzs53eXQQTbvtO",
	"menu-item-divider": "_1A1XucyxZRVaSCmq-uWih2"
};
module.exports = exports;
