// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3s0I_XtuxLcjgkyLkGBPh4 {\n    text-align: center;\n    margin-top: 105px;\n}\n\n._2nxNsEqV6QiMDuZ6IZZ_ED {\n    color: var(--blue-1000);\n    font-size: 32px;\n    margin-bottom: 8px;\n}\n\n._159sOCPuK5K5VqO4_MDjj {\n    width: 448px;\n    margin: 0 auto;\n}\n\n._2VkUJsNx0P6bQVQ8vYAQXw {\n    margin-top: 48px;\n    display: flex;\n    justify-content: center;\n}\n\n._2VkUJsNx0P6bQVQ8vYAQXw input[type='text'] {\n    width: 300px;\n}\n\n@media (min-width: 1025px) {\n    ._2VkUJsNx0P6bQVQ8vYAQXw {\n        width: 432px;\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"page": "_3s0I_XtuxLcjgkyLkGBPh4",
	"header": "_2nxNsEqV6QiMDuZ6IZZ_ED",
	"description": "_159sOCPuK5K5VqO4_MDjj",
	"search": "_2VkUJsNx0P6bQVQ8vYAQXw"
};
module.exports = exports;
