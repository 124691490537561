// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3hhplUld0Hj42LaCDAmQ2p {\n    display: flex;\n    flex-direction: column;\n    gap: 80px;\n}\n\n._3hhplUld0Hj42LaCDAmQ2p header {\n    display: flex;\n    justify-content: space-between;\n    flex-direction: column;\n}\n\n._3hhplUld0Hj42LaCDAmQ2p header p:first-of-type {\n    margin-bottom: 8px;\n}\n\n._3hhplUld0Hj42LaCDAmQ2p h2 {\n\n    font-size: 20px;\n    margin: auto;\n}\n\n._3hhplUld0Hj42LaCDAmQ2p header strong {\n    color: var(--neutral-600);\n}\n\n._3hhplUld0Hj42LaCDAmQ2p a {\n    color: var(--blue-600);\n}\n\n._3hhplUld0Hj42LaCDAmQ2p a:visited {\n    color: var(--blue-600);\n}\n\n._3hhplUld0Hj42LaCDAmQ2p p {\n    margin: 0;\n}\n\n._3Jv36gO8Zzw9Xh7JE8qxup {\n    width: 550px;\n    margin: auto;\n    display: flex;\n    flex-direction: column;\n    row-gap: 60px;\n}\n\n._3Jv36gO8Zzw9Xh7JE8qxup ul {\n    padding-inline-start: 0;\n    margin: 0;\n    display: flex;\n    flex-direction: column;\n    row-gap: 30px;\n}\n\n._3Jv36gO8Zzw9Xh7JE8qxup li {\n    display: grid;\n    grid-template-columns: 13% 87%;\n    align-items: center;\n}", ""]);
// Exports
exports.locals = {
	"section": "_3hhplUld0Hj42LaCDAmQ2p",
	"message-container": "_3Jv36gO8Zzw9Xh7JE8qxup"
};
module.exports = exports;
