// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3IYHnvWRjYO4I059bx5zcs {\n    font-size: 15px;\n    line-height: 20px;\n    font-weight: 400;\n    margin: 0;\n}\n\n._1HphLMuFx10M53jPoGRVRJ {\n    font-size: 20px;\n    line-height: 28px;\n    width: 100%;\n}\n\n._2yRlmDS3fPR2qmkAP_AIF4 {\n    margin-right: 16px;\n    margin-bottom: 25px;\n}\n\n._2yRlmDS3fPR2qmkAP_AIF4 .tbW4wEs-BAM3OoRPZC2nz {\n    width: 100% !important;\n}\n\n._2yRlmDS3fPR2qmkAP_AIF4 .tbW4wEs-BAM3OoRPZC2nz input#_2risaEBGgSTKh77t5Z-Iqa {\n    width: calc(100% - 32px);\n}\n\n._3qk68UghFc-fWRV_INF7bR {\n    display: inline-block;\n    font-size: 14px;\n    line-height: 22px;\n    font-weight: 800;\n    margin: 0 0 8px 0;\n}\n\n._3KF7TuAA9keFWBJCe9JuSk {\n    display: flex;\n    flex-direction: row;\n    flex-basis: 100%;\n    margin-top: 20px;\n}\n", ""]);
// Exports
exports.locals = {
	"inline-alert-text": "_3IYHnvWRjYO4I059bx5zcs",
	"heading": "_1HphLMuFx10M53jPoGRVRJ",
	"input-wrapper": "_2yRlmDS3fPR2qmkAP_AIF4",
	"react-datepicker-wrapper": "tbW4wEs-BAM3OoRPZC2nz",
	"date": "_2risaEBGgSTKh77t5Z-Iqa",
	"form-label": "_3qk68UghFc-fWRV_INF7bR",
	"form-buttons": "_3KF7TuAA9keFWBJCe9JuSk"
};
module.exports = exports;
