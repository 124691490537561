import { useMemo } from 'react'
import type { Contract } from '@helloextend/extend-api-client'
import { ContractStatus, LimitOfLiabilityAmountType } from '@helloextend/extend-api-client'
import { currency } from '@extend/client-helpers'
import { useSearchEntitlementsQuery } from '../queries/entitlements'

type LimitOfLiabilityType = {
  remainingAmount: string
  coverageAmount: string
  isLoading: boolean
  productPriceType: string
}

const INVALID_CONTRACT_STATUSES = [
  ContractStatus.CANCELLED,
  ContractStatus.EXPIRED,
  ContractStatus.FULFILLED,
]

export const useLimitOfLiability = (contract?: Contract): LimitOfLiabilityType => {
  const { data: emsData, isLoading } = useSearchEntitlementsQuery({
    contractId: contract?.id || '',
    enabled: Boolean(contract?.id),
  })

  const remainingAmount = useMemo(() => {
    if (
      !emsData?.products?.[0]?.entitlements?.[0]?.contract?.remainingLoL ||
      (contract?.status && INVALID_CONTRACT_STATUSES.includes(contract.status))
    ) {
      return `0.00`
    }
    return `${currency.formatWithoutCurrencySymbol(
      emsData?.products?.[0]?.entitlements?.[0]?.contract?.remainingLoL,
    )}`
  }, [contract, emsData?.products])

  const coverageAmount = useMemo(() => {
    const emsTotalLoL = emsData?.products?.[0]?.entitlements?.[0]?.contract?.limitOfLiabilityAmount
      ? currency.formatWithoutCurrencySymbol(
          emsData?.products?.[0]?.entitlements?.[0]?.contract?.limitOfLiabilityAmount,
        )
      : '0.00'

    return `/ ${emsTotalLoL} ${contract?.currency}`
  }, [contract, emsData?.products])

  const productPriceType =
    contract?.limitOfLiabilityAmountType === LimitOfLiabilityAmountType.PRODUCT_LIST_PRICE
      ? ' List'
      : ' Purchase'

  return {
    remainingAmount,
    coverageAmount,
    isLoading,
    productPriceType,
  }
}
