import type { FC } from 'react'
import React from 'react'
import { useAtom } from 'jotai/react'
import { Button, OpenInNew, Close } from '@extend/zen'
import { shouldShowSupportPopoverContactFormAtom } from '../../atoms/support'
import { SupportHelpCategoryButton } from './support-help-category-button'
import { SupportContactUsStep } from './support-contact-us-step'
import styles from './support-popover.module.css'

export const SupportPopover: FC<{ closePopover: () => void }> = ({ closePopover }) => {
  const [shouldShowContactForm, setShouldShowContactForm] = useAtom(
    shouldShowSupportPopoverContactFormAtom,
  )

  const onClickHandlerHelpCenter = (): void => {
    window.open('https://extend-merchant-support.zendesk.com/hc/en-us', '_blank')
  }

  return (
    <div className={styles.wrapper}>
      {shouldShowContactForm ? (
        <SupportContactUsStep closePopover={closePopover} />
      ) : (
        <>
          <div className="flex flex-row justify-content-between">
            <span className={styles.header}>Help</span>
            <Button icon={Close} color="neutral" emphasis="low" onClick={closePopover} />
          </div>
          <p className={styles.text}>
            Visit the Help Center for information about Extend and answers to frequently asked
            questions
          </p>
          <Button
            fillContainer
            text="Help Center"
            emphasis="medium"
            icon={OpenInNew}
            onClick={onClickHandlerHelpCenter}
          />
          <h4 className={styles.subheader}>Help categories for you</h4>
          <section className={styles['help-button-section']}>
            <SupportHelpCategoryButton
              key="Extend Program Basics"
              helpType="basics"
              text="Extend Program Basics"
              url="https://extend-merchant-support.zendesk.com/hc/en-us/categories/12612186577815-Extend-Program-Basics"
            />
            <SupportHelpCategoryButton
              key="Billing"
              helpType="billing"
              text="Billing"
              url="https://extend-merchant-support.zendesk.com/hc/en-us/categories/12612041095831-Billing"
            />
            <SupportHelpCategoryButton
              key="Post-purchase Emails & Lead Generation"
              helpType="leads"
              text="Post-purchase Emails & Lead Generation"
              url="https://extend-merchant-support.zendesk.com/hc/en-us/categories/12612112774551-Post-Purchase"
            />
            <SupportHelpCategoryButton
              key="Integration Documentation"
              helpType="notes"
              text="Integration Documentation"
              url="https://docs.extend.com/docs/welcome-to-extend"
            />
          </section>
          <h4 className={styles.subheader}>Get the help you need</h4>
          <section className={styles['help-button-section']}>
            <Button
              fillContainer
              text="Get Support"
              emphasis="medium"
              onClick={() => setShouldShowContactForm(true)}
            />
          </section>
        </>
      )}
    </div>
  )
}
