// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3iZWWcCMjf_EF0Usn16E2- {\n    width: 280px;\n}\n\n._1YvEjszKU282F9QEZYE4rb {\n    border-bottom: 1px solid var(--neutral-300);\n}", ""]);
// Exports
exports.locals = {
	"wrapper": "_3iZWWcCMjf_EF0Usn16E2-",
	"menu-item-divider": "_1YvEjszKU282F9QEZYE4rb"
};
module.exports = exports;
