import type { FC, ReactElement } from 'react'
import React, { useEffect, useMemo } from 'react'
import { Shell, Stack } from '@extend/zen'
import { isEnv } from '@extend/client-helpers'
import type { DashboardSection } from '../constants/dashboard-sections'
import { HeadTag } from './head-tag'
import { useQueryStore } from '../hooks/use-query-store'
import { useSplashScreen } from '../hooks/use-splash-screen'
import { DashboardHeader } from './dashboard-header'
import { DashboardSidebarStorePicker } from './dashboard-sidebar-store-picker'
import { DashboardSidebarMenuItems } from './dashboard-sidebar-menu-items'
import { navMenuLinks } from '../constants/dashboard-sections'
import styles from './dashboard-layout.module.css'

type DashboardLayoutProps = {
  section: DashboardSection
  isStorePickerVisible?: boolean
  isSidebarVisible?: boolean
  header?: ReactElement
}
export const DashboardLayout: FC<React.PropsWithChildren<DashboardLayoutProps>> = ({
  children,
  section,
  isStorePickerVisible = true,
  isSidebarVisible = true,
  header = <DashboardHeader />,
}: React.PropsWithChildren<DashboardLayoutProps>) => {
  const { onLoading, afterLoading } = useSplashScreen('hoc:dashboard-layout')

  const { store, isLoaded } = useQueryStore()

  const validNavMenuLinks = useMemo(
    () => ({ ...navMenuLinks, STORES: navMenuLinks.STORES.filter((l) => l.text !== 'In-Store') }),
    [],
  )

  useEffect(() => {
    if (!isLoaded && !store) {
      onLoading()
    } else {
      afterLoading()
    }
  }, [isLoaded, store, onLoading, afterLoading])

  const showEnvBanner = isEnv(['demo'])

  return (
    <>
      <HeadTag siteTitle="Extend | Merchant Dashboard" />
      {(isLoaded || store) && (
        <Shell
          {...(showEnvBanner && {
            banner: (
              <h5 className={styles['environment-banner']} data-cy="environment-banner">
                You’re in a demo environment—changes made here won’t affect your production store.
              </h5>
            ),
          })}
          header={header}
          sidebar={
            isSidebarVisible && (
              <Stack padding={1}>
                {isStorePickerVisible && <DashboardSidebarStorePicker />}
                <DashboardSidebarMenuItems links={validNavMenuLinks[section]} />
              </Stack>
            )
          }
        >
          {children}
        </Shell>
      )}
    </>
  )
}
