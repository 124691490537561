// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3YR6l3Zpc7WXJcc7Bc4_3F {\n    font-size: 12px;\n    line-height: 16px;\n    color: var(--neutral-1000);\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    overflow: hidden;\n    text-align: left;\n    margin: 0;\n}\n\n._3RxTSuK487agVZILaIqfnP {\n    padding-right: 12px;\n}\n", ""]);
// Exports
exports.locals = {
	"double-cell-text": "_3YR6l3Zpc7WXJcc7Bc4_3F",
	"amount": "_3RxTSuK487agVZILaIqfnP"
};
module.exports = exports;
