// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3FoffvX2Fa4Z31au2Eapvj {\n    color: var(--neutral-600)\n}\n\n._3BvAbYxaEI-ANc-2u2_k1p {\n    display: flex;\n    align-items: center;\n    margin-bottom: 8px;\n}\n\n._3iA5kFABsuK3Ma_RKpzrbL {\n    display: flex;\n    align-items: center;\n    margin-bottom: 24px;\n}\n\n._3GxWYNxnrOya6JN_0gN22b {\n    display: flex;\n    align-items: center;\n    margin-bottom: 8px;\n}\n\n._1g39vAKeGpMuU62wbtAI8Y {\n    color: var(--neutral-600);\n    font-weight: bold;\n}", ""]);
// Exports
exports.locals = {
	"remaining-amount": "_3FoffvX2Fa4Z31au2Eapvj",
	"product-line": "_3BvAbYxaEI-ANc-2u2_k1p",
	"type-line": "_3iA5kFABsuK3Ma_RKpzrbL",
	"contract-line": "_3GxWYNxnrOya6JN_0gN22b",
	"info-label": "_1g39vAKeGpMuU62wbtAI8Y"
};
module.exports = exports;
