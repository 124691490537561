import React, { useCallback } from 'react'
import type { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { useAtomValue } from 'jotai/react'
import type { DataTableAction } from '@extend/zen'
import { usePrecheckQuery } from '../../../../queries/adjudication-management'
import { CollapsibleSection } from '../../../../components/collapsible-section'
import { contractDetailsAtom } from '../../../../atoms/contract-details'
import { ContractProductsTable } from '../../../../components/contract-products-table'

export const BunldeProductsTable: FC = () => {
  const contract = useAtomValue(contractDetailsAtom)
  const history = useHistory()

  const { data: precheckResponse } = usePrecheckQuery({
    contractId: contract.id,
  })

  const handleFileClaim = useCallback(
    (product): DataTableAction[] => {
      const isDisabledForProduct = Boolean(
        product.limitOfLiability === 0 ||
          !precheckResponse ||
          precheckResponse.status === 'failure' ||
          (precheckResponse.lineItems &&
            precheckResponse.lineItems[product.lineItemId]?.hasActiveClaim),
      )

      return [
        {
          text: 'File Claim',
          emphasis: 'low',
          isDisabled: isDisabledForProduct,
          onClick: () =>
            history.push(
              `/store/contracts/${product.contractId}/products/${product.lineItemId}/claims/new`,
            ),
          'data-cy': `${product.referenceId}-file-claim-btn`,
        },
      ]
    },
    [history, precheckResponse],
  )

  return (
    <CollapsibleSection heading="Products" isExpanded>
      <ContractProductsTable contract={contract} rowActions={handleFileClaim} />
    </CollapsibleSection>
  )
}
