import type { FC } from 'react'
import React, { useMemo } from 'react'
import { useAtomValue } from 'jotai/react'
import { CollapsibleSection } from '../../components/collapsible-section'
import { DataPropertyGrid } from '../../components/data-property-grid'
import { ProductShipmentTable } from './claim/product-shipment-table'
import { ServiceOrderInformation } from './claim/service-order-information'
import styles from './shipping-protection-claim-view.module.css'
import { claimDetailsAtom } from '../../atoms/claim-details'
import { serviceOrdersAtom } from '../../atoms/service-orders-atom'
import { ClaimTabSection } from './claim/claim-tab-section'
import { getExternalClaimFields } from '../../utils/get-external-claim-fields'

export const ExternalClaimView: FC = () => {
  const claim = useAtomValue(claimDetailsAtom)
  const serviceOrders = useAtomValue(serviceOrdersAtom)
  const fields = useMemo(() => getExternalClaimFields(claim), [claim])

  return (
    <div className={styles['claims-wrapper']}>
      <CollapsibleSection heading="Customer Information" data-cy="customerInfo">
        <DataPropertyGrid values={fields.customer} className={styles.grid} />
      </CollapsibleSection>
      <CollapsibleSection heading="Claim Details" data-cy="claimDetails" isExpanded>
        <DataPropertyGrid values={fields.claimDetails} className={styles.grid} />
      </CollapsibleSection>
      {claim.orderId && (
        <CollapsibleSection heading="Items Requiring Fulfillment" data-cy="ProductShipmentTable">
          <ProductShipmentTable claim={claim} orderId={claim.orderId} />
        </CollapsibleSection>
      )}
      {serviceOrders &&
        serviceOrders.map((serviceOrder) => (
          <CollapsibleSection
            data-cy="service-order-information"
            isExpanded
            heading="Service Order Information"
            key={serviceOrder.id}
          >
            <ServiceOrderInformation serviceOrder={serviceOrder} isReadonly />
          </CollapsibleSection>
        ))}
      <ClaimTabSection claim={claim} />
    </div>
  )
}
