// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".vTNhhADEWitrsDTHp05rj {\n    display: flex;\n    gap: 16px;\n    padding: 0.8em 0;\n    width: 100%;\n}\n\n.vTNhhADEWitrsDTHp05rj > div {\n    width: 100%;\n}\n\n._3oDBfHnlCvWb_tHhQH_CRJ {\n    padding: 0 0 1.2em;\n}", ""]);
// Exports
exports.locals = {
	"section": "vTNhhADEWitrsDTHp05rj",
	"form": "_3oDBfHnlCvWb_tHhQH_CRJ"
};
module.exports = exports;
