// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2XiYy4xqkt5wav2TjFPMLc {\n    font-size: 16px;\n    padding: 8px 0 16px;\n}\n\n.sAymM9krSN_zt8fYpf9Gd {\n    align-self: center;\n    font-size: 18px;\n    font-weight: 700;\n    line-height: 28px;\n}\n\n._1Havp8ZX3uXNR1Cz18PyK1 {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin-top: 154px;\n}\n\n._3qs3mStyMhStHQG2vvuW4C {\n    font-size: 22px;\n    font-weight: 800;\n    line-height: 30px;\n    margin-top: 32px;\n}\n\n._2kNtGq6va7A-hM6V-qRz4G {\n    font-size: 16px;\n    font-weight: 700;\n    line-height: 22px;\n    margin-top: 16px;\n}\n\n._3CVf_av8ArfPTRAnC0SI6U {\n    margin-top: 224px;\n    width: 100%;\n}\n", ""]);
// Exports
exports.locals = {
	"text": "_2XiYy4xqkt5wav2TjFPMLc",
	"header": "sAymM9krSN_zt8fYpf9Gd",
	"thanks": "_1Havp8ZX3uXNR1Cz18PyK1",
	"thanks-header": "_3qs3mStyMhStHQG2vvuW4C",
	"thanks-text": "_2kNtGq6va7A-hM6V-qRz4G",
	"thanks-button": "_3CVf_av8ArfPTRAnC0SI6U"
};
module.exports = exports;
