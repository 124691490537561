// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1OKHV3DZxhZ77FipifxbhG {\n    padding: 20px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n._1Gcj1J4i5wZjzX7IohjUCq {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    width:100%;\n}\n\n.AoQEUwRqp67F-MdItGZ9q {\n    margin-bottom: 32px;\n}\n\n.JPF47XM7_mJQ_enr6g6CZ {\n    display: flex;\n    gap: 16px;\n    margin-bottom: 32px;\n}\n\n._1dcIEDEqaPrlUwvxd7PtQP {\n    color: var(--neutral-1000);\n    font-family: \"Nunito Sans\", sans-serif;\n    font-size: 26px;\n    font-weight: 700;\n    line-height: 45px;\n    text-align: center;\n    margin-bottom: 22px;\n}\n\n._754ypzp2USBHLo1trQ5IF {\n    max-width: 430px;\n    margin-bottom: 32px;\n}\n\n._2z5iQm484UWt-Spbf8gfef {\n    font-size: 20px;\n    line-height: 27px;\n    margin-bottom: 32px;\n    text-align: center;\n}\n\n._8ca29jAMnpDXrUD_66S-g {\n    margin-bottom: 22px;\n    max-width: 430px;\n}\n", ""]);
// Exports
exports.locals = {
	"page-container": "_1OKHV3DZxhZ77FipifxbhG",
	"container": "_1Gcj1J4i5wZjzX7IohjUCq",
	"figure": "AoQEUwRqp67F-MdItGZ9q",
	"button-group": "JPF47XM7_mJQ_enr6g6CZ",
	"heading": "_1dcIEDEqaPrlUwvxd7PtQP",
	"body-container": "_754ypzp2USBHLo1trQ5IF",
	"body": "_2z5iQm484UWt-Spbf8gfef",
	"alert": "_8ca29jAMnpDXrUD_66S-g"
};
module.exports = exports;
