// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".tq2WWjfTOb1DSO47I1Y1k {\n    display: flex;\n    margin-top: 44px;\n    margin-bottom: 20px;\n    gap: 16px;\n    align-items: flex-start;\n    flex-wrap: wrap;\n}\n\n.tq2WWjfTOb1DSO47I1Y1k > span {\n    margin-top: 50px;\n}\n\n.qNzg3qugPW4kK2xisIWss {\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n    width: 100%;\n}\n\n._2WNboYjeak8StdmuZ7eOlL {\n    width: 262px;\n}\n\n.RtK73teSZw1veqhjpNp2F {\n    margin-top: 26px;\n}\n", ""]);
// Exports
exports.locals = {
	"combo": "tq2WWjfTOb1DSO47I1Y1k",
	"description": "qNzg3qugPW4kK2xisIWss",
	"input": "_2WNboYjeak8StdmuZ7eOlL",
	"button": "RtK73teSZw1veqhjpNp2F"
};
module.exports = exports;
