// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2Jo6nmHYZPnv3S7e1TF2VP {\n    display: flex;\n    align-items: center;\n    margin-bottom: 8px;\n}\n\n.oak0614-bkdP4uAdrnITN {\n    display: flex;\n    align-items: center;\n    margin-bottom: 1rem;\n}\n\n._17l0TcghnkwK-685CuXz95 {\n    margin-bottom: 1.5rem;\n}", ""]);
// Exports
exports.locals = {
	"line": "_2Jo6nmHYZPnv3S7e1TF2VP",
	"type-line": "oak0614-bkdP4uAdrnITN",
	"alert-wrapper": "_17l0TcghnkwK-685CuXz95"
};
module.exports = exports;
