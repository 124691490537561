// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1O8kjwxpM_8jRTd2W57rgw {\n    display: flex;\n    gap: 28px;\n    margin-top: 32px;\n    margin-bottom: 48px;\n}\n\n._1CCC2sXpzgIOJJWnXGKlpO {\n    font-weight: 700;\n    font-size: 14px;\n    line-height: 18px;\n}\n\n._2mAJpnGXUmiDJQs6aK0Qtq {\n    font-weight: 700;\n    font-size: 14px;\n    line-height: 22px;\n    color: var(--neutral-600);\n    margin: 0 0 4px 0;\n}\n\n.ja7S4bIu9S8Hq4qe86ze9 {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 24px;\n}\n\n._2pJduhspLXxOsPLlmlwLyj {\n    display: flex;\n    flex-direction: column;\n    width: 45%;\n}\n\n._2pJduhspLXxOsPLlmlwLyj._2HKuklZTnal8jLymDL1f8j {\n    width: 50%;\n}\n", ""]);
// Exports
exports.locals = {
	"skeleton": "_1O8kjwxpM_8jRTd2W57rgw",
	"column-title": "_1CCC2sXpzgIOJJWnXGKlpO",
	"row-item": "_2mAJpnGXUmiDJQs6aK0Qtq",
	"row-container": "ja7S4bIu9S8Hq4qe86ze9",
	"column": "_2pJduhspLXxOsPLlmlwLyj",
	"old": "_2HKuklZTnal8jLymDL1f8j"
};
module.exports = exports;
