// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3oNW4Pw9FNUkaUjb1OHB6X {\n    padding-bottom: 16px;\n}\n\n._2m0xH2H2_P2F6oYKo-qp7Q {\n    display: flex;\n    flex-wrap: wrap;\n}\n\n._3YJFVP79RsDdo9fIMW-3PH {\n    font-size: 14px;\n    text-transform: uppercase;\n    margin-top: 0;\n}\n\n._19ITIzc-u-Ha1mXkbP6laH {\n    display: flex;\n    gap: 4px;\n    padding: 0 0 16px 0;\n}\n\n@media (max-width: 980px) {\n    ._19ITIzc-u-Ha1mXkbP6laH {\n        grid-template-columns: 1fr;\n    }\n\n    ._15EhEVqmEmnXVdFJ6aGCKJ {\n        display: flex;\n        flex-wrap: wrap;\n    }\n}\n\n._15EhEVqmEmnXVdFJ6aGCKJ {\n    width: 100%;\n    gap: 4px;\n    display: grid;\n}\n\n.a7Dbh5stvFXf6tyZ5HKlE {\n    padding: 8px 0 0;\n}\n\n@media (min-width: 979px) {\n    .a7Dbh5stvFXf6tyZ5HKlE {\n        width: 90%;\n    }\n}\n\n._27fOxZ4IC1AfJgOI5QspD4 {\n    display: flex;\n    width: 100%;\n    padding: 8px 0 0;\n}\n\n._27fOxZ4IC1AfJgOI5QspD4 > div {\n    flex-grow: 1;\n    width: 100%;\n}\n\n._1ol3Fum7GwXaHDzzZ4P2mB {\n    padding-right: 16px;\n    padding-bottom: 40px;\n}\n\n._2Vr68Z7sZz5-xLXIooljIG {\n    align-self: center;\n    line-height: 28px;\n    font-weight: bold;\n    font-size: 20px;\n    margin: 0;\n}\n\n._2UrqWOQuUMJFWw4muUgBZH {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 40px;\n}\n", ""]);
// Exports
exports.locals = {
	"add-button-row": "_3oNW4Pw9FNUkaUjb1OHB6X",
	"user-name-section": "_2m0xH2H2_P2F6oYKo-qp7Q",
	"subtitle": "_3YJFVP79RsDdo9fIMW-3PH",
	"roles-section": "_19ITIzc-u-Ha1mXkbP6laH",
	"container": "_15EhEVqmEmnXVdFJ6aGCKJ",
	"role-selector": "a7Dbh5stvFXf6tyZ5HKlE",
	"org-selector": "_27fOxZ4IC1AfJgOI5QspD4",
	"property": "_1ol3Fum7GwXaHDzzZ4P2mB",
	"title": "_2Vr68Z7sZz5-xLXIooljIG",
	"title-row": "_2UrqWOQuUMJFWw4muUgBZH"
};
module.exports = exports;
