// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1A7knXS9t958dAxx4Q9NvR {\n    display: flex;\n    border-bottom: 1px solid var(--neutral-200);\n}\n\n._2-pqfYOqVt4UMMFvR7a8MB {\n    border: 1px solid var(--neutral-300);\n    margin-top: 32px;\n    padding: 20px 40px;\n}\n\n.MwvN6LEHmfAzSQpA42uyD {\n    color: var(--neutral-1000);\n    font-size: 12px;\n    margin: 16px 0;\n}\n\n.LEig-ymJV7p74L-X7EeAS {\n    margin: 16px 0 32px 0;\n}\n\n._38O9EVojPYReguoI1Lc1fd {\n    color: var(--neutral-600);\n    font-weight: bold;\n    font-size: 14px;\n    margin-bottom: 10px;\n}\n\n.GfWyDNAMFnATDNvF7BdCV:hover {\n    text-decoration: underline;\n}\n", ""]);
// Exports
exports.locals = {
	"row": "_1A7knXS9t958dAxx4Q9NvR",
	"details": "_2-pqfYOqVt4UMMFvR7a8MB",
	"plan": "MwvN6LEHmfAzSQpA42uyD",
	"plans": "LEig-ymJV7p74L-X7EeAS",
	"label": "_38O9EVojPYReguoI1Lc1fd",
	"link": "GfWyDNAMFnATDNvF7BdCV"
};
module.exports = exports;
