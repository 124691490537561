// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1MQnqN6SdjlB8QWTBo9HmN {\n    display: flex;\n    flex-direction: column;\n    gap: 32px;\n}\n\n@media (min-width: 768px) {\n    ._3idKY112H0QQfPWVCjmKot {\n        display: grid;\n        row-gap: 16px;\n        grid-template-columns: repeat(3, 1fr);\n    }\n\n    ._3idKY112H0QQfPWVCjmKot svg {\n        /*this stops flex:grid from overlapping the copy and information icons within data properties*/\n        margin-right: 4px;\n    }\n}\n\n._3idKY112H0QQfPWVCjmKot label, ._3idKY112H0QQfPWVCjmKot div {\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    overflow: hidden;\n}\n\n._1MQnqN6SdjlB8QWTBo9HmN hr {\n    margin: 25px 0;\n    height: 1px;\n    background-color: var(--neutral-300);\n    border: none;\n}", ""]);
// Exports
exports.locals = {
	"claims-wrapper": "_1MQnqN6SdjlB8QWTBo9HmN",
	"grid": "_3idKY112H0QQfPWVCjmKot"
};
module.exports = exports;
