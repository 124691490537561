// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2YEPryrc_WIMj_nowLZ1US a {\n    text-decoration: underline;\n}\n\n._2gDYZxGy7mw5UbvPXloP5E {\n    display: flex;\n    gap: 16px;\n    margin-bottom: 16px;\n}\n\n._2gDYZxGy7mw5UbvPXloP5E > div {\n    flex: 1;\n}", ""]);
// Exports
exports.locals = {
	"success": "_2YEPryrc_WIMj_nowLZ1US",
	"input-row": "_2gDYZxGy7mw5UbvPXloP5E"
};
module.exports = exports;
