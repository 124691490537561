// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3Pd3nZ3b5eVXKN7hDbLLc {\n  display: flex;\n  flex-direction: column;\n}\n\n._2UKY087EfCvthEgVa4FMSD {\n  padding: 16px 0;\n}\n\n._1p2aRMoJ1t8tvLQTnlyIWf {\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  grid-column-gap: 20px;\n  grid-row-gap: 16px;\n}\n\n.KXeMAuet5qlNWDJn80bKe {\n  grid-column: span 3 / span 3;\n}\n\n._1i7VKI8D0GunFRxATfhpEE {\n  grid-column: span 2 / span 2;\n}\n\n._3lFDLOud0H-h1bCSXwA2yX {\n  margin-bottom: 16px;\n}\n\n._3UuPCmSLuHbBwX55g2SJit {\n  margin: 16px 0;\n}\n", ""]);
// Exports
exports.locals = {
	"form": "_3Pd3nZ3b5eVXKN7hDbLLc",
	"checkbox-wrapper": "_2UKY087EfCvthEgVa4FMSD",
	"grid": "_1p2aRMoJ1t8tvLQTnlyIWf",
	"grid-full-row": "KXeMAuet5qlNWDJn80bKe",
	"grid-two-columns": "_1i7VKI8D0GunFRxATfhpEE",
	"warning-message-wrapper": "_3lFDLOud0H-h1bCSXwA2yX",
	"button-group-wrapper": "_3UuPCmSLuHbBwX55g2SJit"
};
module.exports = exports;
