// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ZdagjjEmg-D22-dw4HEh7 {\n    text-decoration: none;\n    color: var(--neutral-800);\n    font-size: 16px;\n    line-height: 24px;\n    padding: 16px 0;\n    z-index: 1;\n    max-width: none;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n\n.ZdagjjEmg-D22-dw4HEh7._3C-PB56_ZCRA8ozegmybtb {\n    color: var(--neutral-1000);\n    border-bottom: 1px solid var(--blue-800);\n}\n\n.ZdagjjEmg-D22-dw4HEh7:visited {\n    color: var(--neutral-800)\n}\n\n.ZdagjjEmg-D22-dw4HEh7._1WNNcfHeXgHjpArTellcys {\n    max-width: 20ch;\n}", ""]);
// Exports
exports.locals = {
	"link": "ZdagjjEmg-D22-dw4HEh7",
	"active": "_3C-PB56_ZCRA8ozegmybtb",
	"is-analytics": "_1WNNcfHeXgHjpArTellcys"
};
module.exports = exports;
