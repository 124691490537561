// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1wOYcTsaerrbPthBcsyXDg {\n\tdisplay: grid;\n\tgrid-auto-flow: row;\n\tgap: 1rem;\n}\n\n.nGTL2JOb5BRhgcRXLwOEW {\n\tmax-width: 6rem;\n}\n\n._3aK7TV1nMrUiU6PTMtNCdC {\n\toverflow: hidden;\n\ttext-overflow: ellipsis;\n}\n", ""]);
// Exports
exports.locals = {
	"form": "_1wOYcTsaerrbPthBcsyXDg",
	"product-image": "nGTL2JOb5BRhgcRXLwOEW",
	"truncatedId": "_3aK7TV1nMrUiU6PTMtNCdC"
};
module.exports = exports;
