// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".JBWa1fkLJZ4iniJEIL4qN {\n    display: flex;\n    flex: 0 1 200px;\n}\n\n.JBWa1fkLJZ4iniJEIL4qN > div {\n    flex: none;\n    margin: 0 12px;\n}\n\n._1QntFJ51wXnSt1KK-pGB50 {\n    cursor: not-allowed;\n}\n\n._1QntFJ51wXnSt1KK-pGB50 > div {\n    pointer-events: none;\n}", ""]);
// Exports
exports.locals = {
	"slider-cell": "JBWa1fkLJZ4iniJEIL4qN",
	"read-only": "_1QntFJ51wXnSt1KK-pGB50"
};
module.exports = exports;
