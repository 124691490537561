import type { FC } from 'react'
import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  useSearchContractsQuery,
  useListInsuranceClaimsQuery,
} from '@helloextend/extend-api-rtk-query'
import { ToastColor, ToastDuration, useToaster } from '@extend/zen'
import type { ButtonOrLinkButtonProps } from '@extend/zen'
import { useAtomValue } from 'jotai/react'
import { getFileClaimUrl } from '../../../utils/route-helper'
import { StandardHeader } from '../../../components/standard-header'
import { getActiveStoreAtom, getActiveStoreIdAtom } from '../../../atoms/stores'
import { useExportClaimsMutation } from '../../../queries/claims'
import type { SplitButtonProps } from '../../../components/split-button'
import { LDFlag } from '../../../constants/ld-flags'
import { usePermissions } from '../../../hooks/use-permissions'
import { Permission } from '../../../lib/permissions'

const ClaimsHeader: FC = () => {
  const { toast } = useToaster()
  const { push } = useHistory()
  const { hasPermission } = usePermissions()

  const store = useAtomValue(getActiveStoreAtom)
  const storeId = useAtomValue(getActiveStoreIdAtom) || ''
  const { mutateAsync: exportClaims, isLoading: isExportClaimsLoading } = useExportClaimsMutation()
  const { [LDFlag.ExportCarrierClaims]: FF_EXPORT_CARRIER_CLAIMS } = useFlags()

  const { data, isLoading: isDataLoading } = useSearchContractsQuery(
    {
      sellerId: store?.id ?? '',
      searchVersion: '2',
    },
    { refetchOnMountOrArgChange: true },
  )

  const { data: claimsTableData, isLoading: isClaimsTableDataLoading } =
    useListInsuranceClaimsQuery(
      {
        sellerId: store?.id ?? '',
        searchVersion: '2',
      },
      { refetchOnMountOrArgChange: true },
    )

  const isExportAllClaimsProcessing = isClaimsTableDataLoading || isExportClaimsLoading
  const shouldDisableExportClaimButton =
    !claimsTableData?.items.length || isExportAllClaimsProcessing
  const shouldDisableFileClaimButton = !data?.items.length || isDataLoading

  const handleFileAClaimClick = useCallback(() => {
    push(getFileClaimUrl())
  }, [push])

  const handleClickExportClaims = useCallback(async () => {
    try {
      const response = await exportClaims({ storeId })
      if ('message' in response && response.message === 'Job Started') {
        toast({
          message:
            'Your data is being prepared. You will receive an email with a download link shortly.',
          toastDuration: ToastDuration.long,
          toastColor: ToastColor.blue,
        })
      } else {
        toast({
          message:
            'You can only download a new export once every 24 hours. Check your email to download your recent export.',
          toastDuration: ToastDuration.long,
          toastColor: ToastColor.yellow,
        })
      }
    } catch (err) {
      toast({
        message: 'There was an error exporting claims, please try again later.',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }
  }, [exportClaims, storeId, toast])

  const handleClickExportCarrierClaims = useCallback(
    () => push('/store/claims/csv-download?type=carrier'),
    [push],
  )

  const getButtons: Array<ButtonOrLinkButtonProps | SplitButtonProps> = useMemo(() => {
    const buttons: Array<ButtonOrLinkButtonProps | SplitButtonProps> = []

    if (FF_EXPORT_CARRIER_CLAIMS) {
      buttons.push({
        isDisabled: shouldDisableExportClaimButton,
        mainButton: {
          text: 'Export all Claims',
          emphasis: 'medium',
          onClick: handleClickExportClaims,
          isProcessing: isExportAllClaimsProcessing,
          isDisabled: shouldDisableExportClaimButton,
          'data-cy': 'export-claim-button',
        },
        menuButton: { emphasis: 'medium', 'data-cy': 'export-claim-menu-button' },
        items: [
          {
            children: 'Export Carrier Claims',
            onClick: handleClickExportCarrierClaims,
            'data-cy': 'export-carrier-claim-button',
          },
        ],
      })
    } else {
      buttons.push({
        text: 'Export Claims',
        emphasis: 'medium',
        onClick: handleClickExportClaims,
        isProcessing: isExportAllClaimsProcessing,
        isDisabled: shouldDisableExportClaimButton,
        'data-cy': 'export-claim-button',
      })
    }

    if (store?.claimsManagementEnabled && hasPermission(Permission.StoreClaimsFullAccess)) {
      buttons.push({
        text: 'File a Claim',
        emphasis: 'medium',
        onClick: handleFileAClaimClick,
        isProcessing: false,
        isDisabled: shouldDisableFileClaimButton,
        'data-cy': 'file-a-claim-button',
      })
    }

    return buttons
  }, [
    store?.claimsManagementEnabled,
    handleClickExportClaims,
    shouldDisableExportClaimButton,
    handleFileAClaimClick,
    shouldDisableFileClaimButton,
    handleClickExportCarrierClaims,
    isExportAllClaimsProcessing,
    FF_EXPORT_CARRIER_CLAIMS,
  ])

  return <StandardHeader pageTitle="Claims" buttons={getButtons} />
}

export { ClaimsHeader }
