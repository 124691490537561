// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._33WHyjU6Wp0M7bPMNpENFs {\n    max-width: 1004px;\n    margin: 64px auto 0;\n}\n\n._1fFLmj4_YycHW4Cmek3nIe {\n    color: var(--neutral-800);\n    font-size: 22px;\n    font-weight: 700;\n}\n\n._9-P1uwdRTNR8cX3pZQzYW {\n    color: var(--neutral-800);\n    font-size: 20px;\n    line-height: 27px;\n    white-space: pre-line;\n}\n\n.jWgByg6-QOdkJLEyg8G6R {\n    margin-top: 64px;\n}\n\n._6Rj9lBaqD7cHItnBCKMyD {\n    width: 397px;\n}", ""]);
// Exports
exports.locals = {
	"wrapper": "_33WHyjU6Wp0M7bPMNpENFs",
	"header": "_1fFLmj4_YycHW4Cmek3nIe",
	"caption": "_9-P1uwdRTNR8cX3pZQzYW",
	"button-wrapper": "jWgByg6-QOdkJLEyg8G6R",
	"product-icons": "_6Rj9lBaqD7cHItnBCKMyD"
};
module.exports = exports;
