// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3Tk7KHE4wtjBBNcwLFSO1k {\n    z-index: -1;\n    border-bottom: 1px solid var(--neutral-300);\n    margin-bottom: 40px;\n}\n\n._1gsRbsRib88frYvld1HYTY {\n    list-style-type: none;\n    display: flex;\n    justify-content: flex-start;\n    padding: 0;\n    margin: 0;\n    flex-wrap: wrap;\n}\n\n.ZcE-OUH0yMOBFzyAhIubw {\n    display: inline-flex;\n}\n\n.ZcE-OUH0yMOBFzyAhIubw a {\n    margin-left: 24px;\n    margin-right: 24px;\n}\n\n.ZcE-OUH0yMOBFzyAhIubw:first-of-type > a {\n    margin-left: 0;\n}\n\n.ZcE-OUH0yMOBFzyAhIubw:last-of-type > a {\n    margin-right: 0;\n}", ""]);
// Exports
exports.locals = {
	"tab-bar-wrapper": "_3Tk7KHE4wtjBBNcwLFSO1k",
	"list": "_1gsRbsRib88frYvld1HYTY",
	"list-item": "ZcE-OUH0yMOBFzyAhIubw"
};
module.exports = exports;
