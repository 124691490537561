// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2oAYbvveEWdOcuiRfrzcMT {\n    max-width: 816px;\n    margin: auto;\n}\n\n._2oAYbvveEWdOcuiRfrzcMT h2 {\n    text-align: center;\n}\n\n._2oAYbvveEWdOcuiRfrzcMT ._3EUlVEWvGtttQfNZ4tBehE {\n    margin: 0 auto 56px auto;\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n}\n\n._2oAYbvveEWdOcuiRfrzcMT div[class*=\"StyledTextArea\"] {\n    align-self: center;\n    max-width: 816px;\n    width: 650px;\n}\n\n._2oAYbvveEWdOcuiRfrzcMT textarea {\n    height: 169px;\n}\n", ""]);
// Exports
exports.locals = {
	"form": "_2oAYbvveEWdOcuiRfrzcMT",
	"text-form-wrapper": "_3EUlVEWvGtttQfNZ4tBehE"
};
module.exports = exports;
