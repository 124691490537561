// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._32HUthdm6Qh78j85tvJSyI {\n  margin-bottom: 20px;\n}", ""]);
// Exports
exports.locals = {
	"data-property-wrapper": "_32HUthdm6Qh78j85tvJSyI"
};
module.exports = exports;
