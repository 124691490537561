import { useCallback } from 'react'
import type { PrecheckRequest, PrecheckResponse } from '@extend-services/adjudication-management'
import { EXTEND_API_HOST } from '@helloextend/client-constants'
import { useAtomValue } from 'jotai/react'
import { v3AccessTokenAtom } from '../atoms/auth'
import type { QueryObserverResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

const BASE_URL = `https://${EXTEND_API_HOST}`
const COMMON_HEADERS = {
  'Content-Type': 'application/json',
  accept: 'application/json; version=latest',
}

export function usePrecheckQuery(
  body: PrecheckRequest,
): QueryObserverResult<PrecheckResponse, Error> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  const queryFn = useCallback(async () => {
    const response = await fetch(`${BASE_URL}/adjudication-management/pre-check`, {
      headers: {
        ...COMMON_HEADERS,
        'x-extend-access-token': accessToken,
      },
      method: 'POST',
      body: JSON.stringify(body),
    })

    if (!response.ok) {
      throw new Error('Unable to precheck')
    }

    return response.json()
  }, [accessToken])

  return useQuery({
    queryKey: ['precheck', body.contractId],
    queryFn,
    enabled: true,
  })
}
