// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._37uqLZTj5KyJJAN3ouGcf_ {\n    width: 440px;\n}\n\n.K-LNoWV5Wl-hEspWFaHLZ {\n    font-weight: 700;\n    font-size: 14px;\n    line-height: 22px;\n    color: var(--neutral-1000);\n    margin: 24px 0 4px;\n}\n\n._38uVCvwILHHrolUk1iKj7W {\n    border: 1px solid var(--neutral-300);\n    border-radius: 4px;\n    height: 22px;\n    display: flex;\n    align-items: center;\n    padding: 8px 12px;\n    cursor: pointer;\n}\n\n._38uVCvwILHHrolUk1iKj7W > span {\n    width: 100%;\n}\n\n._38ajlSvikSL4x0z_npvf8f {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n}\n\n.j20Q1gGB25zBqvoyTCY96 {\n    display: inline-block;\n    transition: transform .2s ease-in;\n    transform: scale(0.8);\n    margin-left: 16px;\n}\n\n.j20Q1gGB25zBqvoyTCY96.DtGaW8M6rCAlurhqHkaki {\n    transform: scale(0.8) rotate(180deg);\n}\n\n.X0hqaIRRyu_pZqTT9Ex2w {\n    display: flex;\n    gap: 8px;\n    width: 400px;\n    align-items: center;\n}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "_37uqLZTj5KyJJAN3ouGcf_",
	"title": "K-LNoWV5Wl-hEspWFaHLZ",
	"theme-selector": "_38uVCvwILHHrolUk1iKj7W",
	"item": "_38ajlSvikSL4x0z_npvf8f",
	"caret": "j20Q1gGB25zBqvoyTCY96",
	"is-open": "DtGaW8M6rCAlurhqHkaki",
	"menu-item": "X0hqaIRRyu_pZqTT9Ex2w"
};
module.exports = exports;
