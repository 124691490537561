// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2cihEaBlfibRiXcWWLrmYc {\n    color: var(--white);\n    font-size: 14px;\n    line-height: 22px;\n    font-weight: 700;\n    margin: 0;\n}", ""]);
// Exports
exports.locals = {
	"label": "_2cihEaBlfibRiXcWWLrmYc"
};
module.exports = exports;
