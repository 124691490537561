// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._132mT8p1GWxusBJ6IYKGeE {\n  font-size: 14px;\n  line-height: 18px;\n}\n\n@media (min-width: 768px) {\n  ._n3G8sRpSKKC0SJLA_rX9 {\n      display: grid;\n      row-gap: 16px;\n      grid-template-columns: repeat(2, 1fr);\n  }\n\n  ._3QGJT0sVv4QZEGoL-W5rC1 {\n    display: grid;\n    row-gap: 16px;\n    grid-template-columns: repeat(3, 1fr);\n  }\n}\n\n._n3G8sRpSKKC0SJLA_rX9 label, ._n3G8sRpSKKC0SJLA_rX9 div, ._3QGJT0sVv4QZEGoL-W5rC1 label, ._3QGJT0sVv4QZEGoL-W5rC1 div {\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n}", ""]);
// Exports
exports.locals = {
	"h4-styles": "_132mT8p1GWxusBJ6IYKGeE",
	"grid": "_n3G8sRpSKKC0SJLA_rX9",
	"addressGrid": "_3QGJT0sVv4QZEGoL-W5rC1"
};
module.exports = exports;
