import React, { useMemo, useCallback } from 'react'
import type { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { useAtomValue } from 'jotai/react'
import { DataTable } from '@extend/zen'
import type { DataTableAction } from '@extend/zen'
import type { CategoryProduct } from '@helloextend/extend-api-client'
import { usePrecheckQuery } from '@helloextend/extend-api-rtk-query'
import { CollapsibleSection } from '../../../../components/collapsible-section'
import { contractDetailsAtom } from '../../../../atoms/contract-details'
import { getCategoryProductsColumns } from './category-products-table-config'
import type { MerchantContract } from '../../../../types/merchant-contract'
import { usePermissions } from '../../../../hooks/use-permissions'
import { Permission } from '../../../../lib/permissions'

export const CategoryProductsTable: FC = () => {
  const { hasPermission } = usePermissions()
  const contract = useAtomValue(contractDetailsAtom)
  const history = useHistory()

  const categoryProducts = useMemo(() => getCategoryProductsFromContract(contract), [contract])

  const { data: precheckResponse } = usePrecheckQuery({
    contractId: contract.id,
  })

  const handleFileClaim = useCallback(
    (product): DataTableAction[] => {
      const isDisabledForProduct = Boolean(
        product.limitOfLiability === 0 ||
          !precheckResponse ||
          precheckResponse.status === 'failure' ||
          (precheckResponse.lineItems &&
            precheckResponse.lineItems[product.lineItemId]?.hasActiveClaim),
      )

      return hasPermission(Permission.StoreClaimsFullAccess)
        ? [
            {
              text: 'File Claim',
              emphasis: 'low',
              isDisabled: isDisabledForProduct,
              onClick: () =>
                history.push(
                  `/store/contracts/${product.contractId}/products/${product.lineItemId}/claims/new`,
                ),
              'data-cy': `${product.referenceId}-file-claim-btn`,
            },
          ]
        : []
    },
    [history, precheckResponse],
  )

  return (
    <CollapsibleSection heading="Products" isExpanded>
      <DataTable
        isError={false}
        isLoading={false}
        data={categoryProducts}
        columns={getCategoryProductsColumns()}
        getRowActions={handleFileClaim}
      />
    </CollapsibleSection>
  )
}

const dateFormat = new Intl.DateTimeFormat(undefined, {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
})

/* exporting the following functions for testing purposes */
export function getProductCoverage(coverage: CategoryProduct['coverage']): string {
  if (coverage && coverage?.starts && coverage?.ends) {
    const { starts, ends } = coverage

    const START_DATE = dateFormat.format(new Date(starts))
    const END_DATE = dateFormat.format(new Date(ends))

    return `${START_DATE} - ${END_DATE}`
  }

  return ''
}

export function dedupeProducts(products: CategoryProduct[]): CategoryProduct[] {
  const seen = new Set()
  const dedupedProducts: CategoryProduct[] = []

  for (const product of products) {
    const { lineItemId } = product

    if (!seen.has(lineItemId)) {
      seen.add(lineItemId)
      dedupedProducts.push(product)
    }
  }

  return dedupedProducts
}

export function getCategoryProductsFromContract(contract: MerchantContract): CategoryProduct[] {
  const products =
    contract.categoryProductsList?.map((product) => ({
      ...product,
      category: contract?.planDetails?.category,
      coverageDuration: getProductCoverage(product?.coverage),
    })) || []

  return dedupeProducts(products)
}
