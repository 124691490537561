// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FRgKjPRJItM7O0By8WjGw {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n}\n\n.FRgKjPRJItM7O0By8WjGw div {\n    margin: 0;\n}", ""]);
// Exports
exports.locals = {
	"container": "FRgKjPRJItM7O0By8WjGw"
};
module.exports = exports;
