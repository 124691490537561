// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1ivvoKlSH5X6dL_nglcjbY {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    color: var(--neutral-1000);\n    font-weight: 800;\n}\n\n._15svsLUo756CuJwUWnCA2c {\n  font-size: 14px;\n  line-height: 20px;\n  padding: 13px 16px;\n}\n\n.-WkEnqa4WzXF9NND7ZxNv {\n  list-style: none;\n  padding: 0;\n  margin: 0;\n}", ""]);
// Exports
exports.locals = {
	"store-name-wrapper": "_1ivvoKlSH5X6dL_nglcjbY",
	"active-store-wrapper": "_15svsLUo756CuJwUWnCA2c",
	"store-dropdown-list": "-WkEnqa4WzXF9NND7ZxNv"
};
module.exports = exports;
