import React, { useCallback, useState } from 'react'
import { useAtomValue, useSetAtom } from 'jotai/react'
import { Box, Checkbox, DataProperty, Grid, GridItem, Radio, Stack } from '@extend/zen'
import { CreateContractsModal } from './create-contracts-modal'
import {
  productAtom,
  orderAtom,
  modalStepAtom,
  productDetailsAtom,
  planDetailsAtom,
  transactionIdAtom,
  contractCreatedAtom,
} from '../../../../atoms/create-contracts-modal'
import { currency } from '@extend/client-helpers'
import { CreateContractsModalStep } from '../../../../constants/create-contracts-modal-step'
import { getContractTermLengthDisplayText } from '../../../../utils/contract-mappers'
import styles from './create-contracts-modal-confirmation.module.css'
import { CustomerInformationGrid } from './customer-information-grid'
import { useCreateContracts } from '../../../../hooks/use-create-contracts'
import { useCreateContractsModalClose } from '../../../../hooks/use-create-contracts-modal-close'

export const CreateContractsModalConfirmation = () => {
  const product = useAtomValue(productAtom)
  const productDetails = useAtomValue(productDetailsAtom)
  const order = useAtomValue(orderAtom)
  const planDetails = useAtomValue(planDetailsAtom)
  const transactionId = useAtomValue(transactionIdAtom)
  const setModalStep = useSetAtom(modalStepAtom)
  const [accepted, setHasAccepted] = useState(false)
  const setCreatedContractInfo = useSetAtom(contractCreatedAtom)
  const [errorMsg, setErrorMsg] = useState<string | null>(null)

  const { createContracts } = useCreateContracts()
  const { handleModalClose } = useCreateContractsModalClose()

  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = useCallback(
    async (event?: React.FormEvent) => {
      if (event) {
        event.preventDefault()
      }

      setErrorMsg(null)
      setIsLoading(true)
      try {
        const lineItems = await createContracts()
        setCreatedContractInfo({
          customerName: order?.customer?.name || '',
          contractIds: lineItems.reduce<string[]>((acc, item) => {
            if ('contractId' in item && item.contractId) {
              acc.push(item.contractId)
            }
            return acc
          }, [] as string[]),
          transactionId,
          pendingDismissed: false,
          successDismissed: false,
          contractsCreated: false,
        })
        setIsLoading(false)
        handleModalClose()
      } catch (e: unknown) {
        setIsLoading(false)
        setErrorMsg('An unexpected error occurred. Please try again later.')
      }
    },
    [
      setErrorMsg,
      setIsLoading,
      createContracts,
      setCreatedContractInfo,
      order,
      transactionId,
      handleModalClose,
    ],
  )

  return (
    <CreateContractsModal
      primaryButton={{
        text: 'Confirm',
        onClick: handleSubmit,
        isDisabled: !accepted,
        isProcessing: isLoading,
      }}
      secondaryButton={{
        text: 'Back',
        onClick: () => {
          setModalStep(CreateContractsModalStep.PlanSelection)
        },
      }}
      errorMsg={errorMsg}
    >
      <Stack spacing={3}>
        <Box>
          <h3>Order Information</h3>
          <DataProperty label="Transaction ID" value={transactionId} />
        </Box>

        <Box>
          <h3>Customer Information</h3>
          <CustomerInformationGrid order={order} />
        </Box>

        <Box>
          <h3>Product Information</h3>
          <Grid columns={4}>
            <DataProperty label="Reference ID" value={product?.referenceId} />
            <DataProperty label="Product Name" value={product?.title} />
            <DataProperty label="Category" value={product?.category} />
            <DataProperty
              label="Product List Price"
              value={currency.format(product?.price?.amount, order?.currency || 'USD')}
            />
          </Grid>
          <Grid columns={3}>
            <DataProperty label="Purchase Date" value={productDetails?.purchaseDate} />
            <DataProperty
              label="Purchase Price"
              value={currency.format(
                parseInt(productDetails?.purchasePrice || '0', 10),
                order?.currency || 'USD',
              )}
            />
            <DataProperty
              label="Number of Contracts Need"
              value={`${planDetails?.numContracts || 0}`}
            />

            <GridItem fillWidth>
              <h4>Plans</h4>
              <Radio
                disabled
                checked
                label={`${planDetails?.plan?.title} - ${getContractTermLengthDisplayText(
                  planDetails?.plan?.contract?.termLength || 0,
                )} - ${currency.format(planDetails?.plan?.price, planDetails?.plan?.currencyCode)}`}
                value={planDetails?.plan?.id || ''}
              />
            </GridItem>
          </Grid>
        </Box>
        <form onSubmit={handleSubmit}>
          <Checkbox
            data-cy="authorize:checkbox"
            label={
              <>
                <div>
                  By creating this service contract for the customer, you agree to collect the
                  customer’s payment and pay Extend their portion following the master services
                  agreement.
                </div>
                <div className={styles['customer-email-text']}>
                  The customer will receive an email with their contract details upon completion.
                </div>
              </>
            }
            checked={accepted}
            onChange={() => setHasAccepted(!accepted)}
          />
          {/* Submit button is required to allow for submission via Enter key */}
          <button type="submit" style={{ display: 'none' }} />
        </form>
      </Stack>
    </CreateContractsModal>
  )
}
