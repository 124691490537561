// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BmN_F4Oz1r_Rg6MVbCtXu {\n    display: flex;\n    flex-flow: row wrap;\n}\n\n.xSl9zgeZ0yzJH10p4NA0z {\n    flex: 1 0 100%;\n}\n\n._3OjbWh1Ze5B8VAtSfuLHco {\n    width: 140px;\n    height: 140px;\n}\n\n._1EJkWWhqjoxCRaSHXMNtPU {\n    height: 100%;\n    object-fit: cover;\n    object-position: center center;\n    width: 100%;\n}\n\n._3oAAmaH8q5zugSUeAMCVoJ {\n    flex-grow: 1;\n}\n\n._1mTNkdd4eIOiyKfxWdY1ZC {\n    margin-top: 8px;\n    flex: 1 0 100%;\n}\n\n/* universal selector for the list items mapped */\n/* that proceeds an adjacent element. */\n/* Source: https://alistapart.com/article/axiomatic-css-and-lobotomized-owls/ */\n._1mTNkdd4eIOiyKfxWdY1ZC > * + * {\n    margin-top: 16px;\n}\n\n._2E5MJWlvlFqT3bBRuX7gtZ {\n    display: flex;\n    flex-flow: column wrap;\n    margin-top: 32px;\n    align-items: baseline;\n}\n\n._2E5MJWlvlFqT3bBRuX7gtZ > * + * {\n    margin: 16px 0 0 0;\n}\n\n@media (min-width: 1025px) {\n    .xSl9zgeZ0yzJH10p4NA0z {\n        flex: 0 1;\n        margin-right: 16px;\n    }\n\n    ._1mTNkdd4eIOiyKfxWdY1ZC {\n        margin-top: 0;\n        flex: 1 0 50%;\n    }\n\n    ._2E5MJWlvlFqT3bBRuX7gtZ {\n        flex-flow: row wrap;\n    }\n\n    ._2E5MJWlvlFqT3bBRuX7gtZ > * + * {\n        margin: 0 0 0 16px;\n    }\n}", ""]);
// Exports
exports.locals = {
	"flex-wrapper": "BmN_F4Oz1r_Rg6MVbCtXu",
	"figure": "xSl9zgeZ0yzJH10p4NA0z",
	"image-wrapper": "_3OjbWh1Ze5B8VAtSfuLHco",
	"image": "_1EJkWWhqjoxCRaSHXMNtPU",
	"details": "_3oAAmaH8q5zugSUeAMCVoJ",
	"details-column": "_1mTNkdd4eIOiyKfxWdY1ZC",
	"buttons": "_2E5MJWlvlFqT3bBRuX7gtZ"
};
module.exports = exports;
