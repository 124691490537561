// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1DvnEIouAHjOKNjKR5mQkV {\n    list-style-position: inside;\n    padding-left: 8px;\n}\n\n.ZkES_Crm_UkYArYcUouLT {\n    padding-top: 4px;\n    font-weight: normal;\n}\n\n._3J2uDl7PQ7-RfzTQta8kI_ {\n    font-weight: normal;\n}\n\n._3uLnh12tqks1jHwAWO4HTH {\n    padding-left: 16px;\n    list-style-type: none;\n    font-weight: normal;\n}\n\n._1_V38jEU4V_qMCL6X_rnOG {\n    font-weight: 700;\n}\n", ""]);
// Exports
exports.locals = {
	"list": "_1DvnEIouAHjOKNjKR5mQkV",
	"item": "ZkES_Crm_UkYArYcUouLT",
	"first": "_3J2uDl7PQ7-RfzTQta8kI_",
	"plain": "_3uLnh12tqks1jHwAWO4HTH",
	"info": "_1_V38jEU4V_qMCL6X_rnOG"
};
module.exports = exports;
