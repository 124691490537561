// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../images/extend-placeholder-mountains.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "._2xaSrfEGYqm_4hT_QJCD7g {\n    padding: 8px 0;\n}\n\n.u3mXDTXyxUi1ZNx0Ad5tj {\n    display: block;\n    overflow-y: hidden;\n    width: 178px;\n    height: 178px;\n}\n\n.u3mXDTXyxUi1ZNx0Ad5tj._3LeHmqEp7MIXOk-zvrfpui {\n    width: 40px;\n    height: 40px;\n}\n\n._27ae_WUcqMBH3mCyPy3E0A {\n    background-size: cover;\n    background: none;\n    height: 100%;\n}\n\n._27ae_WUcqMBH3mCyPy3E0A._2AhCBcEM44edXK1p6SXAxD {\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center no-repeat;\n    height: 40px;\n}\n\n._27ae_WUcqMBH3mCyPy3E0A._2AhCBcEM44edXK1p6SXAxD.FYlDqABfZ_X80frN516PV {\n    height: 178px;\n}\n\n._3Cb4n1jtcOcG9Wc_U_daOD {\n    height: 100%;\n    width: 100%;\n    object-fit: contain;\n    word-break: break-all;\n    overflow-wrap: anywhere;\n    display: inline-block;\n}\n", ""]);
// Exports
exports.locals = {
	"product-cell": "_2xaSrfEGYqm_4hT_QJCD7g",
	"image-column": "u3mXDTXyxUi1ZNx0Ad5tj",
	"small": "_3LeHmqEp7MIXOk-zvrfpui",
	"product-image-wrapper": "_27ae_WUcqMBH3mCyPy3E0A",
	"show-placeholder": "_2AhCBcEM44edXK1p6SXAxD",
	"default-size": "FYlDqABfZ_X80frN516PV",
	"product-image": "_3Cb4n1jtcOcG9Wc_U_daOD"
};
module.exports = exports;
