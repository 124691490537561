// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".QBkGPlAdYUpZKrUM7pY8x {\n    width: 541px;\n}\n\n._31AU-KLqK3v9F5LOHZtTLn {\n    display: flex;\n    flex-direction: column;\n    padding-bottom: 24px;\n}\n._31AU-KLqK3v9F5LOHZtTLn >:nth-of-type(n+2) {\n    margin-top: 16px;\n}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "QBkGPlAdYUpZKrUM7pY8x",
	"container": "_31AU-KLqK3v9F5LOHZtTLn"
};
module.exports = exports;
