// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3ibQqvC9gg72o2h1bH3bxu {\n    display: grid;\n}\n\n/* Form Input Grid Styles */\n._2H2Fbdbnz7dgoEUJv_Rgvw {\n    display: grid;\n    column-gap: 16px;\n    grid-template-columns: calc(25% - 16px) calc(25% - 16px) calc(12.5% - 16px) calc(12.5% - 16px) calc(12.5% - 16px) 12.5%;\n}\n\n._2XRzRpWMYoGqkOJeY1T3-E {\n    display: grid;\n    column-gap: 16px;\n    grid-template-columns: calc(25% - 16px) calc(25% - 16px) 50%;\n}\n\n/*===============================*/\n\n._6mAQrSHiQmAGqCzA0BqEG {\n    font-size: 20px;\n}\n\n._2p4_20LhSYLaw3leQpiWcy {\n    display: flex;\n    align-items: center;\n    margin-left: auto;\n    gap: 0.6em;\n    padding-top: 30px;\n}\n\n._1i-r_aiZOVBL79dLJfyoui {\n    align-items: center;\n    background-color: var(--red-200);\n    border-radius: 6px;\n    display: flex;\n    height: 52px;\n    gap: 8px;\n    line-height: 20px;\n    padding: 0 16px;\n}\n\n._3PnytYWFYrxHNfiWdznS0h {\n    color: var(--red-600);\n    font-size: 15px;\n    font-weight: 400;\n    line-height: 20px;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}\n\n.JyLNq39SBXXbTSCXv2fCg {\n    display: grid;\n    grid-template-columns: 25% 25% 50%;\n}\n\n._1lmiRlAsN59L0TsvC587Yl {\n    display: grid;\n    grid-template-columns: 25% 25% 12.5% 12.5% 12.5% 12.5%;\n}", ""]);
// Exports
exports.locals = {
	"form-container": "_3ibQqvC9gg72o2h1bH3bxu",
	"six-input-grid": "_2H2Fbdbnz7dgoEUJv_Rgvw",
	"three-input-grid": "_2XRzRpWMYoGqkOJeY1T3-E",
	"h4-styles": "_6mAQrSHiQmAGqCzA0BqEG",
	"button-group": "_2p4_20LhSYLaw3leQpiWcy",
	"error-message-container": "_1i-r_aiZOVBL79dLJfyoui",
	"error-message": "_3PnytYWFYrxHNfiWdznS0h",
	"three-property-grid": "JyLNq39SBXXbTSCXv2fCg",
	"six-property-grid": "_1lmiRlAsN59L0TsvC587Yl"
};
module.exports = exports;
