// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".YtMIBAKqtLPg_qLqje5GG {\n    display: flex;\n    gap: 8px;\n}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "YtMIBAKqtLPg_qLqje5GG"
};
module.exports = exports;
