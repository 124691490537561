// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2P43Rua7DImnUZY4MIC2kx form {\n    display: flex;\n    flex-direction: column;\n    gap: 19px;\n}\n\n._30GC0HX0Aj4KKHQSwIY_m0 {\n    display: grid;\n    grid-template-columns: repeat(5, 1fr);\n    gap: 16px;\n    min-height: 90px;\n}\n\n._3ficYN9oCgP-0nEWlzFO-0 {\n  grid-column: span 2;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "_2P43Rua7DImnUZY4MIC2kx",
	"grid": "_30GC0HX0Aj4KKHQSwIY_m0",
	"two-column": "_3ficYN9oCgP-0nEWlzFO-0"
};
module.exports = exports;
