export enum LDFlag {
  EnterpriseInitiative = 'ao-enterprise-initiative-features',
  ClaimsPhoneSearch = 'claims-phone-search',
  EnterpriseTenancyR3 = 'enterprise-tenancy-r3',
  MpEER4Integrations = 'mp-ee-r4-integrations',
  ContractsPOSearch = 'contracts-po-search',
  UseClaimProducts = 'use-claim-products',
  SelectFulfillmentOption = 'post-purchase-select-fulfillment-option',
  NewClaimAttachments = 'new-claim-attachments',
  // TODO: [MINT-2400] Remove FF
  ExportCarrierClaims = 'export-carrier-claims',
  CreateContracts = 'merchant-portal-create-contracts',
  MerchantReviewClaims = 'merchant-portal-review-claims',
  // TODO: [MINT-2609] Remove automated-replacement-fulfillment FF
  AutomatedReplacements = 'automated-replacement-fulfillment',
  ServicerRateCards = 'servicer-rate-cards',
  ViewOnlyRole = 'view-only-role',
  ManualShippingProtectionFulfillment = 'manual-shipping-protection-fulfillment',
  ExpenseUpdates = 'post-lol-expenses-updates',
}

export type LDFlagType = boolean | string | number | JSON

// when updating the flags, update cypress/support/helpers/ff.ts as well
export const defaultLDFlagValues: Record<LDFlag, LDFlagType> = {
  [LDFlag.EnterpriseInitiative]: false,
  [LDFlag.ClaimsPhoneSearch]: false,
  [LDFlag.EnterpriseTenancyR3]: false,
  [LDFlag.ContractsPOSearch]: false,
  [LDFlag.MpEER4Integrations]: false,
  [LDFlag.UseClaimProducts]: false,
  [LDFlag.SelectFulfillmentOption]: false,
  [LDFlag.NewClaimAttachments]: false,
  [LDFlag.ExportCarrierClaims]: false,
  [LDFlag.CreateContracts]: false,
  [LDFlag.MerchantReviewClaims]: false,
  [LDFlag.AutomatedReplacements]: false,
  [LDFlag.ServicerRateCards]: false,
  [LDFlag.ViewOnlyRole]: false,
  [LDFlag.ManualShippingProtectionFulfillment]: false,
  [LDFlag.ExpenseUpdates]: false,
}
