// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".vttPpLcHXyl9zQxX468J {\n    width: 355px;\n    margin-top: 48px;\n}\n\n._24BEjrcdJuDp4ieWs6mUGH {\n    color: var(--blue-900);\n    font-weight: bold;\n    margin-bottom: 20px;\n    text-align: center;\n}\n\n@media (min-width: 768px) {\n    .vttPpLcHXyl9zQxX468J {\n        max-height: none;\n    }\n}", ""]);
// Exports
exports.locals = {
	"wrapper": "vttPpLcHXyl9zQxX468J",
	"followup": "_24BEjrcdJuDp4ieWs6mUGH"
};
module.exports = exports;
