import type { FC } from 'react'
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Button, InlineAlert, InlineAlertColor } from '@extend/zen'
import { querystring } from '@extend/client-helpers'
import { useAtomValue } from 'jotai/react'
import images from '../images'
import { useClosePageGuard } from '../hooks/use-close-page-guard'
import styles from './magento-install.module.css'
import { cachedAccountNameAtom, cachedRolesAtom, v3AccessTokenAtom } from '../atoms/auth'
import { DashboardLayout } from '../components/dashboard-layout'
import { DashboardSection } from '../constants/dashboard-sections'
import { useMagentoRegistrationMutation } from '../queries/magento-registration'
import { getAccountIdFromV3Token } from '../lib/jwt'

type QueryStringProps = {
  oauth_consumer_key: string
  success_call_back: string
}

export const MagentoInstall: FC = () => {
  const [wasConnectClicked, setWasConnectClicked] = useState(false)
  const [isNavigating, setIsNavigating] = useState(false)
  const [shouldRedirect, setShouldRedirect] = useState(false)
  const [validationError, setValidationError] = useState<string | undefined>(undefined)
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined)

  const accountName = useAtomValue(cachedAccountNameAtom)
  const accessToken = useAtomValue(v3AccessTokenAtom)
  const cachedRoles = useAtomValue(cachedRolesAtom)

  const {
    mutateAsync: registerMagento,
    isLoading: isMagentoRegistrationLoading,
    error: registrationError,
  } = useMagentoRegistrationMutation()

  const { search } = useLocation()
  const { oauth_consumer_key: magentoConsumerKey, success_call_back: magentoRedirectUrl } =
    querystring.parse<QueryStringProps>(search)

  useClosePageGuard(wasConnectClicked)

  useLayoutEffect(() => {
    if (shouldRedirect) {
      if (magentoRedirectUrl) {
        window.location.href = magentoRedirectUrl
        // Continue to disable the Connect button
        // to prevent the user from creating another store
        // while navigating back to Magento
        setIsNavigating(true)
      } else {
        // Show success message
        setSuccessMessage('Successfully connected. You may now exit this page.')
      }
    }
  }, [shouldRedirect, magentoRedirectUrl, setIsNavigating, setSuccessMessage])

  const isConnecting = (isMagentoRegistrationLoading && !registrationError) || isNavigating
  const v3AccountId = useMemo(() => getAccountIdFromV3Token(accessToken), [accessToken])
  const grant = useMemo(
    () => (v3AccountId ? cachedRoles[v3AccountId] : undefined),
    [v3AccountId, cachedRoles],
  )

  useEffect(() => {
    const msg =
      'Something went wrong. Please logout and click "Reauthorize" in your Magento instance to retry the installation.'
    if (!v3AccountId) {
      console.log("No account ID found in the user's access token")
      setValidationError(msg)
    } else if (!grant) {
      console.log('No grant found for the selected account')
      setValidationError(msg)
    } else if (!magentoConsumerKey) {
      console.log('Missing required query parameter: oauth_consumer_key')
      setValidationError(msg)
    }
  }, [v3AccountId, grant, magentoConsumerKey])

  const errorMessage = validationError || registrationError?.message

  const handleConnect = async (): Promise<void> => {
    setWasConnectClicked(true)

    if (!v3AccountId || !grant) {
      // This should never happen as an error should have been shown to the user
      // and the connect button disabled
      return
    }

    if (accessToken) {
      await registerMagento({
        accessToken,
        oauth_consumer_key: magentoConsumerKey,
      })
      setShouldRedirect(true)
    }
  }

  return (
    <DashboardLayout section={DashboardSection.None} isSidebarVisible={false}>
      <div className={styles['page-container']}>
        <div className={styles.container}>
          <figure className={styles.figure} data-cy="magento-install-logo">
            <img src={images.magento} alt="Magento Install" />
          </figure>
          <h1 className={styles.heading} data-cy="magento-install-header">
            Connect to Magento
          </h1>
          {errorMessage && (
            <div className={styles.alert}>
              <InlineAlert color={InlineAlertColor.red} data-cy="magento-install-error-alert">
                {errorMessage}
              </InlineAlert>
            </div>
          )}
          {!validationError && (
            <>
              <div className={styles['body-container']}>
                <div className={styles.body} data-cy="magento-install-body">
                  <p>
                    Click “Connect” to integrate account <strong>{accountName}</strong> with your
                    Magento instance.
                  </p>
                  <p>
                    <em>
                      <small>
                        Not the right account? <Link to={'/logout'}>Logout</Link> and click
                        "Reauthorize" in your Magento instance to complete this installation.
                      </small>
                    </em>
                  </p>
                </div>

                {successMessage ? (
                  <div className={styles.alert}>
                    <InlineAlert
                      color={InlineAlertColor.green}
                      data-cy="magento-install-success-alert"
                    >
                      {successMessage}
                    </InlineAlert>
                  </div>
                ) : (
                  <InlineAlert color={InlineAlertColor.blue} data-cy="magento-install-alert">
                    If you leave without connecting Magento, your Extend app installation will fail.
                    You may need to click "Reauthorize" on the Extend integration in Magento and
                    complete this installation flow again.
                  </InlineAlert>
                )}
              </div>
              <div className={styles['button-group']}>
                <Button
                  data-cy="magento-install-button-connect"
                  emphasis="high"
                  text="Connect"
                  isDisabled={isConnecting || !!validationError || !!successMessage}
                  isProcessing={isConnecting}
                  onClick={handleConnect}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </DashboardLayout>
  )
}
