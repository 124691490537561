// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._13lPMCs6wEYuVgDdiJt-v0 {\n    margin-top: 32px;\n}\n\n.-ffbJEWuBLiC_PGRbpYYR {\n    align-self: center;\n    line-height: 28px;\n    font-weight: bold;\n    font-size: 20px;\n    margin: 0;\n}\n\n._3W_4zFBvZyf6tl_VnFh4nW {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 40px;\n}\n\n._2Hox6GsAAZI80fDab4bpZw {\n    margin-right: 16px;\n}\n\n.FVhFVMQorlAUJ6YwWQqmJ {\n    font-size: 14px;\n    text-transform: uppercase;\n    margin-top: 0;\n}\n\n.kA8LvIRY7Ju5CTbjvGHna {\n    display: flex;\n    margin-bottom: 16px;\n    width: 100%;\n    border-bottom: 1px solid var(--neutral-300);\n}\n\n.kA8LvIRY7Ju5CTbjvGHna:last-child {\n    border-bottom: none;\n}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "_13lPMCs6wEYuVgDdiJt-v0",
	"title": "-ffbJEWuBLiC_PGRbpYYR",
	"title-row": "_3W_4zFBvZyf6tl_VnFh4nW",
	"property": "_2Hox6GsAAZI80fDab4bpZw",
	"subtitle": "FVhFVMQorlAUJ6YwWQqmJ",
	"row": "kA8LvIRY7Ju5CTbjvGHna"
};
module.exports = exports;
