// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1D2YEpW-8bj2mdQlXhvkno {\n    display: flex;\n    flex-flow: row wrap;\n}\n\n._13VD-TN5Q8RGPQDnKRN__1 {\n    flex: 1 0 100%;\n}\n\n._2onf_TW2qp8s5p_IagJQya {\n    font-size: 18px;\n    margin-bottom: 8px;\n}\n\n._1ftR8CtgEv0PLIq1v161Gi {\n    flex-grow: 1;\n}\n\n.itu3JNbhLVdZto5lZ0xux {\n    margin-top: 8px;\n    flex: 1 0 100%;\n}\n\n.itu3JNbhLVdZto5lZ0xux > * + * {\n    margin-top: 16px;\n}\n\n@media (min-width: 1025px) {\n    ._13VD-TN5Q8RGPQDnKRN__1 {\n        flex: 0 1 348px;\n        margin-right: 46px;\n    }\n\n    ._2onf_TW2qp8s5p_IagJQya {\n        max-width: 178px;\n    }\n\n    .itu3JNbhLVdZto5lZ0xux {\n        margin-top: 0;\n        flex: 1 0 50%;\n    }\n}", ""]);
// Exports
exports.locals = {
	"flex-wrapper": "_1D2YEpW-8bj2mdQlXhvkno",
	"header": "_13VD-TN5Q8RGPQDnKRN__1",
	"header-text": "_2onf_TW2qp8s5p_IagJQya",
	"details": "_1ftR8CtgEv0PLIq1v161Gi",
	"details-column": "itu3JNbhLVdZto5lZ0xux"
};
module.exports = exports;
