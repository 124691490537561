// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3McAnwLE3KXOU1eQAJZmMy {\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n\n._1dVb7HbRNz-u8zZLDYIs_U {\n    display: flex;\n    margin-bottom: 28px;\n}\n\n._36RiarE8XyjibsqSAboTJw {\n    border: 1px solid var(--neutral-300);\n    margin-top: 32px;\n    padding: 20px 40px;\n}\n\n._3ZOrZA_O2TyEpmceQBdQsD {\n    display: flex;\n    flex-direction: column;\n    width: 25%;\n}\n\n._3qwUOI78iD1tkQICq2A7P3 {\n    color: var(--neutral-600);\n    font-weight: bold;\n    font-size: 14px;\n    margin-bottom: 10px;\n}\n", ""]);
// Exports
exports.locals = {
	"value": "_3McAnwLE3KXOU1eQAJZmMy",
	"row": "_1dVb7HbRNz-u8zZLDYIs_U",
	"details": "_36RiarE8XyjibsqSAboTJw",
	"detail": "_3ZOrZA_O2TyEpmceQBdQsD",
	"label": "_3qwUOI78iD1tkQICq2A7P3"
};
module.exports = exports;
