import { atom } from 'jotai/vanilla'

export enum Tab {
  incidentReport = 'incident_report',
  adjudication = 'adjudication',
  fraudReport = 'fraud_report',
  attachments = 'attachments',
}

export const DEFAULT_CLAIM_TAB = Tab.incidentReport

export const activeTabAtom = atom<{
  activeTab: Tab
  clickedAt?: number
}>({
  activeTab: DEFAULT_CLAIM_TAB,
})
