import { date } from '@extend/client-helpers'

export const formatDate = (dateValue: number | undefined): string => {
  if (!dateValue || typeof dateValue !== 'number') {
    return ''
  }

  return date.format(dateValue, date.standardDateFormat)
}

export const formatDateWithTimezone = (value?: number): string => {
  return (typeof value === 'number' && date.format(value, 'MMM D YYYY, h:mm A z')) || ''
}
