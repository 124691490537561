// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2og3xmI3EnGQQI23mPhcSZ {\n    display: flex;\n    margin-bottom: 28px;\n}\n\n._3MZelAJsGRpAg7O2nFFY09 {\n    border: 1px solid var(--neutral-300);\n    margin-top: 32px;\n    padding: 20px 40px;\n}\n\n.qH_11oVATB69uvT1CDSfh {\n    display: flex;\n    flex-direction: column;\n    width: 25%;\n}\n\n._3qupcEtBBnVhEjoPbeEq_n {\n    color: var(--neutral-600);\n    font-weight: bold;\n    font-size: 14px;\n    margin-bottom: 10px;\n}\n", ""]);
// Exports
exports.locals = {
	"row": "_2og3xmI3EnGQQI23mPhcSZ",
	"details": "_3MZelAJsGRpAg7O2nFFY09",
	"detail": "qH_11oVATB69uvT1CDSfh",
	"label": "_3qupcEtBBnVhEjoPbeEq_n"
};
module.exports = exports;
