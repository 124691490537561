// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3K3KjPZV0bWOY5GtQ6wc_- {\n    display: flex;\n    flex-direction: column;\n    gap: 32px;\n    min-width: 864px;\n    white-space: nowrap;\n    overflow: hidden;\n}\n\n._1HctvOyyrEVIDI73Vf4Ghh {\n    display: grid;\n    row-gap: 16px;\n    grid-template-columns: 33% 33% 33%;\n}\n\n._1lHZ-b2jsQUAKh1qJBScbV {\n    display: grid;\n    row-gap: 16px;\n    grid-template-columns: 33% 66%;\n}\n\n._2NU7rmbdkNR9gnroEHtCzV {\n    display: grid;\n    row-gap: 16px;\n    grid-template-columns: 33% 33% 33%;\n}\n\n._2NU7rmbdkNR9gnroEHtCzV span:last-child {\n    overflow: unset;\n}", ""]);
// Exports
exports.locals = {
	"container": "_3K3KjPZV0bWOY5GtQ6wc_-",
	"grid-33-33-33": "_1HctvOyyrEVIDI73Vf4Ghh",
	"grid-33-66": "_1lHZ-b2jsQUAKh1qJBScbV",
	"contract-information-section": "_2NU7rmbdkNR9gnroEHtCzV"
};
module.exports = exports;
