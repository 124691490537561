// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3FCG15SJrjho7P_Hn68biB {\n    margin-bottom: 32px;\n}\n\n._17EiJddW9-4CFPADDD5o-q {\n    font-size: 32px;\n    font-weight: 700;\n    margin: 0 0 10px 0;\n}\n\n._1Yj2UyLs-k7En6NFgygpkH {\n    font-size: 20px;\n}\n\n._1iMsNaXqXsrOt3d17keNJL {\n    color: var(--blue-800);\n    font-weight: 700;\n    text-decoration: underline;\n}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "_3FCG15SJrjho7P_Hn68biB",
	"heading": "_17EiJddW9-4CFPADDD5o-q",
	"subheading": "_1Yj2UyLs-k7En6NFgygpkH",
	"reset": "_1iMsNaXqXsrOt3d17keNJL"
};
module.exports = exports;
