import type { FC, SyntheticEvent } from 'react'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Switch, ToastColor, ToastDuration, useToaster } from '@extend/zen'
import type { ProductsSearchItem } from '@helloextend/extend-api-client'
import { useAtomValue } from 'jotai/react'
import { useSearchProductsQuery, useUpdateProductMutation } from '../../queries/products'
import { getProductVariantsUrl } from '../../utils/route-helper'
import { getActiveStoreIdAtom } from '../../atoms/stores'
import { Permission } from '../../lib/permissions'
import { usePermissions } from '../../hooks/use-permissions'
import styles from './product-offer-status-variants.module.css'
import { safeDecodeURIComponent } from '../../utils/encoding'

type ProductOfferStatusVariantsProps = {
  product: ProductsSearchItem
  enabled: boolean
}

export const ProductOfferStatusVariants: FC<ProductOfferStatusVariantsProps> = ({
  enabled,
  product,
}) => {
  const {
    referenceId: queryReferenceId,
    variantCount,
    enabled: productEnabled,
    warrantyStatus,
  } = product
  const referenceId = useMemo(() => safeDecodeURIComponent(queryReferenceId), [queryReferenceId])
  const isDisabled = variantCount > 0 || (!productEnabled && warrantyStatus === 'non-warrantable')
  const isSolo = variantCount === 0
  const { toast } = useToaster()
  const storeId = useAtomValue(getActiveStoreIdAtom)

  const { mutateAsync: updateProduct } = useUpdateProductMutation()
  const { data, isSuccess: isQuerySearchSuccess } = useSearchProductsQuery({
    parentReferenceId: product.referenceId,
    storeId,
    limit: 250,
    enabled: !isSolo,
  })
  const { hasPermission } = usePermissions()
  const hasFullProductsPermission = hasPermission(Permission.ProductsFullAccess)

  const warrantableVariants = useMemo(() => {
    if (data && isQuerySearchSuccess) {
      return data.products?.filter((variant) => variant.enabled).length
    }
    return 0
  }, [data, isQuerySearchSuccess])

  const editProduct = async (newValue: boolean): Promise<void> => {
    try {
      await updateProduct({
        storeId,
        productId: referenceId,
        version: 'latest',
        data: { enabled: newValue },
      })
      toast({
        message: newValue ? 'Offer display turned on' : 'Offer display turned off',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.blue,
      })
    } catch (err) {
      toast({
        message: 'Offer display could not be toggled. Please try again later',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }

  const handleClick = (e: SyntheticEvent): void => {
    e.preventDefault()
    e.stopPropagation()
  }

  /**
   * Handle case where referenceId is not able to be properly decoded
   * The safe decode function will log this invalid input as well.
   */
  if (!referenceId) {
    toast({
      message: `Unable to properly display product: ${product.name}`,
      toastColor: ToastColor.red,
      toastDuration: ToastDuration.short,
    })

    return (
      <div>
        <p>Invalid URI for Product: {product.name}</p>
      </div>
    )
  }

  if (isSolo) {
    return (
      <div
        className={`${styles['slider-cell']} ${!hasFullProductsPermission && styles['read-only']}`}
      >
        <Switch
          data-cy={`${referenceId}-enabled-status`}
          id={`${referenceId}-enabled-status`}
          isDisabled={isDisabled}
          disabledTooltip="Offers can't be displayed because this product is not warrantable"
          isOn={enabled}
          onChange={() => editProduct(!enabled)}
        />
      </div>
    )
  }

  return (
    <Link
      to={getProductVariantsUrl(queryReferenceId)}
      data-cy="product-variant-count-links"
      onClick={handleClick}
    >
      {`${warrantableVariants}/${variantCount} variant${variantCount > 1 ? 's' : ''}`}
    </Link>
  )
}
