// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3SE11qPqsEAASc-f9uUNYt {\n  margin-bottom: 80px;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n._3SE11qPqsEAASc-f9uUNYt p {\n  margin: 0;\n  font-weight: bold;\n  font-size: 16px;\n  line-height: 24px;\n  color: var(--neutral-600);\n}\n\n._3SE11qPqsEAASc-f9uUNYt p span {\n  font-weight: normal;\n  color: var(--neutral-900)\n}\n\n._3SE11qPqsEAASc-f9uUNYt a {\n  color: var(--blue-800);\n  font-size: 16px;\n  line-height: 24px;\n  font-weight: normal;\n  text-decoration: underline;\n}\n\n._3SE11qPqsEAASc-f9uUNYt a:hover {\n  text-decoration: underline,\n}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "_3SE11qPqsEAASc-f9uUNYt"
};
module.exports = exports;
