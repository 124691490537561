// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._345oIivdxg7a1dPHKXPYc5 {\n    display: flex;\n    gap: 16px;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "_345oIivdxg7a1dPHKXPYc5"
};
module.exports = exports;
