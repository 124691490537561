// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._5AHUsYTKWsbsFpBgT1NgN {\n    padding: 20px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n._3uik844FfHeoJVZdHgf8bM {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    width:100%;\n}\n\n._2_YXcOrHIT_tV6M1Do14CI {\n    margin-bottom: 32px;\n}\n\n.jpLLFyb4BhJHdkbmCtc_K {\n    display: flex;\n    gap: 16px;\n    margin-bottom: 32px;\n}\n\n._26cJgMztN1oWRVkcxWBkiL {\n    color: var(--neutral-1000);\n    font-family: \"Nunito Sans\", sans-serif;\n    font-size: 26px;\n    font-weight: 700;\n    line-height: 45px;\n    text-align: center;\n    margin-bottom: 22px;\n}\n\n._1w4gXehvL3A7jO5BWvkSKX {\n    max-width: 430px;\n    margin-bottom: 32px;\n}\n\n.MndilhXqxNSSAWq6lAUX3 {\n    font-size: 20px;\n    line-height: 27px;\n    margin-bottom: 32px;\n    text-align: center;\n}\n\n._1jr_xaRX4YvJIVS4lEyeD2 {\n    margin-bottom: 22px;\n}\n", ""]);
// Exports
exports.locals = {
	"page-container": "_5AHUsYTKWsbsFpBgT1NgN",
	"container": "_3uik844FfHeoJVZdHgf8bM",
	"figure": "_2_YXcOrHIT_tV6M1Do14CI",
	"button-group": "jpLLFyb4BhJHdkbmCtc_K",
	"heading": "_26cJgMztN1oWRVkcxWBkiL",
	"body-container": "_1w4gXehvL3A7jO5BWvkSKX",
	"body": "MndilhXqxNSSAWq6lAUX3",
	"alert": "_1jr_xaRX4YvJIVS4lEyeD2"
};
module.exports = exports;
