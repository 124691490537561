import type { FC, MouseEvent } from 'react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import type {
  CheckboxFilterValues,
  DateRangeFilterValues,
  FilterValues,
} from '@helloextend/merchants-ui'
import {
  DataReactTable,
  dateRangeFilterValueToDates,
  PaginationType,
  ZeroState,
} from '@helloextend/merchants-ui'
import { useAtomValue, useSetAtom } from 'jotai/react'
import { useHistory, useLocation } from 'react-router-dom'
import { useQueryStringState } from '@helloextend/client-hooks/src/use-query-string-state'
import { useUserAnalytics } from '@helloextend/client-hooks'
import type { ClaimRecord, ClaimsSearchQueryStringOptions } from '@helloextend/extend-api-rtk-query'
import { useListInsuranceClaimsQuery } from '@helloextend/extend-api-rtk-query'
import { useShellContext, useToaster, ToastDuration, ToastColor } from '@extend/zen'
import { columns, filterOptions, searchOptions } from './table-config'
import { getClaimDetailUrl } from '../../../utils/route-helper'
import { useDataDelayedToast } from '../../../hooks/use-data-delay-toast'
import { claimBreadcrumbsAtom } from '../../../atoms/claim-breadcrumb'
import { useCustomerPhoneSearch } from '../../../hooks/use-customer-phone-search'
import { LDFlag } from '../../../constants/ld-flags'
import { getActiveStoreIdAtom } from '../../../atoms/stores'
import { mapUnhandledClaimsServiceItemsForClaimsTable } from '../../../utils/map-unhandled-claims-service-items-for-claims-table'

const EMPTY_FILTER_MESSAGE =
  'Double check that the search term is correct, or try applying different filters to your search.'

type Filters = Pick<
  ClaimsSearchQueryStringOptions,
  | 'matchesClaimStatus'
  | 'createdAtBegin'
  | 'createdAtEnd'
  | 'reportedAtBegin'
  | 'reportedAtEnd'
  | 'updatedAtBegin'
  | 'updatedAtEnd'
  | 'typeFilter'
  | 'matchesClaimCategory'
>

const ClaimsDataTable: FC = () => {
  const flaggedSearchOptions = useCustomerPhoneSearch(searchOptions, LDFlag.ClaimsPhoneSearch)
  const { search: queryParams } = useLocation()
  const setClaimsUrl = useSetAtom(claimBreadcrumbsAtom)
  const { trackEvent } = useUserAnalytics()
  const { toast } = useToaster()

  useEffect(() => {
    // Sync the breadcrumb whenever the query params change
    setClaimsUrl(`/store/claims${queryParams}`)
  }, [setClaimsUrl, queryParams])

  const { getScrollableRegionRef } = useShellContext()
  const { push } = useHistory()
  const [searchFilters, setSearchFilters] = useQueryStringState<
    Record<string, FilterValues | null>
  >('filters', {}, { encode: true, transformDates: true })
  const storeId = useAtomValue(getActiveStoreIdAtom)

  const [searchKey, setSearchKey] = useQueryStringState('search', '')
  const [searchValue, setSearchValue] = useQueryStringState('searchValue', '')
  const [sortAsc, setSortAsc] = useQueryStringState('sortAsc', false)
  const [sortKey, setSortKey] = useQueryStringState('sortKey', 'reportedAt')

  const [nextPageCursor, setNextPageCursor] = useState('')
  const [pageSize, setPageSize] = useQueryStringState('pageSize', 50)
  const [autoResetPage, setAutoResetPage] = useState(true)

  const filtersForApi = useMemo(() => {
    const newFilters: Filters = {}

    if (searchFilters.status && (searchFilters.status as CheckboxFilterValues).values.length) {
      newFilters.matchesClaimStatus = (searchFilters.status as CheckboxFilterValues).values
    }

    if (searchFilters.reportedAtDate) {
      const { start, end } = dateRangeFilterValueToDates(
        searchFilters.reportedAtDate as DateRangeFilterValues,
      )
      if (start) newFilters.reportedAtBegin = start
      if (end) newFilters.reportedAtEnd = end
    }

    if (searchFilters.updatedAtDate) {
      const { start, end } = dateRangeFilterValueToDates(
        searchFilters.updatedAtDate as DateRangeFilterValues,
      )
      if (start) newFilters.updatedAtBegin = start
      if (end) newFilters.updatedAtEnd = end
    }

    if (searchKey && searchValue) {
      return { ...newFilters, [searchKey]: searchValue }
    }

    if (searchFilters.typeFilter) {
      return {
        ...newFilters,
        matchesClaimType: (searchFilters.typeFilter as CheckboxFilterValues).values
          .flat(1)
          .join(', '),
      }
    }

    if (searchFilters.categoryFilter) {
      return {
        ...newFilters,
        matchesClaimCategory: (searchFilters.categoryFilter as CheckboxFilterValues).values.flat(1),
      }
    }

    return newFilters
  }, [
    searchFilters.reportedAtDate,
    searchFilters.status,
    searchFilters.updatedAtDate,
    searchFilters.typeFilter,
    searchFilters.categoryFilter,
    searchKey,
    searchValue,
  ])

  const params = {
    sellerId: storeId,
    searchVersion: '2',
    minLimit: 50,
    cursor: nextPageCursor || undefined,
    sortKey,
    sortAsc,
    ...filtersForApi,
  }

  const { data, isLoading, isFetching, isError } = useListInsuranceClaimsQuery(params)

  const isFulfilled = !isLoading

  useEffect(() => {
    if (!isFetching && searchValue && data?.items) {
      trackEvent('claim_search_result_count', { count: data.items.length })
    }

    if (isError) {
      toast({
        message: 'Encountered an error while trying to retrieve claims',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }
  }, [isFetching, data, searchValue, trackEvent, isError, toast])

  useDataDelayedToast(isFetching)

  const handleServerPagination = useCallback((cursor: string) => {
    setNextPageCursor(cursor || '')
    setAutoResetPage(false)
  }, [])

  const setPagination = useCallback(
    (newPageSize: number) => {
      setPageSize(newPageSize)
    },
    [setPageSize],
  )

  const handleRowClick = useCallback(
    (_e: MouseEvent, rowData: ClaimRecord) => {
      push(getClaimDetailUrl(rowData.id))
    },
    [push],
  )

  const handleSearch = useCallback(
    (key?: string, value?: string): void => {
      if (key && value) {
        setSearchKey(key)
        setSearchValue(value)
      } else {
        setSearchKey('')
        setSearchValue('')
      }
    },
    [setSearchKey, setSearchValue],
  )

  const initialState = useMemo(() => {
    return {
      sortBy: [{ id: sortKey || '', desc: !sortAsc }],
      pageSize,
      filters: Object.keys(searchFilters).map((key) => ({ id: key, value: searchFilters[key] })),
    }
  }, [pageSize, searchFilters, sortAsc, sortKey])

  const resetPagination = useCallback(() => {
    setNextPageCursor('')
    setAutoResetPage(true)
  }, [])

  const handleServerFilter = useCallback(
    (filtersRecord: Record<string, FilterValues | null>) => {
      setSearchFilters(filtersRecord)
      resetPagination()
    },
    [resetPagination, setSearchFilters],
  )

  const handleServerSort = useCallback(
    (newSortKey: string, newSortAsc: boolean) => {
      if (newSortKey) {
        setSortKey(newSortKey)

        setSortAsc(newSortAsc)
      } else {
        setSortKey('reportedAt')
        setSortAsc(false)
      }

      resetPagination()
    },
    [resetPagination, setSortAsc, setSortKey],
  )

  const hasNoFiltersApplied = !Object.keys(searchFilters).length
  const hasNoSearchApplied = !searchKey || !searchValue
  const hasEmptyDataSet = !data?.items?.length
  const shouldDisplayZeroState =
    hasNoFiltersApplied && hasNoSearchApplied && hasEmptyDataSet && !isLoading && !isFetching

  /**
   * https://helloextend.atlassian.net/browse/MEXP-2277
   * @TODO remove mapUnhandledClaimsServiceItemsForClaimsTable when the claims service is updated with unified incident values
   * */

  const formattedClaimsServiceItems = useMemo(
    () => mapUnhandledClaimsServiceItemsForClaimsTable(data?.items || []),
    [data?.items],
  )

  return (
    <DataReactTable
      data-cy="claims"
      isLoading={isLoading || isFetching}
      hasSearchBar
      searchOptions={flaggedSearchOptions}
      onServerSearch={handleSearch}
      initialState={initialState}
      initialSearchKey={searchKey}
      initialSearchValue={searchValue}
      data={formattedClaimsServiceItems}
      columns={columns}
      emptyMessage={EMPTY_FILTER_MESSAGE}
      type="claims"
      getScrollToRef={getScrollableRegionRef}
      onRowClick={handleRowClick}
      autoResetPage={autoResetPage}
      nextPageCursor={data?.nextPageCursor}
      paginationType={PaginationType.ENHANCED_SERVER_SIDE}
      onServerPagination={handleServerPagination}
      setPagination={setPagination}
      filterOptions={!isError ? filterOptions : undefined}
      onServerFilter={handleServerFilter}
      onServerSort={handleServerSort}
      disableSortRemove
      isInitialReqFullfilled={isFulfilled}
      shouldDisplayZeroState={shouldDisplayZeroState}
      zeroState={
        isError ? (
          <ZeroState
            altIconName="cloudsError"
            title="Sorry, something went wrong."
            text={`The data couldn't be retrieved right now.\nPlease try again later.`}
            data-cy="claims-zero-state:error-container"
          />
        ) : (
          <ZeroState
            title="No claims yet"
            text="When a customer files a claim, you can view them here."
            data-cy="claims-zero-state:message-container"
          />
        )
      }
    />
  )
}

export { ClaimsDataTable }
