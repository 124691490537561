// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1SzeX4qYWK7cLGVC8lMEtK {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    gap: 8px;\n}\n\n._3ioKcVQpRb8y5hQoQvOmMS {\n    padding-left: 4px;\n}\n\n._2g8YQs6iPH6SNuupJ5x9vZ {\n    display: flex;\n    gap: 16px;\n}\n", ""]);
// Exports
exports.locals = {
	"icon": "_1SzeX4qYWK7cLGVC8lMEtK",
	"center": "_3ioKcVQpRb8y5hQoQvOmMS",
	"wrapper": "_2g8YQs6iPH6SNuupJ5x9vZ"
};
module.exports = exports;
