// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".i5Bmi-mdWmhIh6LWK7ym8 {\n    font-weight: 700;\n}\n\n.I-02ntVEsyEyvZv_mxojV {\n    display: flex;\n    gap: 10px;\n    align-items: end;\n}\n\n.I-02ntVEsyEyvZv_mxojV > div:first-child {\n    width: 100%;\n}\n\n.I-02ntVEsyEyvZv_mxojV > button {\n    align-self: unset;\n}\n\np {\n    font-weight: 100;\n    line-height: 20px;\n}\n\n._1yxpeDiyrVLFGeNPU_AW2N {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n}", ""]);
// Exports
exports.locals = {
	"strong": "i5Bmi-mdWmhIh6LWK7ym8",
	"input-wrapper": "I-02ntVEsyEyvZv_mxojV",
	"wrapper": "_1yxpeDiyrVLFGeNPU_AW2N"
};
module.exports = exports;
