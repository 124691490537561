import styled from '@emotion/styled'
import type { ReplacementFulfillmentMethod } from '@helloextend/extend-api-rtk-query/src/service-orders/types'
import { ClaimType } from '@helloextend/extend-api-client'
import type { ServiceOrder } from '@helloextend/extend-api-client'
import { useSelectFulfillmentMethodMutation } from '@helloextend/extend-api-rtk-query'
import { useFlags } from 'launchdarkly-react-client-sdk'
import type { RowOptionProps } from '@extend/zen'
import {
  Button,
  Modal,
  ModalController,
  RowOption,
  RowOptionGroup,
  Stack,
  ToastColor,
  ToastDuration,
  useToaster,
} from '@extend/zen'
import type { FC } from 'react'
import React, { useState } from 'react'
import type { MerchantServicingSettings } from '@helloextend/extend-api-rtk-query/src/servicers/types'
import { LDFlag } from '../constants/ld-flags'

interface SelectFulfillmentOptionModalProps {
  isVisible: boolean
  serviceOrder: ServiceOrder
  sellerName?: string
  merchantServicingSettings: MerchantServicingSettings
  onClose: (isSuccess?: boolean) => void
}

const SelectFulfillmentOptionModal: FC<SelectFulfillmentOptionModalProps> = ({
  isVisible,
  serviceOrder,
  sellerName,
  merchantServicingSettings,
  onClose,
}) => {
  const getDefaultPayoutMethod = (): ReplacementFulfillmentMethod => {
    return merchantServicingSettings?.supportedCustomerFulfillmentMethods?.includes('virtual_card')
      ? 'virtual_card'
      : 'direct_payment'
  }
  const { [LDFlag.AutomatedReplacements]: FF_AUTOMATED_REPLACEMENTS } = useFlags()

  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectedOption(e.target.value as ReplacementFulfillmentMethod)
  }

  const [selectedOption, setSelectedOption] = useState<ReplacementFulfillmentMethod>(
    getDefaultPayoutMethod(),
  )
  const [setPayoutMethod] = useSelectFulfillmentMethodMutation()
  const { toast } = useToaster()

  const generateRowOptionProps = (): RowOptionProps[] => {
    const props: RowOptionProps[] = []
    if (!merchantServicingSettings) return props
    const isFulfillmentOptionsUndefined =
      !merchantServicingSettings.supportedCustomerFulfillmentMethods
    const isVirtualCardSupported =
      merchantServicingSettings.supportedCustomerFulfillmentMethods?.includes('virtual_card')
    const isAutomatedReplacementSupported =
      merchantServicingSettings.supportedCustomerFulfillmentMethods?.includes(
        'automated_replacement',
      ) &&
      FF_AUTOMATED_REPLACEMENTS &&
      serviceOrder.claimType === ClaimType.SHIPPING_PROTECTION
    const isDirectPaymentSupported =
      merchantServicingSettings.supportedCustomerFulfillmentMethods?.includes('direct_payment')
    const isManualOptionsSupported =
      merchantServicingSettings.supportedCustomerFulfillmentMethods?.includes('manual')

    if (isFulfillmentOptionsUndefined || isVirtualCardSupported) {
      props.push({
        'data-cy': 'virtual-card-option',
        value: 'virtual_card',
        badgeText: 'Best value',
        label: `Virtual Visa Card for ${sellerName ?? 'store'}`,
        description: 'Issued instantly for your purchase with the same merchant',
      })
    }
    if (isFulfillmentOptionsUndefined || isDirectPaymentSupported) {
      props.push({
        'data-cy': 'direct-payment-option',
        value: 'direct_payment',
        label: 'Bank account transfer',
        description: 'A direct transfer of funds. It takes 3-5 business days to process',
      })
    }
    if (isAutomatedReplacementSupported) {
      props.push({
        'data-cy': 'automated-replacement-option',
        value: 'automated_replacement',
        label: 'Automatic product replacement',
        description: 'Automatically replaced with the same product, pending availability',
      })
    }

    if (isManualOptionsSupported) {
      props.push({
        'data-cy': 'manual-option',
        value: 'manual',
        label: 'Original product replacement',
        description: 'Replacement product shipped by the merchant, pending availability',
      })
    }
    return props
  }

  const handleConfirmPayoutClick = async (): Promise<void> => {
    if (!serviceOrder) return
    try {
      const response = await setPayoutMethod({
        serviceOrderId: serviceOrder?.id,
        fulfillmentSelection: selectedOption,
      })
      if ((response as { error: { status: number; data: unknown } }).error) {
        toast({
          message: 'An error occurred',
          toastColor: ToastColor.red,
          toastDuration: ToastDuration.short,
        })
        onClose()
        return
      }
      toast({
        message: 'Fulfillment option successfully selected!',
        toastColor: ToastColor.blue,
        toastDuration: ToastDuration.short,
      })
      onClose(true)
    } catch (e) {
      toast({
        message: 'An error occurred',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
      onClose()
    }
  }

  return (
    <ModalController isOpen={isVisible}>
      <Modal heading="Select fulfillment option" onDismissRequest={onClose}>
        <Container data-cy="customer-payout-timeline">
          <Stack spacing={2} justify="start">
            <RowOptionGroup
              value={selectedOption}
              name="payout_option"
              onChange={handleOptionChange}
              data-cy="payout-option-group"
            >
              {generateRowOptionProps().map((props) => (
                <RowOption key={props.value} {...props} />
              ))}
            </RowOptionGroup>
          </Stack>
          <Footer>
            <Button
              data-cy="confirm-payout-options-button"
              text="Confirm payout option"
              emphasis="high"
              onClick={handleConfirmPayoutClick}
            />
          </Footer>
        </Container>
      </Modal>
    </ModalController>
  )
}

const Footer = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
})

const Container = styled.div({
  width: '100%',
  display: 'flex',
  height: 'auto',
  padding: '1rem 0',
  flexDirection: 'column',
  button: {
    display: 'block !important',
  },
  gap: 15,
})

export { SelectFulfillmentOptionModal }
