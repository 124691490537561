// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SIB3pZ8rKWPCGCrzy6OLt {\n    font-size: 18px;\n    line-height: 24px;\n    font-weight: 700;\n}\n\n._1hY2CcAjSOjaZSLWOT3dVs {\n    display: flex;\n    flex: 1;\n    justify-content: center;\n}\n\n.baJOoLOmqVeOvN7qHNrBR {\n    margin-right: auto;\n}\n\n._1iQydnzrHDUUcFRTg33nDa {\n    display: flex;\n    margin-left: auto;\n    gap: 8px;\n}\n\n.U8vx5IuClfAmEAkVGjC3T {\n    z-index: 1000000;\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    overflow: auto;\n    display: flex;\n    flex-direction: row-reverse;\n    gap: 16px;\n    height: 59px;\n    padding: 0 16px;\n    background-color: var(--blue-800);\n    align-items: center;\n    color: var(--white);\n    white-space: nowrap;\n    transition-property: top;\n    transition-duration: 200ms;\n}\n\n.U8vx5IuClfAmEAkVGjC3T._3hC6JYj0YO1kYY6Em3Oal4 {\n    top: -59px;\n}", ""]);
// Exports
exports.locals = {
	"header": "SIB3pZ8rKWPCGCrzy6OLt",
	"section": "_1hY2CcAjSOjaZSLWOT3dVs",
	"start": "baJOoLOmqVeOvN7qHNrBR",
	"end": "_1iQydnzrHDUUcFRTg33nDa",
	"save-banner": "U8vx5IuClfAmEAkVGjC3T",
	"invisible": "_3hC6JYj0YO1kYY6Em3Oal4"
};
module.exports = exports;
