// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._18bJMYqBRCZJzorQNSET6W {\n    display: flex;\n    align-items: center;\n    margin-bottom: 40px;\n    border-radius: 6px;\n    height: 60px;\n    width: 100%;\n    border-color: var(--yellow-300);\n    border: 1px solid var(--yellow-700);\n    background-color: var(--yellow-100);\n}\n\n._2kLdla13Miwdb18CMMIpc3 {\n    margin-left: 10px;\n    color: var(--yellow-800);\n}\n\n._13iyIVVW3p7KV6PD-tcQfF {\n    margin: 5px 0 0 10px;\n}\n\n._1RB2RII1eNLhuetughlliG {\n    border: none;\n    background: none;\n    text-decoration: underline;\n    color: var(--yellow-800);\n}\n\n._1RB2RII1eNLhuetughlliG:visited {\n    color: var(--yellow-800);\n}", ""]);
// Exports
exports.locals = {
	"container": "_18bJMYqBRCZJzorQNSET6W",
	"text": "_2kLdla13Miwdb18CMMIpc3",
	"icon-wrapper": "_13iyIVVW3p7KV6PD-tcQfF",
	"logout-button": "_1RB2RII1eNLhuetughlliG"
};
module.exports = exports;
