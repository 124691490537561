import type { FC } from 'react'
import React, { useState, useMemo, useCallback } from 'react'
import type { Contract } from '@helloextend/extend-api-client'
import { ClaimType, ClaimSelectStatus } from '@helloextend/extend-api-client'
import {
  InlineAlert,
  InlineAlertColor,
  Sparkle,
  ToastColor,
  ToastDuration,
  useToaster,
} from '@extend/zen'
import { NavLink } from 'react-router-dom'
import { useAtom, useSetAtom } from 'jotai/react'
import {
  useGetMerchantServicingSettingsQuery,
  useSearchServiceOrdersQuery,
} from '@helloextend/extend-api-rtk-query'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { HeaderLabel } from '../../../components/header-label'
import { claimStatusDetails, getClaimDenialReason } from '../contract/contract-claim-status-details'
import type { BadgeDescriptionItem } from '../../../components/badge-descriptions'
import { StandardHeader } from '../../../components/standard-header'
import { ApproveClaimModal } from '../../../components/approve-claim-modal'
import { CloseClaimModal } from '../../../components/close-claim-modal'
import { DenyClaimModal } from '../../../components/deny-claim-modal'
import { getProductUrl } from '../../../utils/route-helper'
import { claimBreadcrumbsAtom } from '../../../atoms/claim-breadcrumb'
import { usePermissions } from '../../../hooks/use-permissions'
import { useUpdateClaimMutation } from '../../../queries/claims'
import { Permission } from '../../../lib/permissions'
import styles from './claim-header.module.css'
import type { MerchantClaim } from '../../../types/claims'
import { SelectFulfillmentOptionModal } from '../../../components/select-fulfillment-option-modal'
import { LDFlag } from '../../../constants/ld-flags'
import { dismissedAlertsAtom } from '../../../atoms/alerts'
import {
  activeTabAtom as claimTabSectionActiveTabAtom,
  Tab,
} from '../../../atoms/claim-tab-section'

interface ClaimHeaderProps {
  contract?: Contract
  claim: MerchantClaim
}

export const ClaimHeader: FC<ClaimHeaderProps> = ({ claim, contract }) => {
  const { hasPermission } = usePermissions()
  const hasProductsView = hasPermission(Permission.ProductsView)
  const {
    [LDFlag.SelectFulfillmentOption]: FF_SELECT_FULFILLMENT_OPTION,
    [LDFlag.MerchantReviewClaims]: FF_MERCHANT_REVIEW_CLAIMS,
  } = useFlags()

  const { toast } = useToaster()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isApproveClaimModalVisible, setIsApproveClaimModalVisible] = useState(false)
  const [isDenyClaimModalVisible, setIsDenyClaimModalVisible] = useState(false)
  const [isCloseClaimModalVisible, setIsCloseClaimModalVisible] = useState(false)
  const [isSelectionSuccess, setIsSelectionSuccess] = useState(false)
  const [dismissedAlerts, setDismissedAlerts] = useAtom(dismissedAlertsAtom)
  const recommendationAlertId = useMemo(() => `CLAIM::${claim.id}::RECOMMENDATION`, [claim.id])
  const setActiveTab = useSetAtom(claimTabSectionActiveTabAtom)

  const productLink = useMemo(() => {
    return contract?.product?.referenceId ? getProductUrl(contract.product.referenceId) : ''
  }, [contract])

  const contractLink = useMemo(() => {
    return contract ? `/store/contracts/${contract?.id}` : ''
  }, [contract])

  const productName = useMemo(() => {
    return contract?.product?.title || ''
  }, [contract])

  const { serviceOrders } = useSearchServiceOrdersQuery(
    { claimId: claim.id },
    {
      selectFromResult: ({ data }) => ({
        serviceOrders: data?.items ? [...data.items] : [],
      }),
    },
  )

  const { mutateAsync: updateClaim, isLoading: isUpdatingClaim } = useUpdateClaimMutation()

  // sort by creation date descending
  serviceOrders.sort((a, b) => b.createdAt - a.createdAt)

  const serviceOrder = serviceOrders.length ? serviceOrders[0] : undefined

  const statusBadgeDescription = useMemo((): BadgeDescriptionItem | null => {
    const isClaimDenied = claim.status === 'denied'
    const originalClaimStatusDetails = claimStatusDetails[claim.status] || null

    if (claim.status === 'pending_adjudication' && claim.photoRequirements?.length) {
      originalClaimStatusDetails.details = 'Photos Required'
    }

    if (!isClaimDenied) return originalClaimStatusDetails

    const denialReason = getClaimDenialReason(claim)

    if (denialReason) {
      const generatedDenialReason = `${originalClaimStatusDetails?.text}: ${getClaimDenialReason(
        claim,
      )}`

      return {
        ...originalClaimStatusDetails,
        text: generatedDenialReason,
      }
    }

    return originalClaimStatusDetails
  }, [claim])

  const { data: merchantServicingSettings } = useGetMerchantServicingSettingsQuery({
    sellerId: claim.sellerId,
  })

  const [claimsUrl] = useAtom(claimBreadcrumbsAtom)
  const pageTitle = `${claim.customer.name}'s Claim`

  const crumbs = useMemo(() => {
    return [
      { to: claimsUrl || '/store/claims', text: 'Claims' },
      {
        text: pageTitle,
      },
    ]
  }, [pageTitle, claimsUrl])

  const isShippingProtectionClaim = Boolean(claim.type === ClaimType.SHIPPING_PROTECTION)
  let claimType = 'Product Protection'
  if (isShippingProtectionClaim) {
    claimType = 'Shipping Protection'
  } else if (claim.type === ClaimType.SHIPPING_RESOLUTION) {
    claimType = 'Shipping Resolution'
  }

  const shouldShowCustomerFulfillment =
    FF_SELECT_FULFILLMENT_OPTION &&
    !merchantServicingSettings?.isCustomerFulfillmentSelectionDisabled &&
    serviceOrder?.status === 'created'

  const toggleIsModalVisible = (isSuccess?: boolean): void => {
    setIsModalVisible(!isModalVisible)
    if (isSuccess) setIsSelectionSuccess(true)
  }

  const approveClaimClicked = async (): Promise<void> => {
    if (claim.incident.failureType && claim.incident.failureType !== 'unanswered') {
      try {
        await updateClaim({
          claimId: claim.id,
          body: {
            status: ClaimSelectStatus.approved,
            customer: claim.customer,
            incident: claim.incident,
          },
        })

        toast({
          message: 'Claim approved!',
          toastDuration: ToastDuration.short,
          toastColor: ToastColor.green,
        })
      } catch (error) {
        toast({
          message: 'Failed to approve claim!',
          toastDuration: ToastDuration.short,
          toastColor: ToastColor.red,
        })
      }
    } else {
      setIsApproveClaimModalVisible(!isApproveClaimModalVisible)
    }
  }

  const isMerchantReviewRequired = useMemo(() => {
    return claim.status === 'review' && claim?.entitlementCategory === 'external'
  }, [claim])

  // Recommend approval if claim is in review, ruleset is approved and validation error is not fraud risk
  const isMerchantApprovalRecommended = useMemo(() => {
    return (
      isMerchantReviewRequired &&
      claim.ruleset?.status === 'approved' &&
      claim.validationError !== 'fraud_risk' &&
      !dismissedAlerts[recommendationAlertId] // Alert has not been dismissed
    )
  }, [claim, dismissedAlerts])

  // Recommend denial if claim is in review, ruleset is denied or validation error is fraud risk
  const isMerchantDenialRecommended = useMemo(() => {
    return (
      isMerchantReviewRequired &&
      (claim.ruleset?.status === 'denied' || claim.validationError === 'fraud_risk') && //
      !dismissedAlerts[recommendationAlertId] // Alert has not been dismissed
    )
  }, [claim, dismissedAlerts])

  const onDismissMerchantReviewRecommendation = useCallback(() => {
    setDismissedAlerts({ ...dismissedAlerts, [recommendationAlertId]: true })
  }, [setDismissedAlerts, dismissedAlerts, recommendationAlertId])

  return (
    <>
      {serviceOrder && merchantServicingSettings && shouldShowCustomerFulfillment && (
        <SelectFulfillmentOptionModal
          isVisible={isModalVisible}
          onClose={toggleIsModalVisible}
          sellerName={contract?.sellerName}
          serviceOrder={serviceOrder}
          merchantServicingSettings={merchantServicingSettings}
        />
      )}
      {isApproveClaimModalVisible && FF_MERCHANT_REVIEW_CLAIMS && (
        <ApproveClaimModal
          isOpen={isApproveClaimModalVisible}
          handleConfirm={() => setIsApproveClaimModalVisible(!isApproveClaimModalVisible)}
          handleLeave={() => setIsApproveClaimModalVisible(!isApproveClaimModalVisible)}
          claim={claim}
        />
      )}
      {isDenyClaimModalVisible && FF_MERCHANT_REVIEW_CLAIMS && (
        <DenyClaimModal
          isOpen={isDenyClaimModalVisible}
          handleConfirm={() => setIsDenyClaimModalVisible(!isDenyClaimModalVisible)}
          handleLeave={() => setIsDenyClaimModalVisible(!isDenyClaimModalVisible)}
          claim={claim}
        />
      )}
      {isCloseClaimModalVisible && FF_MERCHANT_REVIEW_CLAIMS && (
        <CloseClaimModal
          isOpen={isCloseClaimModalVisible}
          handleConfirm={() => setIsCloseClaimModalVisible(!isCloseClaimModalVisible)}
          handleLeave={() => setIsCloseClaimModalVisible(!isCloseClaimModalVisible)}
          claim={claim}
        />
      )}
      <StandardHeader
        data-cy="claim-info"
        pageTitle={pageTitle}
        pageTitleBadge={{
          badgeText: statusBadgeDescription?.text || '',
          badgeColor: statusBadgeDescription?.color || 'neutral',
          badgeDescriptions: Object.values(claimStatusDetails),
          badgeDetails: statusBadgeDescription?.details,
        }}
        buttons={[
          ...(serviceOrder &&
          merchantServicingSettings &&
          shouldShowCustomerFulfillment &&
          !isSelectionSuccess
            ? [
                {
                  'data-cy': 'fulfillment-option-button',
                  text: 'Select Fulfillment Option',
                  onClick: () => toggleIsModalVisible(),
                },
              ]
            : []),
          ...(isMerchantReviewRequired && FF_MERCHANT_REVIEW_CLAIMS
            ? [
                {
                  'data-cy': 'approve-claim-button',
                  text: 'Approve Claim',
                  emphasis: 'medium' as const,
                  onClick: () => approveClaimClicked(),
                  isProcessing: isUpdatingClaim,
                },
                {
                  'data-cy': 'deny-claim-button',
                  text: 'Deny Claim',
                  emphasis: 'medium' as const,
                  onClick: () => setIsDenyClaimModalVisible(true),
                  isProcessing: isUpdatingClaim,
                },
                {
                  'data-cy': 'close-claim-button',
                  text: 'Close Claim',
                  emphasis: 'low' as const,
                  onClick: () => setIsCloseClaimModalVisible(true),
                  isProcessing: isUpdatingClaim,
                },
              ]
            : []),
        ]}
        crumbs={crumbs}
      >
        <div className={styles.line} data-cy="claim-line">
          <HeaderLabel labelText="Claim ID:" />
          <span>{claim.id}</span>
        </div>
        {!isShippingProtectionClaim && productName && (
          <div className={styles.line} data-cy="product-line">
            <HeaderLabel labelText="Product:" />
            {hasProductsView && productLink ? (
              <NavLink to={productLink}>{productName}</NavLink>
            ) : (
              productName
            )}
          </div>
        )}
        {contract?.id && contractLink && (
          <div className={styles.line} data-cy="contract-line">
            <HeaderLabel labelText="Contract:" />
            <NavLink to={contractLink}>{contract.id}</NavLink>
          </div>
        )}

        <div className={styles['type-line']} data-cy="type-line">
          <HeaderLabel labelText="Type:" />
          <span>{claimType}</span>
        </div>

        {/* TODO: MEXP-864: Uncomment out TabBar when claims history is available
      <TabBar
      tabBarLinks={[
        { to: getClaimDetailUrl(claim.id), text: 'Claim Details' },
        { to: getClaimDetailHistoryUrl(claim.id), text: 'Claim History' },
        ]}
        /> */}
      </StandardHeader>
      {isMerchantApprovalRecommended && FF_MERCHANT_REVIEW_CLAIMS && (
        <div className={styles['alert-wrapper']}>
          <InlineAlert
            color={InlineAlertColor.blue}
            icon={Sparkle}
            primaryButtonProps={{
              text: 'Approve Claim',
              onClick: () => approveClaimClicked(),
            }}
            secondaryButtonProps={{
              text: 'Review analysis',
              onClick: () =>
                setActiveTab({
                  activeTab: Tab.fraudReport,
                  clickedAt: Date.now(),
                }),
            }}
            isDismissable
            onDismiss={onDismissMerchantReviewRecommendation}
          >
            Extend recommends the claim be approved because it meets your criteria for this issue.
          </InlineAlert>
        </div>
      )}
      {isMerchantDenialRecommended && FF_MERCHANT_REVIEW_CLAIMS && (
        <div className={styles['alert-wrapper']}>
          <InlineAlert
            color={InlineAlertColor.yellow}
            icon={Sparkle}
            primaryButtonProps={{
              text: 'Deny Claim',
              onClick: () => setIsDenyClaimModalVisible(true),
            }}
            secondaryButtonProps={{
              text: 'Review analysis',
              onClick: () =>
                setActiveTab({
                  activeTab: Tab.fraudReport,
                  clickedAt: Date.now(),
                }),
            }}
            isDismissable
            onDismiss={onDismissMerchantReviewRecommendation}
          >
            Extend recommends the claim be denied due to a high fraud risk score.
          </InlineAlert>
        </div>
      )}
    </>
  )
}
