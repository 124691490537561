// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._18fbQG6hDzi4Qelw5pEPS8 {\n    color: var(--neutral-600);\n    font-size: 14px;\n    line-height: 22px;\n    font-weight: 300;\n    white-space: pre-wrap;\n}\n", ""]);
// Exports
exports.locals = {
	"disclaimer": "_18fbQG6hDzi4Qelw5pEPS8"
};
module.exports = exports;
