// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".kQtlMqbLSaULFKXna7c5J {\n    padding-bottom: 16px;\n}\n\n.SHf5tJr7_7nWN83KV9fhk {\n    display: flex;\n    flex-direction: column;\n    gap: 4px;\n    padding: 0 0 16px 0;\n}\n\n._2g9DYeziBHELx6r_Fq7ZMo {\n    display: flex;\n    flex-direction: row;\n    align-items: baseline;\n    width: 100%;\n}\n\n._2g9DYeziBHELx6r_Fq7ZMo:first-child {\n    flex: 1;\n}\n\n._2g9DYeziBHELx6r_Fq7ZMo:nth-child(2) {\n    flex: 2;\n}\n\n._UiJ4LuEG60-i6OOiRVLt {\n    display: flex;\n    flex-direction: row;\n    align-items: baseline;\n    gap: 4px;\n}\n\n.G0eVz159pAOSi6Po-1ALE {\n    flex: 1;\n    padding: 8px 0 0;\n    width: 174px;\n}\n\n._2l1daRyhJKcJA7h7witi4B {\n    flex: 2;\n    display: flex;\n    width: 100%;\n    padding: 8px 0 0;\n}\n\n._2l1daRyhJKcJA7h7witi4B > div {\n    flex-grow: 1;\n    width: 100%;\n}\n\n@media (max-width: 980px) {\n    .SHf5tJr7_7nWN83KV9fhk {\n        grid-template-columns: 1fr;\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"add-button-row": "kQtlMqbLSaULFKXna7c5J",
	"roles-section": "SHf5tJr7_7nWN83KV9fhk",
	"label-row": "_2g9DYeziBHELx6r_Fq7ZMo",
	"data-row": "_UiJ4LuEG60-i6OOiRVLt",
	"role-selector": "G0eVz159pAOSi6Po-1ALE",
	"org-selector": "_2l1daRyhJKcJA7h7witi4B"
};
module.exports = exports;
