import { useMemo } from 'react'
import type { MouseEventHandler } from 'react'
import type { ButtonProps } from '@extend/zen'
import { ContractType } from '@helloextend/extend-api-client'

type ContractHeaderButtonsType = {
  contractType: ContractType
  isContractActive: boolean
  isClaimsManagementEnabled: boolean
  handleRefundModalRequest?: MouseEventHandler<HTMLButtonElement> | undefined
  isEditing?: boolean
  isLoading?: boolean
  handleFileClaim?: MouseEventHandler<HTMLButtonElement> | undefined
  shouldClaimBeDisabled?: boolean
  userHasContractClaimFilingPermission?: boolean
  userHasFullContractPermission?: boolean
  tooltip?: string
  shouldRefundBeDisabled?: boolean
}

export const useGetContractHeaderButtons = ({
  contractType,
  isContractActive = false,
  isClaimsManagementEnabled = false,
  handleRefundModalRequest,
  isEditing = false,
  isLoading = false,
  handleFileClaim,
  shouldClaimBeDisabled = false,
  userHasContractClaimFilingPermission = false,
  userHasFullContractPermission = false,
  tooltip,
  shouldRefundBeDisabled = false,
}: ContractHeaderButtonsType): ButtonProps[] => {
  const getHeaderButtonsArray: ButtonProps[] = useMemo(() => {
    const headerButtonsArray: ButtonProps[] = []

    const isCategoryContract = contractType === ContractType.CATEGORY
    const shouldDisplayFileClaimButton =
      isClaimsManagementEnabled &&
      (userHasContractClaimFilingPermission || userHasFullContractPermission)

    if (isContractActive) {
      if (!isCategoryContract && shouldDisplayFileClaimButton) {
        headerButtonsArray.push({
          'data-cy': 'contract-header:button:file-a-claim',
          text: 'File a Claim',
          emphasis: 'medium',
          onClick: handleFileClaim,
          isDisabled: shouldClaimBeDisabled,
          tooltip: shouldClaimBeDisabled ? tooltip ?? 'A claim is already in progress' : '',
        })
      }

      if (isCategoryContract && shouldDisplayFileClaimButton) {
        headerButtonsArray.push({
          'data-cy': 'contract-header:button:file-claim-for-individual-product',
          text: 'File Claim for Individual Product',
          isProcessing: isLoading,
          emphasis: 'medium',
          onClick: () => window.location.replace('#products'),
          isDisabled: isEditing,
        })
      }

      if (userHasFullContractPermission) {
        headerButtonsArray.push({
          'data-cy': 'contract-header:button:refund',
          text: 'Refund',
          isProcessing: isLoading,
          emphasis: 'medium',
          onClick: handleRefundModalRequest,
          isDisabled: isEditing || shouldRefundBeDisabled,
        })
      }
    }

    return headerButtonsArray
  }, [
    contractType,
    handleFileClaim,
    handleRefundModalRequest,
    isClaimsManagementEnabled,
    isContractActive,
    isEditing,
    isLoading,
    shouldClaimBeDisabled,
    userHasContractClaimFilingPermission,
    userHasFullContractPermission,
    tooltip,
  ])

  return getHeaderButtonsArray
}
