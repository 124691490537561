// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1ddbfJRYotSemMTFm8XzCQ {\n    display: flex;\n    flex-direction: column;\n    padding: 20px 24px 24px 24px;\n}\n\n._2VeY8caiZ2sPgLjheg8L6Y {\n    align-self: center;\n    font-size: 18px;\n    font-weight: 700;\n    line-height: 28px;\n}\n\n._2c0n3tgyxsJYJA85Xk0L8X {\n    font-size: 14px;\n    line-height: 22px;\n    font-weight: 700;\n    color: var(--neutral-1000);\n    padding: 1.6em 0 0;\n    margin: 0;\n}\n\n._6r7Pm0aQfErrEtTFvhgPX {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n    padding: 8px 0 16px;\n}\n\n._2odqo80qDkbbJAv1PU8UJu {\n    font-size: 16px;\n    padding: 8px 0 16px;\n    margin: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "_1ddbfJRYotSemMTFm8XzCQ",
	"header": "_2VeY8caiZ2sPgLjheg8L6Y",
	"subheader": "_2c0n3tgyxsJYJA85Xk0L8X",
	"help-button-section": "_6r7Pm0aQfErrEtTFvhgPX",
	"text": "_2odqo80qDkbbJAv1PU8UJu"
};
module.exports = exports;
