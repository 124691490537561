// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2QneffN5RpgG0hPZFU4Zo5 {\n    border-collapse: separate;\n    border-spacing: 16px 19px;\n    font-weight: 400;\n}\n\n._13GPOiracJ9NfmZSUCoSqi {\n    padding-top: 5px;\n    text-align: right;\n    vertical-align: top;\n    white-space: nowrap;\n}", ""]);
// Exports
exports.locals = {
	"table": "_2QneffN5RpgG0hPZFU4Zo5",
	"badge-cell": "_13GPOiracJ9NfmZSUCoSqi"
};
module.exports = exports;
