// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".lmYSWnvzM7XgJnbP2c9tL {\n    padding-bottom: 12px;\n    width: 542px;\n}\n\n._3-sza6ZvGDRsCCk_qMVHYH {\n    break-before: left;\n    display: flex;\n}\n\n._1iNi8deZ_42jtCi0rBwDu1 {\n    padding-right: 24px;\n    padding-top: 24px;\n}\n", ""]);
// Exports
exports.locals = {
	"input-wrapper": "lmYSWnvzM7XgJnbP2c9tL",
	"button-group": "_3-sza6ZvGDRsCCk_qMVHYH",
	"button-wrapper": "_1iNi8deZ_42jtCi0rBwDu1"
};
module.exports = exports;
