// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3WcbC4rqKhiP7Bui3La9ye {\n  display: flex;\n  flex-direction: column;\n  margin: 16px 0;\n}\n\n._3xXy0fB5vlyEwNf2D7bmCj {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  column-gap: 20px;\n  row-gap: 16px;\n  margin-bottom: 16px;\n}\n\n._3OfNABfjyrJvIgX5F2FfU8 {\n  margin-top: 16px;\n}\n\n._1igkfQnwUhNQchHPL3RXV {\n  margin-top: 16px;\n}", ""]);
// Exports
exports.locals = {
	"form": "_3WcbC4rqKhiP7Bui3La9ye",
	"grid": "_3xXy0fB5vlyEwNf2D7bmCj",
	"alert-wrapper": "_3OfNABfjyrJvIgX5F2FfU8",
	"button-group-wrapper": "_1igkfQnwUhNQchHPL3RXV"
};
module.exports = exports;
