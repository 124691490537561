// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".II9PIMhV8aPzNtsWcVsiD {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 40px;\n    align-items: center;\n    background-color: var(--blue-100);\n    border: 1px solid var(--blue-300);\n    border-radius: 4px;\n    padding: 12px;\n    overflow: hidden;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n}\n\n.II9PIMhV8aPzNtsWcVsiD label {\n    color: var(--black);\n}\n\n.II9PIMhV8aPzNtsWcVsiD div {\n    margin: 0;\n}", ""]);
// Exports
exports.locals = {
	"expense-info-banner": "II9PIMhV8aPzNtsWcVsiD"
};
module.exports = exports;
