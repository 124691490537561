// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".eKIOe2mGC9sZDHaA_b5i6 {\n    max-width: 816px;\n    margin: auto;\n}\n\n._37puMcr5cd8ebX4qimENQY {\n    align-content: flex-start;\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    margin-bottom: 56px;\n}\n\n._162O1-NsSNOwFkj16Pa21s {\n    text-align: center;\n    margin: 0 0 32px;\n}\n\n.Uj-di7bMTuvD4DHT7OoIQ input {\n    display: none;\n}\n\n.Uj-di7bMTuvD4DHT7OoIQ input[type=radio]:checked + ._3lWgnUmyeZzDt4OHRzA7gN {\n    border: 1px solid var(--blue-800);\n    background-color: var(--neutral-100);\n}\n\n.Uj-di7bMTuvD4DHT7OoIQ input[type=radio]:checked + ._3lWgnUmyeZzDt4OHRzA7gN > ._1PvsGDZXy6gC98zW9OOq0x {\n    opacity: 1;\n}\n\n.Uj-di7bMTuvD4DHT7OoIQ ._3lWgnUmyeZzDt4OHRzA7gN {\n    align-items: center;\n    background-color: var(--white);\n    border: 1px solid var(--neutral-200);\n    border-radius: 4px;\n    box-sizing: border-box;\n    cursor: pointer;\n    display: flex;\n    height: 80px;\n    justify-content: space-between;\n    margin-bottom: 32px;\n    padding: 0 24px 0 24px;\n    transition: all .25s ease-in-out;\n    width: 392px;\n}\n\n.Uj-di7bMTuvD4DHT7OoIQ ._3lWgnUmyeZzDt4OHRzA7gN:hover {\n    border: 1px solid var(--blue-800);\n    background-color: var(--neutral-100);\n}\n\n.Uj-di7bMTuvD4DHT7OoIQ ._1PvsGDZXy6gC98zW9OOq0x {\n    opacity: 0;\n    transition: opacity .25s ease-in-out;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    min-width: 24px;\n    width: 24px;\n    height: 24px;\n    background-color: var(--blue-800);\n    border-radius: 30px;\n}\n\n.Uj-di7bMTuvD4DHT7OoIQ ._1PvsGDZXy6gC98zW9OOq0x::after {\n    content: \" \";\n    width: 6px;\n    height: 12px;\n    border-color: var(--white);\n    border-style: solid;\n    border-width: 0 2px 2px 0;\n    transform: rotate(45deg);\n    box-sizing: border-box;\n}\n", ""]);
// Exports
exports.locals = {
	"form": "eKIOe2mGC9sZDHaA_b5i6",
	"form-group": "_37puMcr5cd8ebX4qimENQY",
	"title": "_162O1-NsSNOwFkj16Pa21s",
	"container": "Uj-di7bMTuvD4DHT7OoIQ",
	"select-container-box": "_3lWgnUmyeZzDt4OHRzA7gN",
	"check-circle": "_1PvsGDZXy6gC98zW9OOq0x"
};
module.exports = exports;
