// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3r1HXMyzYXx9eIbWANV3TN {\n    display: grid;\n    grid-template-columns: 1fr 1fr 2fr;\n    grid-auto-flow: row;\n    gap: 20px;\n    align-items: center;\n    margin-bottom: 40px;\n}\n\n.OKpKaXOzWp72XBsX3P43u {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 25px;\n}\n\n._2Rw4G07H4vdUQm8sXbXEdo {\n    margin: 0;\n    color: var(--blue-1000);\n}\n\n.NRI1M4W-l0syq9JHdxAtG {\n    color: var(--neutral-600);\n    font-size: 14px;\n    margin: 0 0 5px 0;\n    display: flex;\n    align-items: center;\n}\n\n._1GNEjOs9nF8hQNiGeHHbDL {\n    margin: 0;\n}", ""]);
// Exports
exports.locals = {
	"row": "_3r1HXMyzYXx9eIbWANV3TN",
	"container": "OKpKaXOzWp72XBsX3P43u",
	"header": "_2Rw4G07H4vdUQm8sXbXEdo",
	"label": "NRI1M4W-l0syq9JHdxAtG",
	"detail": "_1GNEjOs9nF8hQNiGeHHbDL"
};
module.exports = exports;
