// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".S04QLg8TTjEuauG_7CVtq {\n    display: flex;\n    border: 1px solid var(--neutral-300);\n    box-sizing: border-box;\n    margin-top: 32px;\n    padding: 40px 18px 16px 50px;\n    width: 100%;\n}\n\n._2T5K22Omofhi-NXQw8VOcN {\n    font-family: \"Nunito Sans\", sans-serif;\n}\n\n._1fAJYnMSbrIRbEOQwAp83y {\n    color: var(--blue-1000);\n    font-size: 20px;\n    width: 316px;\n    font-weight: 700;\n    line-height: 28px;\n    margin: 0 80px 0 0;\n}\n\n._17IWYfIjEydVwQ2QzpYjpu {\n    color: var(--neutral-1000);\n    font-size: 18px;\n    font-weight: normal;\n    display: inline-block;\n    margin-top: 8px;\n    width: 316px;\n}\n\n._2YNSTXJgIE508QlVxcg71v {\n    display: flex;\n    align-items: flex-start;\n}", ""]);
// Exports
exports.locals = {
	"wrapper": "S04QLg8TTjEuauG_7CVtq",
	"heading-wrapper": "_2T5K22Omofhi-NXQw8VOcN",
	"header": "_1fAJYnMSbrIRbEOQwAp83y",
	"subheader": "_17IWYfIjEydVwQ2QzpYjpu",
	"content-wrapper": "_2YNSTXJgIE508QlVxcg71v"
};
module.exports = exports;
