// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1v6715ZBH31IEjmOxzp7p_ {\n    text-align: right;\n    padding-right: 10px;\n}", ""]);
// Exports
exports.locals = {
	"cell": "_1v6715ZBH31IEjmOxzp7p_"
};
module.exports = exports;
