import type { FC } from 'react'
import React, { useMemo, useState } from 'react'
import { CellText, ColumnHead, TableBody, TableCell, TableHead } from '@helloextend/merchants-ui'
import {
  Button,
  ButtonGroup,
  COLOR,
  Spinner,
  InlineAlert,
  InlineAlertColor,
  Info as InfoIcon,
} from '@extend/zen'
import { currency } from '@extend/client-helpers'
import type { Expense, ServiceOrder } from '@helloextend/extend-api-client'
import { DeleteExpenseModal } from './delete-expense-modal'
import { RequestPaymentModal } from './request-payment-modal'
import { expenseColumns, hasExpenses } from '../../../utils/expenses'
import { ExpenseColumns } from './expense-columns'
import { DeleteButtonColumn } from './delete-button-column'
import { useListServiceOrderExpensesQuery } from '../../../queries/service-orders'
import { Permission } from '../../../lib/permissions'
import { usePermissions } from '../../../hooks/use-permissions'
import { ExpenseInfoBanner } from './expense-info-banner'
import { AddExpenseForm } from './add-expense-form'

import styles from './expenses.module.css'
import { LDFlag } from '../../../constants/ld-flags'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useGetRateCardsForServicerQuery } from '../../../queries/servicers'

// we want to hide the expenses table until the service order is
// in a state where expenses can be added/already exist
export const showExpenses = (serviceOrder: ServiceOrder): boolean => {
  const disallowedStatuses = ['created', 'assigned']

  return (
    serviceOrder?.status !== undefined &&
    !disallowedStatuses.includes(serviceOrder?.status) &&
    serviceOrder?.assignedServicer?.servicerType === 'merchant'
  )
}

export const calculateExpensesTotalAmount = (expenses: Expense[]): string => {
  const { currencyCode } = expenses[0]?.cost || ''

  return currency.formatWithoutCurrencySymbol(
    expenses.reduce(
      (sum, current) => sum + (current.totalCost ?? current.cost.amount * (current.quantity ?? 1)),
      0,
    ),
    currencyCode,
    true,
  )
}
export const Expenses: FC<{
  serviceOrder: ServiceOrder
}> = ({ serviceOrder }) => {
  const [isAddExpenseFormVisible, setIsAddExpenseFormVisible] = useState(false)
  const [isRequestPaymentModalVisible, setIsRequestPaymentModalVisible] = useState(false)
  const [expenseSelectedForDeletion, setExpenseSelectedForDeletion] = useState<string | null>(null)
  const { hasPermission } = usePermissions()
  const hasClaimsFullAccess = hasPermission(Permission.StoreClaimsFullAccess)
  const { data, isLoading } = useListServiceOrderExpensesQuery({
    serviceOrderId: serviceOrder?.id || '',
  })

  const { [LDFlag.ServicerRateCards]: FF_RATE_CARDS } = useFlags()

  const { data: rateCards } = useGetRateCardsForServicerQuery({
    servicerId: serviceOrder?.assignedServicer?.servicerId || '',
    skip: !FF_RATE_CARDS || !serviceOrder?.assignedServicer?.servicerId,
  })

  const expenses = useMemo(
    () => (data ? [...data].sort((a, b) => a.createdAt - b.createdAt) : []),
    [data],
  )
  const { currencyCode } = expenses[0]?.cost || ''

  const nonVoidedExpenses = expenses.filter((expense) => expense.status !== 'voided')

  const canAddExpense = ![
    // can add expenses when the service order is NOT in one of these states
    'closed',
    'created',
    'assigned',
    'payment_requested',
    'payment_approved',
    'paid',
  ].includes(serviceOrder?.status)

  const canRequestPayment = serviceOrder?.status === 'fulfilled'

  const handleDeleteExpenseModalToggle = (selectedExpenseId?: string | null): void => {
    if (!expenseSelectedForDeletion && selectedExpenseId) {
      setExpenseSelectedForDeletion(selectedExpenseId)
    } else {
      setExpenseSelectedForDeletion(null)
    }
  }

  const activeRateCard = (rateCards ?? []).find(
    (rateCard) =>
      Date.now() >= rateCard.startDate && (!rateCard.endDate || Date.now() <= rateCard.endDate),
  )

  if (isLoading) {
    return (
      <div className="width-100 height-100 flex align-items-center justify-content-center">
        <Spinner size={40} color={COLOR.BLUE[800]} data-cy="spinner" />
      </div>
    )
  }

  return (
    <div className={styles.container} data-cy="expenses-cell">
      {hasExpenses(expenses) ? (
        <>
          <div className={styles['alert-wrapper']}>
            <InlineAlert
              color={InlineAlertColor.blue}
              icon={InfoIcon}
              data-cy="service-order-expenses-alert"
            >
              <p>
                <b>Expenses Total: {calculateExpensesTotalAmount(nonVoidedExpenses)}</b>
              </p>
              <p>
                Update expenses before requesting payment. Expenses cannot be changed once you
                Request Payment.
              </p>
            </InlineAlert>
          </div>
          <div className={styles['expenses-table-wrapper']}>
            <ExpenseInfoBanner
              serviceOrder={serviceOrder}
              expenseTotal={calculateExpensesTotalAmount(nonVoidedExpenses)}
              currency={currencyCode}
            />
            <table className={styles.table}>
              <TableHead>
                {expenseColumns.map((column) => (
                  <ColumnHead
                    key={`head-${column.accessor}`}
                    columnWidth={column.columnWidth ?? 100}
                    active={false}
                    textAlign={column.textAlign ?? 'left'}
                  >
                    {column.Header}
                  </ColumnHead>
                ))}
                {serviceOrder.status !== 'payment_approved' && (
                  <ColumnHead columnWidth={7} active={false} />
                )}
              </TableHead>
              <TableBody>
                {nonVoidedExpenses.map((row) => (
                  <tr className={styles['table-row']} key={row.id as string}>
                    <ExpenseColumns row={row} />
                    {canAddExpense && (
                      <DeleteButtonColumn
                        onClick={() => {
                          setExpenseSelectedForDeletion(row.id)
                        }}
                      />
                    )}
                  </tr>
                ))}
              </TableBody>
              <tfoot className={styles['table-foot']}>
                <tr>
                  <td className={styles['total-label-cell']} colSpan={5}>
                    <CellText align="right">Total:</CellText>
                  </td>
                  <TableCell>
                    <CellText align="right">
                      <span className={styles['total-amount-cell']}>
                        {calculateExpensesTotalAmount(nonVoidedExpenses)}
                      </span>
                    </CellText>
                  </TableCell>
                </tr>
              </tfoot>
            </table>
            {isAddExpenseFormVisible && (
              <AddExpenseForm
                rateCard={activeRateCard}
                closeForm={() => setIsAddExpenseFormVisible(false)}
                currencyCode={currencyCode}
                serviceOrderId={serviceOrder.id}
              />
            )}
          </div>
        </>
      ) : (
        <div className={styles['alert-wrapper']}>
          <InlineAlert color={InlineAlertColor.neutral} icon={InfoIcon} isDismissable={false}>
            No expenses found
          </InlineAlert>
          {isAddExpenseFormVisible && (
            <AddExpenseForm
              closeForm={() => setIsAddExpenseFormVisible(false)}
              currencyCode={currencyCode}
              serviceOrderId={serviceOrder.id}
            />
          )}
        </div>
      )}

      {canAddExpense && hasClaimsFullAccess && (
        <>
          <RequestPaymentModal
            isVisible={isRequestPaymentModalVisible}
            closeModal={() => setIsRequestPaymentModalVisible(false)}
            serviceOrder={serviceOrder}
          />
          <ButtonGroup>
            {!isAddExpenseFormVisible && (
              <Button
                emphasis="medium"
                ariaLabel="Add Expense"
                text="Add Expense"
                onClick={() => setIsAddExpenseFormVisible(true)}
              />
            )}
            <Button
              ariaLabel="Request Payment"
              text="Request Payment"
              isDisabled={!canRequestPayment}
              onClick={() => setIsRequestPaymentModalVisible(true)}
            />
          </ButtonGroup>
        </>
      )}

      <DeleteExpenseModal
        closeModal={handleDeleteExpenseModalToggle}
        isVisible={Boolean(expenseSelectedForDeletion)}
        expenseId={expenseSelectedForDeletion || ''}
        serviceOrderId={serviceOrder.id}
      />
    </div>
  )
}
