// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1Ve8cFS4iITdzWHH04u_-H {\n    margin-top: 40px;\n    max-width: 1107px;\n}\n\n._1Ve8cFS4iITdzWHH04u_-H > * + * {\n    margin-top: 32px;\n}", ""]);
// Exports
exports.locals = {
	"cards": "_1Ve8cFS4iITdzWHH04u_-H"
};
module.exports = exports;
