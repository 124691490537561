import type { Store } from 'redux'
import { configureStore as rtkConfigureStore } from '@reduxjs/toolkit'
import { EXTEND_ENV } from '@helloextend/client-constants'
import { logErrors } from '@helloextend/core-api-redux/src/middleware'
import {
  claimsApi,
  contractsApi,
  productCategoriesRdbApi,
  productsApi,
  productsRdbApi,
  serviceOrdersApi,
  servicersApi,
  adjudicationManagementApi,
  ordersApi,
  conversationsApi,
} from '@helloextend/extend-api-rtk-query'
import rootReducer from '../reducers'

export default function configureStore(): Store {
  const store = rtkConfigureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        logErrors,
        claimsApi.middleware,
        productCategoriesRdbApi.middleware,
        productsApi.middleware,
        productsRdbApi.middleware,
        contractsApi.middleware,
        serviceOrdersApi.middleware,
        servicersApi.middleware,
        adjudicationManagementApi.middleware,
        ordersApi.middleware,
        conversationsApi.middleware,
      ),
  })

  if (EXTEND_ENV === 'local' && module.hot) {
    module.hot.accept('../reducers', () => store.replaceReducer(rootReducer))
  }

  return store
}
