import type { FC } from 'react'
import React, { useEffect, useMemo } from 'react'
import { useFormik } from 'formik'
import {
  Input,
  Button,
  ButtonGroup,
  InlineAlert,
  InlineAlertColor,
  Info as InfoIcon,
} from '@extend/zen'
import { CurrencyInputGroup } from '@helloextend/merchants-ui'
import { useAtomValue, useSetAtom } from 'jotai/react'
import { useFulfillServiceOrderMutation } from '../../../queries/service-orders'
import styles from './resolve-service-order-product-replacement-form.module.css'
import { isResolveServiceOrderModalVisibleAtom } from '../../../atoms/resolve-service-order-modal'
import { claimDetailsAtom } from '../../../atoms/claim-details'
import { V2 } from '@extend-services/service-orders'
import styled from '@emotion/styled'

type FulfillmentProduct = V2.Models.FulfillmentProduct

export type ReplacementModalProps = {
  serviceOrderId: string
}

export const ResolveServiceOrderShippingProtectionForm: FC<ReplacementModalProps> = ({
  serviceOrderId,
}) => {
  const claim = useAtomValue(claimDetailsAtom)
  const initialValues = useMemo(() => {
    return {
      products: claim.products?.map((p) => ({
        name: p.title,
        value: {
          amount: 0,
          currencyCode: p.components?.[0].value.currencyCode || 'USD',
        },
        referenceId: p.referenceId,
      })),
    }
  }, [claim.products])

  const { mutateAsync: fulfillReplacement, isLoading } = useFulfillServiceOrderMutation()
  const setIsResolveServiceOrderModalVisible = useSetAtom(isResolveServiceOrderModalVisibleAtom)

  // Custom validation function
  const validate = (values: { products: FulfillmentProduct[] }) => {
    const errors: any = {}

    values.products.forEach((product, index) => {
      const productErrors: any = {}

      if (!product.name) {
        productErrors.name = 'Name is required'
      }

      if (!product.value.amount) {
        productErrors.value = { amount: 'Amount is required' }
      }

      if (Object.keys(productErrors).length > 0) {
        errors.products = errors.products || []
        errors.products[index] = productErrors
      }
    })

    return errors
  }

  const { handleSubmit, handleBlur, handleChange, values, setFieldValue, isValid, validateForm } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValues as { products: FulfillmentProduct[] },
      onSubmit: async (values: { products: FulfillmentProduct[] }) => {
        await fulfillReplacement({
          serviceOrderId,
          fulfillRequest: {
            products: values.products,
            method: 'shipping_protection',
          },
        })
        setIsResolveServiceOrderModalVisible(false)
      },
      validate,
    })

  useEffect(() => {
    validateForm(values)
  }, []) // Empty dependency array to run only on mount

  const saveButtonText = 'Resolve'

  return (
    <form onSubmit={handleSubmit} name="shipping-protection-form">
      {values.products.map((p, index) => (
        <FormItemsWrapper key={index}>
          <Input
            id={`products[${index}].name`}
            label={`Replacement Product ${index + 1}`}
            value={p.name}
            onBlur={handleBlur}
            onChange={handleChange}
            data-cy={`product${index}.name`}
            name={`products[${index}].name`}
            min={1}
          />
          <CurrencyInputGroup
            data-cy={`products[${index}].value.amount`}
            name={`products[${index}].value.amount`}
            label={`Fulfillment Expense ${index + 1}`}
            currencyProps={{
              codeFieldName: `products.${index}.value.currency`,
              codeValue: p.value.currencyCode ?? 'USD',
            }}
            showSymbol={false}
            onChange={setFieldValue}
            onBlur={handleBlur}
            value={p.value.amount}
            invalid={!p.value.amount}
            isCurrencyCodeDisabled={true}
          />
        </FormItemsWrapper>
      ))}

      <InlineAlert
        color={InlineAlertColor.blue}
        icon={InfoIcon}
        data-cy="service-order-resolve-alert"
        isDismissable={false}
      >
        {`Resolution cannot be edited once submitted. Please review before clicking
            "${saveButtonText}"`}
      </InlineAlert>

      <div className={styles['button-group-wrapper']}>
        <ButtonGroup isReversed>
          <Button
            type="submit"
            text={saveButtonText}
            data-cy="resolve-button"
            isDisabled={isLoading || !isValid}
            isProcessing={isLoading}
          />
          <Button
            text="Cancel"
            emphasis="medium"
            data-cy="cancel-button"
            onClick={() => setIsResolveServiceOrderModalVisible(false)}
            isDisabled={isLoading}
          />
        </ButtonGroup>
      </div>
    </form>
  )
}

const FormItemsWrapper = styled.div({
  marginBottom: '20px',
  marginTop: '20px',
})
