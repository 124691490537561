// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._32qsRgBG64UbRT61bZOzk6 {\n    max-width: 1008px;\n    margin: 108px auto 0;\n    text-align: center;\n}\n\n._9IhJX30LaJ3xpo382C4I9 {\n    color: var(--blue-1000);\n    font-size: 22px;\n    font-weight: 700;\n}\n\n.bCJVcRdJAbVEz44xu9G1l {\n    color: var(--blue-1000);\n    font-size: 20px;\n    line-height: 27px;\n    white-space: pre-line;\n}\n\n.bCJVcRdJAbVEz44xu9G1l p {\n    margin: 0;\n    padding: 0;\n}\n\n._1qeSgwSj8QBOVbj8vfHu_Z {\n    margin-top: 16px;\n}\n\n._3gf4k-U87iJL1kSeMT4JLY {\n    display: inline-flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    margin-top: 56px;\n}\n\n.W3jYsvbCKrH8U6XnF1c0B {\n    width: 400px;\n}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "_32qsRgBG64UbRT61bZOzk6",
	"header": "_9IhJX30LaJ3xpo382C4I9",
	"caption": "bCJVcRdJAbVEz44xu9G1l",
	"help": "_1qeSgwSj8QBOVbj8vfHu_Z",
	"button": "_3gf4k-U87iJL1kSeMT4JLY",
	"icons": "W3jYsvbCKrH8U6XnF1c0B"
};
module.exports = exports;
