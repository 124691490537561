// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3vQIIFcG6EiQSGql3hpdzM {\n    padding-bottom: 16px;\n}\n\n._1MRMjQYzvFpXjYQYiRGtJY {\n    display: flex;\n    flex-wrap: wrap;\n    align-items: baseline;\n    gap: 4px;\n    padding-bottom: 8px;\n}\n\n._2HDg56f1xaTvXwTfFgfQFH {\n    display: flex;\n    flex-direction: column;\n    gap: 4px;\n    padding: 0 0 16px 0;\n}\n\n@media (max-width: 980px) {\n    ._2HDg56f1xaTvXwTfFgfQFH {\n        grid-template-columns: 1fr;\n    }\n}\n\n._1C_bJvW0d5cMLiMED5hapc {\n    display: flex;\n    flex-direction: row;\n    align-items: baseline;\n    width: 100%;\n}\n\n.J9MC5HqrlWO5emMu6_ZjM {\n    display: flex;\n    flex-direction: row;\n    align-items: baseline;\n    gap: 4px;\n}\n\n._2PiHPENlmHmGFvpguwBYeU {\n    display: flex;\n    flex-wrap: wrap;\n    flex-direction: column;\n    gap: 8px;\n}\n\n._2l_lp3rkWUAKQuADAB9GUP {\n    flex: 1;\n    padding: 8px 0 0;\n    width: 174px;\n}\n\n._1PVQ-n1KsjXi6Z-0aGAMkF {\n    flex: 2;\n    display: flex;\n    width: 100%;\n    padding: 8px 0 0;\n}\n\n._1PVQ-n1KsjXi6Z-0aGAMkF > div {\n    flex-grow: 1;\n    width: 100%;\n}\n", ""]);
// Exports
exports.locals = {
	"add-button-row": "_3vQIIFcG6EiQSGql3hpdzM",
	"user-name-section": "_1MRMjQYzvFpXjYQYiRGtJY",
	"roles-section": "_2HDg56f1xaTvXwTfFgfQFH",
	"label-row": "_1C_bJvW0d5cMLiMED5hapc",
	"data-row": "J9MC5HqrlWO5emMu6_ZjM",
	"container": "_2PiHPENlmHmGFvpguwBYeU",
	"role-selector": "_2l_lp3rkWUAKQuADAB9GUP",
	"org-selector": "_1PVQ-n1KsjXi6Z-0aGAMkF"
};
module.exports = exports;
