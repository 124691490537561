// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1vd07HHf-C_ThCzkd9GctI {\n    width: calc(50% - 10px);\n}", ""]);
// Exports
exports.locals = {
	"fulfillment-method-wrapper": "_1vd07HHf-C_ThCzkd9GctI"
};
module.exports = exports;
