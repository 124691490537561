// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".i0Emy1hecrXH4PRhJfdls {\n    margin-bottom: 32px;\n}\n\n.i0Emy1hecrXH4PRhJfdls svg {\n    margin: 0 12px;\n}\n\n._38aJeT-ZEwgHTsBC_YcE-9 {\n    margin: 0 auto;\n    max-width: 826px;\n    text-align: center;\n}\n\n._1igTo_2u1pjqoy2LgEBN3y {\n    margin: 40px 0 56px;\n    font-size: 20px;\n}\n\n._1igTo_2u1pjqoy2LgEBN3y a {\n    color: var(--bright-blue);\n    text-decoration: underline;\n}\n", ""]);
// Exports
exports.locals = {
	"icons": "i0Emy1hecrXH4PRhJfdls",
	"wrapper": "_38aJeT-ZEwgHTsBC_YcE-9",
	"body": "_1igTo_2u1pjqoy2LgEBN3y"
};
module.exports = exports;
