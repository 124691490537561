// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._15HlmY21Djz1Us2DbPmaJx {\n    height: 600px;\n    width: 400px;\n    margin-bottom: 24px;\n}\n\n._1yrTGPFq0xWD7AdpcBexfs {\n    height: 600px;\n    width: 400px;\n    margin-bottom: 24px;\n}\n\n._2nqK7kKgwK4k2yf4VV33tc {\n    height: 800px;\n    width: 400px;\n    margin-bottom: 24px;\n}\n\n._15HlmY21Djz1Us2DbPmaJx._5S3clNJzP0zMUlXCxB6lM {\n    height: 570px;\n    width: 600px;\n}\n\n._1yrTGPFq0xWD7AdpcBexfs._5S3clNJzP0zMUlXCxB6lM {\n    height: 700px;\n    width: 700px;\n}\n\n._2nqK7kKgwK4k2yf4VV33tc._5S3clNJzP0zMUlXCxB6lM {\n    height: 600px;\n    width: 600px;\n}\n\n._1pYfxJ81MAkjcE1-HBbmbU {\n    transform: translateX(20%);\n}\n\n._3owGOb3F4jlHIHoLquKrdL {\n    display: flex;\n    justify-content: center;\n    margin-top: 16px;\n}\n", ""]);
// Exports
exports.locals = {
	"learn-more": "_15HlmY21Djz1Us2DbPmaJx",
	"offer": "_1yrTGPFq0xWD7AdpcBexfs",
	"aftermarket": "_2nqK7kKgwK4k2yf4VV33tc",
	"desktop": "_5S3clNJzP0zMUlXCxB6lM",
	"cart": "_1pYfxJ81MAkjcE1-HBbmbU",
	"spinner": "_3owGOb3F4jlHIHoLquKrdL"
};
module.exports = exports;
