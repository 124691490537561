// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2HA-2A8W1eNjlojCx-ukHb {\n    display: grid;\n    gap: 16px;\n    grid-template-columns: 33% 33% 33%;\n}\n\n._1C-1ZOdUjT-PIJdsIMXuTM {\n    display: flex;\n    gap: 8px;\n    justify-content: end;\n    margin-top: 30px;\n}\n\n._11Asugt5pm5FTInSIf-GCv {\n    overflow-y: auto;\n}", ""]);
// Exports
exports.locals = {
	"grid-33-33-33": "_2HA-2A8W1eNjlojCx-ukHb",
	"button-group": "_1C-1ZOdUjT-PIJdsIMXuTM",
	"box-overflow": "_11Asugt5pm5FTInSIf-GCv"
};
module.exports = exports;
