// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3HiNXGGdagqDyz7d1rPszE {\n    margin-bottom: 32px;\n}\n\n._3HiNXGGdagqDyz7d1rPszE svg {\n    margin: 0 12px;\n}\n\n._3n3P5mGPs7Y61ircP6jH9t {\n    margin: auto;\n    position: relative;\n    text-align: center;\n    top: 50%;\n    transform: translateY(-50%);\n    width: 826px;\n}\n\n._3n3P5mGPs7Y61ircP6jH9t p {\n    font-size: 20px;\n}\n\n._2SrUu4XiIoGsL9rNhW25W5 {\n    color: var(--blue-600);\n    text-decoration: underline;\n}\n\n._2SrUu4XiIoGsL9rNhW25W5:visited {\n    color: var(--blue-600);\n    text-decoration: underline;\n}\n\n._2rdbt7uQJ6J6mN32vlxOim {\n    margin-top: 40px;\n}\n\n._35p71WZPrd86QlypSWVlpt {\n    margin-bottom: 56px;\n}\n", ""]);
// Exports
exports.locals = {
	"icons": "_3HiNXGGdagqDyz7d1rPszE",
	"messages": "_3n3P5mGPs7Y61ircP6jH9t",
	"link": "_2SrUu4XiIoGsL9rNhW25W5",
	"error": "_2rdbt7uQJ6J6mN32vlxOim",
	"support": "_35p71WZPrd86QlypSWVlpt"
};
module.exports = exports;
