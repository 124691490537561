import { useMemo } from 'react'
import type { Contract } from '@helloextend/extend-api-client'
import { ContractStatus } from '@helloextend/extend-api-client'
import { currency } from '@extend/client-helpers'
import { useSearchEntitlementsQuery } from '../queries/entitlements'

type LimitOfLiabilityType = {
  remainingAmount: string
  isLoading: boolean
}

const INVALID_CONTRACT_STATUSES = [
  ContractStatus.CANCELLED,
  ContractStatus.EXPIRED,
  ContractStatus.FULFILLED,
]

export const useCategoryLOLRemaining = (
  contract: Contract,
  lineItemId: string,
): LimitOfLiabilityType => {
  const { data: entitlements, isLoading } = useSearchEntitlementsQuery({
    contractId: contract.id,
    enabled: Boolean(contract.id),
  })

  const remainingAmount = useMemo(() => {
    const product = entitlements?.products?.find((product) => product.lineItemId === lineItemId)

    const amount = product?.entitlements.reduce(
      (entitlementTotal, { remainingCoverage }) =>
        entitlementTotal + (remainingCoverage?.validationProps?.remaining || 0),
      0,
    )

    const currencyCode = product?.entitlements?.[0]?.contract?.currency

    if (!amount || INVALID_CONTRACT_STATUSES.includes(contract.status)) {
      return `0.00 ${currencyCode}`
    }
    return `${currency.formatWithoutCurrencySymbol(amount, currencyCode)}`
  }, [entitlements, contract])

  return {
    remainingAmount,
    isLoading,
  }
}
