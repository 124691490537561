// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1dfrWZuzXlImSD2UPGbxc6 {\n    cursor: not-allowed;\n}\n\n._1dfrWZuzXlImSD2UPGbxc6 > div {\n    pointer-events: none;\n}", ""]);
// Exports
exports.locals = {
	"read-only": "_1dfrWZuzXlImSD2UPGbxc6"
};
module.exports = exports;
