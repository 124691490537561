// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1DN8izDpuJqb8jeFM14IKB {\n  display: flex;\n  align-items: center;\n  gap: 20px;\n}\n\n._1DN8izDpuJqb8jeFM14IKB img {\n  width: 75px;\n}\n\n._1DN8izDpuJqb8jeFM14IKB button {\n  align-self: center;\n}\n", ""]);
// Exports
exports.locals = {
	"product-title": "_1DN8izDpuJqb8jeFM14IKB"
};
module.exports = exports;
