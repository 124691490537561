// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._21Rw1Eso9mv3PiYVlA5U-G {\n    display: flex;\n    flex-direction: column;\n    gap: 32px;\n}\n\n@media (min-width: 768px) {\n    ._1SAd418vV-ppWUIPDB9wzw {\n        display: grid;\n        row-gap: 16px;\n        grid-template-columns: repeat(3, 1fr);\n    }\n\n    ._1SAd418vV-ppWUIPDB9wzw svg {\n        /*this stops flex:grid from overlapping the copy and information icons within data properties*/\n        margin-right: 4px;\n    }\n}\n\n._1SAd418vV-ppWUIPDB9wzw label, ._1SAd418vV-ppWUIPDB9wzw div {\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    overflow: hidden;\n}\n\n._21Rw1Eso9mv3PiYVlA5U-G hr {\n    margin: 25px 0;\n    height: 1px;\n    background-color: var(--neutral-300);\n    border: none;\n}\n", ""]);
// Exports
exports.locals = {
	"claims-wrapper": "_21Rw1Eso9mv3PiYVlA5U-G",
	"grid": "_1SAd418vV-ppWUIPDB9wzw"
};
module.exports = exports;
