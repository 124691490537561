// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1MCtgserX8GiemjwgfbjRR {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n}\n\n.rtHGE6X_2Qfn1TFfwHfwd {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n}\n\n.rtHGE6X_2Qfn1TFfwHfwd div {\n    margin: 0;\n}\n\n.URQjS4lNAQ2Ob35OZPrqp {\n    width: 50%;\n    display: flex;\n    flex-direction: column;\n    gap: 4px;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "_1MCtgserX8GiemjwgfbjRR",
	"grid": "rtHGE6X_2Qfn1TFfwHfwd",
	"date-container": "URQjS4lNAQ2Ob35OZPrqp"
};
module.exports = exports;
