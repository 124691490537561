import { useFlags } from 'launchdarkly-react-client-sdk'
import { LDFlag } from '../constants/ld-flags'
import { getUserRoleLabel } from '../utils/user-roles'
import type { MerchantsEnterpriseUserRole } from '../types/users'

export interface RoleOption {
  display: string
  value: MerchantsEnterpriseUserRole
}

export const enterpriseRoleOptions: RoleOption[] = [
  { display: getUserRoleLabel('MerchantPortalAdmin'), value: 'MerchantPortalAdmin' },
  { display: getUserRoleLabel('MerchantTransactionManager'), value: 'MerchantTransactionManager' },
  { display: getUserRoleLabel('MerchantApiManager'), value: 'MerchantApiManager' },
  { display: getUserRoleLabel('MerchantClaimsAgent'), value: 'MerchantClaimsAgent' },
  { display: getUserRoleLabel('MerchantPortalViewOnly'), value: 'MerchantPortalViewOnly' },
]

/**
 *  Filter available role options based on feature flag and permissions
 *  @param isR3Enabled - Feature flag for tenancy release where MerchantApiManager is introduced (EnterpriseTenancyR3)
 *  @param hasInStorePermission - Permission to manage in store app
 */
function filterAvailableRoleOptions(isR3FlagEnabled: boolean): RoleOption[] {
  return isR3FlagEnabled
    ? enterpriseRoleOptions
    : enterpriseRoleOptions.filter((option) => option.value !== 'MerchantApiManager')
}

function filterViewOnlyRole(roles: RoleOption[], isViewOnlyEnabled: boolean): RoleOption[] {
  return isViewOnlyEnabled
    ? roles
    : roles.filter((option) => option.value !== 'MerchantPortalViewOnly')
}

export const useRoleOptions = (): RoleOption[] => {
  const {
    [LDFlag.EnterpriseTenancyR3]: FF_ENTERPRISE_TENANCY_R3,
    [LDFlag.ViewOnlyRole]: FF_VIEW_ONLY_ROLE,
  } = useFlags()
  return filterViewOnlyRole(filterAvailableRoleOptions(FF_ENTERPRISE_TENANCY_R3), FF_VIEW_ONLY_ROLE)
}
