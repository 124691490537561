// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._32mJLN55ds_FYIhQBUQzDQ {\n    width: 100%;\n    table-layout: fixed;\n    border-collapse: collapse;\n}\n\n._32mJLN55ds_FYIhQBUQzDQ thead {\n    color: var(--neutral-600);\n    font-size: 14px;\n    text-align: left;\n    margin-bottom: 8px;\n}\n\n._32mJLN55ds_FYIhQBUQzDQ th {\n    text-align: left;\n    padding-bottom: 8px;\n    width: initial;\n}\n\n._32mJLN55ds_FYIhQBUQzDQ td {\n    font-weight: normal;\n    padding: 16px 0;\n}\n\n._32mJLN55ds_FYIhQBUQzDQ tr {\n    border: none;\n    border-top: 1px solid var(--neutral-300);\n    white-space: normal;\n}\n\n._32mJLN55ds_FYIhQBUQzDQ tr:first-of-type {\n    border: none;\n    white-space: normal;\n}\n", ""]);
// Exports
exports.locals = {
	"table": "_32mJLN55ds_FYIhQBUQzDQ"
};
module.exports = exports;
