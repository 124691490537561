// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3AwPyyhMXlreuSGWs5dwfs {\n    display: grid;\n    grid-auto-flow: column;\n    grid-template-columns: repeat(2, 1fr);\n    margin: .5rem 0;\n    height: 100%;\n    width: 100%;\n    gap: 16px;\n}", ""]);
// Exports
exports.locals = {
	"container": "_3AwPyyhMXlreuSGWs5dwfs"
};
module.exports = exports;
