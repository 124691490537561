import type { FC, SyntheticEvent } from 'react'
import React, { useState } from 'react'
import { Select } from '@extend/zen'
import { ResolveServiceOrderProductReplacementForm } from './resolve-service-order-product-replacement-form'
import { ResolveServiceOrderGiftCardForm } from './resolve-service-order-gift-card-form'
import styles from './resolve-service-order-product-replacement-content.module.css'
import { claimDetailsAtom } from '../../../atoms/claim-details'
import { useAtomValue } from 'jotai'
import { ResolveServiceOrderShippingProtectionForm } from './resolve-service-order-shipping-protection-form'
import { LDFlag } from '../../../constants/ld-flags'
import { useFlags } from 'launchdarkly-react-client-sdk'

export type ReplacementModalProps = {
  contractId: string
  serviceOrderId: string
}

export const ResolveServiceOrderProductReplacementContent: FC<ReplacementModalProps> = ({
  contractId,
  serviceOrderId,
}) => {
  const claim = useAtomValue(claimDetailsAtom)
  const [fulfillmentMethod, setFulfillmentMethod] = useState('product_replacement')
  const {
    [LDFlag.ManualShippingProtectionFulfillment]: FF_MANUAL_SHIPPING_PROTECTION_FULFILLMENT,
  } = useFlags()

  const handleFulfillmentChange = (
    event: SyntheticEvent<HTMLSelectElement | HTMLButtonElement>,
  ): void => {
    const { value } = event.currentTarget
    setFulfillmentMethod(value)
  }

  return (
    <>
      <div className={styles['fulfillment-method-wrapper']}>
        <Select
          id="fulfillment-method"
          placeholder="SELECT"
          label="Fulfillment Resolution"
          onChange={handleFulfillmentChange}
          value={fulfillmentMethod}
        >
          <option value="product_replacement">Product Replacement</option>
          <option value="gift_card">Gift Card</option>
        </Select>
      </div>

      {fulfillmentMethod === 'product_replacement' &&
        claim.type === 'shipping_protection' &&
        FF_MANUAL_SHIPPING_PROTECTION_FULFILLMENT && (
          <ResolveServiceOrderShippingProtectionForm serviceOrderId={serviceOrderId} />
        )}

      {fulfillmentMethod === 'product_replacement' && claim.type !== 'shipping_protection' && (
        <ResolveServiceOrderProductReplacementForm
          contractId={contractId}
          serviceOrderId={serviceOrderId}
        />
      )}

      {fulfillmentMethod === 'gift_card' && (
        <ResolveServiceOrderGiftCardForm serviceOrderId={serviceOrderId} contractId={contractId} />
      )}
    </>
  )
}
