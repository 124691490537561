import type { FC } from 'react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { ToastColor, ToastDuration, useToaster } from '@extend/zen'
import { SearchBar } from '@helloextend/merchants-ui'
import { SplashScreen } from '@helloextend/component-commons'
import { useAtom, useAtomValue, useSetAtom } from 'jotai/react'
import { customLogger } from '@extend/client-helpers'
import { useExchangeTokenMutation } from '../queries/okta'
import {
  accountWithGrantsAtom,
  cachedAccountNameAtom,
  cachedRolesAtom,
  oktaAccessTokenAtom,
} from '../atoms/auth'
import type { AccountWithGrants, Grant } from '../types/okta'
import type { UserRoleV3 } from '../utils/get-user-roles'
import { isValidRoleV3, UserRoleMappingV3 } from '../utils/get-user-roles'
import styles from './account-selector.module.css'
import { loginRedirectPathAtom } from '../atoms/login-redirect'
import { RESET } from 'jotai/utils'

export const AccountSelector: FC = () => {
  const { toast } = useToaster()
  const accountWithGrants = useAtomValue(accountWithGrantsAtom)
  const [filteredGrants, setFilteredGrants] = useState(accountWithGrants)
  const [hasSearched, setHasSearched] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState<AccountWithGrants | null>(null)
  const selectedAccountGrants = useMemo(() => {
    return selectedAccount?.grants.filter((grant) => isValidRoleV3(grant.role)) || []
  }, [selectedAccount?.grants])
  const oktaIdentityToken = useAtomValue(oktaAccessTokenAtom)
  const { push } = useHistory()
  const { mutateAsync: exchangeToken, isLoading } = useExchangeTokenMutation()
  const [cachedRoles, setCachedRoles] = useAtom(cachedRolesAtom)
  const setCachedAccountName = useSetAtom(cachedAccountNameAtom)
  const [loginRedirectPath, setLoginRedirectPath] = useAtom(loginRedirectPathAtom)

  const shouldShowSearch = accountWithGrants.length > 5

  const handleRoleSelection = useCallback(
    async (grant: Grant, account: AccountWithGrants) => {
      try {
        const shopifyUrl = localStorage.getItem('shopifyUrl')
        const accessToken = await exchangeToken({ accessToken: oktaIdentityToken, grant })
        if (accessToken) {
          setSelectedAccount(account)
          setCachedAccountName(account?.name ?? '')

          if (cachedRoles[account.id] !== grant) {
            setCachedRoles({
              ...cachedRoles,
              [account.id]: grant,
            })
          }

          if (shopifyUrl) {
            localStorage.removeItem('shopifyUrl')
            push(`/shp/install?storeUrl=${shopifyUrl}`)
          } else {
            setLoginRedirectPath(RESET)
            push(loginRedirectPath || '/store')
          }
        }
      } catch (err: unknown) {
        if (err instanceof Error) {
          customLogger.error(err.message, { stack: err.stack })
        }
        toast({
          message: 'Something went wrong during login',
          toastColor: ToastColor.red,
          toastDuration: ToastDuration.short,
        })
      } finally {
        localStorage.removeItem('accountId')
        localStorage.removeItem('role')
      }
    },
    [
      exchangeToken,
      oktaIdentityToken,
      setSelectedAccount,
      setCachedAccountName,
      cachedRoles,
      setCachedRoles,
      push,
      setLoginRedirectPath,
      toast,
    ],
  )

  /**
   * This useEffect is used when a role and accountId are provided as qs params
   * ie: SEs use this method in the demo environment to quickly switch between accounts/roles
   * https://helloextend.atlassian.net/browse/MEXP-2148
   */
  useEffect(() => {
    const selectedAccountId = localStorage.getItem('accountId')
    const selectedRole = localStorage.getItem('role')

    // This should only run if both accountId and role are provided
    const foundAccount = accountWithGrants.find((account) => account.id === selectedAccountId)
    if (foundAccount) {
      const foundGrant = foundAccount?.grants.find((grant) => grant.role === selectedRole)

      if (foundGrant) {
        handleRoleSelection(foundGrant, foundAccount)
      }
    }
  }, [accountWithGrants, handleRoleSelection, setSelectedAccount])

  const handleAccountSelection = useCallback(
    (account: AccountWithGrants) => {
      const cachedRole = cachedRoles[account.id]

      // If the role is cached for the selected account, we can skip the role selection step
      if (cachedRole) {
        handleRoleSelection(cachedRole, account)
        return
      }

      // If the user has access to only one role, we can skip the role selection step
      if (account.grants.length === 1) {
        handleRoleSelection(account.grants[0], account)
      } else {
        // Otherwise, set the account and have the user select a role
        setSelectedAccount(account)
      }
    },
    [handleRoleSelection, cachedRoles],
  )

  // Display an error when the user has access to no accounts
  // Or automatically select the account if the user has access to only one account
  useEffect(() => {
    if (accountWithGrants.length === 0 || !oktaIdentityToken) {
      toast({
        message: 'You do not have access to this application',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
      push('/login')
    } else if (accountWithGrants.length === 1) {
      handleAccountSelection(accountWithGrants[0])
    }
  }, [accountWithGrants, oktaIdentityToken, push, handleAccountSelection, toast])

  const handleSearch = (_key: string, value: string): void => {
    setHasSearched(true)
    setFilteredGrants(
      accountWithGrants.filter(({ name }) => name?.toLowerCase().includes(value.toLowerCase())),
    )
  }

  if (isLoading) {
    return <SplashScreen data-cy="splash-screen" />
  }

  return (
    <div className={styles['account-selector-container']}>
      {!selectedAccount && (
        <div className={styles['account-selectors']}>
          <div className={styles['account-selector-header']}>Select An Account</div>
          {shouldShowSearch && (
            <SearchBar placeholder="Account Name" onSubmit={handleSearch} id="accountSearch" />
          )}
          <div className="flex flex-col">
            {filteredGrants.map((account) => {
              return (
                <button
                  className={styles['account-selector-item']}
                  key={`${account.name}-${account.id}`}
                  onClick={() => handleAccountSelection(account)}
                  type="button"
                >
                  {account.name}
                </button>
              )
            })}
            {hasSearched && filteredGrants.length === 0 && <div>No account found</div>}
          </div>
        </div>
      )}
      {selectedAccount && (
        <div className={styles['account-selectors']}>
          <div className={styles['account-selector-header']}>Select A Role</div>
          <div className="flex flex-col">
            {selectedAccountGrants.map((grant) => {
              const roleName = UserRoleMappingV3[grant.role as UserRoleV3]
              return (
                <button
                  className={styles['account-selector-item']}
                  key={`${grant.ern}-${grant.role}`}
                  onClick={() => handleRoleSelection(grant, selectedAccount)}
                  type="button"
                >
                  {roleName}
                </button>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}
