// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1_oCYDf5MD5_lpFZaOIkbx {\n    display: grid;\n    gap: 16px;\n}\n\n._1_oCYDf5MD5_lpFZaOIkbx label, ._1_oCYDf5MD5_lpFZaOIkbx div {\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    overflow: hidden;\n}\n\n@media (min-width: 768px) {\n    ._1_oCYDf5MD5_lpFZaOIkbx {\n        grid-template-columns: repeat(3, 1fr);\n    }\n\n    ._1_oCYDf5MD5_lpFZaOIkbx svg {\n        /*this stops flex:grid from overlapping the copy and information icons within data properties*/\n        margin-right: 4px;\n    }\n}\n\n@media (min-width: 992px) {\n    ._1_oCYDf5MD5_lpFZaOIkbx {\n        grid-template-columns: repeat(4, 1fr);\n    }\n}\n\n@media (min-width: 1200px) {\n    ._1_oCYDf5MD5_lpFZaOIkbx {\n        grid-template-columns: repeat(5, 1fr);\n    }\n}", ""]);
// Exports
exports.locals = {
	"grid": "_1_oCYDf5MD5_lpFZaOIkbx"
};
module.exports = exports;
