// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3CqwCM3DMaO3wsSaYYmv7Y {\n    display: flex;\n    align-items: center;\n    margin-top: 4px;\n    gap: 10px;\n}\n\n.vtr9E0ZGijUCz4D3GyETr {\n    width: 24px;\n    height: 24px;\n    border-radius: 4px;\n    box-shadow: 0 0 0 1px var(--neutral-opacity-50);\n}\n\n._3eltfCTteNVxrF6XjKNBvZ {\n    margin: 0;\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "_3CqwCM3DMaO3wsSaYYmv7Y",
	"preview": "vtr9E0ZGijUCz4D3GyETr",
	"text": "_3eltfCTteNVxrF6XjKNBvZ"
};
module.exports = exports;
