// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2xxFukYpeAwfj5JAhStyoj:not(:last-child) {\n    margin-bottom: 1rem;\n}\n\n._2xxFukYpeAwfj5JAhStyoj a {\n    color: inherit;\n}\n\n._2xxFukYpeAwfj5JAhStyoj a:visited {\n    color: inherit;\n}\n\n._3kQYoyviMRl9BDYTLNszC4 {\n    margin-top: 1rem;\n    margin-bottom: 1rem;\n}\n\n._21gSoV8krScztyaHAn3wly {\n    margin: 0;\n}\n\n._2qEkGNvZd3pp5iloB35GzR {\n    margin-top: 1rem;\n}\n\n._2qEkGNvZd3pp5iloB35GzR:not(:last-child) {\n    margin-bottom: 1rem;\n}\n\n._2E-1Vczw9pUSPpLgPt68oL {\n    font-weight: bold;\n}\n\n.ZStqCR7xz42cpXXo_cU6D  {\n    margin: 0;\n}\n\n.ZStqCR7xz42cpXXo_cU6D svg {\n    margin: 0.125rem;\n    vertical-align: bottom;\n}\n", ""]);
// Exports
exports.locals = {
	"inline-alert-wrapper": "_2xxFukYpeAwfj5JAhStyoj",
	"container": "_3kQYoyviMRl9BDYTLNszC4",
	"text": "_21gSoV8krScztyaHAn3wly",
	"contract-id-container": "_2qEkGNvZd3pp5iloB35GzR",
	"contract-id-header": "_2E-1Vczw9pUSPpLgPt68oL",
	"contract-id-wrapper": "ZStqCR7xz42cpXXo_cU6D"
};
module.exports = exports;
