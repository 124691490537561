// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3aZEKPU3xAsN1bfI7DzZF7 {\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n\n.P6giPyiF0VtKsMZdwNeH {\n    display: flex;\n    margin-bottom: 28px;\n}\n\n._1hbXZgxtT7kMOHV-u8ihqG {\n    border: 1px solid var(--neutral-300);\n    margin-top: 32px;\n    padding: 20px 40px;\n}\n\n._1tSwhDDlDHb-4pGpeVBQ1n {\n    display: flex;\n    flex-direction: column;\n    width: 25%;\n}\n\n._1vi3Z27dNvR0T0R5udonZM {\n    color: var(--neutral-600);\n    font-weight: bold;\n    font-size: 14px;\n    margin-bottom: 10px;\n}\n", ""]);
// Exports
exports.locals = {
	"value": "_3aZEKPU3xAsN1bfI7DzZF7",
	"row": "P6giPyiF0VtKsMZdwNeH",
	"details": "_1hbXZgxtT7kMOHV-u8ihqG",
	"detail": "_1tSwhDDlDHb-4pGpeVBQ1n",
	"label": "_1vi3Z27dNvR0T0R5udonZM"
};
module.exports = exports;
