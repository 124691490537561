import { COLOR, Dots } from '@extend/zen'
import type { FC } from 'react'
import React, { useEffect } from 'react'
import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { ClaimType } from '@helloextend/extend-api-client'
import { useAtom, useSetAtom } from 'jotai/react'
import { ShippingProtectionClaimView } from '../shipping-protection-claim-view'
import { ErrorPage } from '../../../components/error-page'
import { ClaimHeader } from './claim-header'
import { ProductProtectionClaimView } from '../product-protection-claim-view'
import { useGetInsuranceClaimQuery } from '../../../queries/claims'
import { useGetServiceOrdersByClaimQuery } from '../../../queries/service-orders'
import { useGetContractQuery } from '../../../queries/contract'
import { CONTRACTS_API_VERSION_FEB_2022 } from '../../../constants/const'
import { contractDetailsAtom } from '../../../atoms/contract-details'
import { claimDetailsAtom } from '../../../atoms/claim-details'
import { serviceOrdersAtom } from '../../../atoms/service-orders-atom'
import { mapCommonClaimAttributes } from '../../../utils/map-unhandled-claims-service-items-for-claims-table'
import { ExternalClaimView } from '../external-claim-view'
import { MerchantContract } from '../../../types/merchant-contract'
import { MerchantClaim } from '../../../types/claims'

export const Claim: FC = () => {
  const { url } = useRouteMatch()
  const { claimId } = useParams<{ claimId: string }>()
  const [contract, setContractAtom] = useAtom(contractDetailsAtom)
  const [claim, setClaimAtom] = useAtom(claimDetailsAtom)
  const setServiceOrdersAtom = useSetAtom(serviceOrdersAtom)

  const {
    data: claimData,
    isInitialLoading: isClaimLoading,
    isError: isClaimError,
  } = useGetInsuranceClaimQuery({ claimId })
  const contractId = claimData?.contractId || ''

  const { data: serviceOrdersData } = useGetServiceOrdersByClaimQuery({ claimId })

  const isShippingProtectionClaim = Boolean(claimData?.type === ClaimType.SHIPPING_PROTECTION)
  const isExternalClaim = Boolean(claimData?.type === ClaimType.SHIPPING_RESOLUTION)

  const {
    data: contractData,
    isLoading: isContractLoading,
    isError: isContractError,
    error: contractError,
  } = useGetContractQuery({
    contractId,
    apiVersion: CONTRACTS_API_VERSION_FEB_2022,
    enabled: Boolean(contractId) && !isExternalClaim,
  })

  useEffect(() => {
    if (contractData) {
      setContractAtom(contractData)
    } else {
      setContractAtom({} as MerchantContract)
    }
  }, [setContractAtom, contractData])

  useEffect(() => {
    if (claimData) {
      setClaimAtom(mapCommonClaimAttributes(claimData))
    } else {
      setClaimAtom({} as MerchantClaim)
    }
  }, [setClaimAtom, claimData])

  useEffect(() => {
    setServiceOrdersAtom(serviceOrdersData || [])
  }, [setServiceOrdersAtom, serviceOrdersData])

  // Show loading state if the claim or contract is still loading
  if (isClaimLoading || (!isExternalClaim && isContractLoading)) {
    return (
      <div className="width-100 height-100 flex justify-content-center align-items-center">
        <Dots color={COLOR.BLUE[800]} data-cy="spinner" />
      </div>
    )
  }

  // Show error page if there is an error fetching the claim or contract
  if (isClaimError || (!isExternalClaim && isContractError)) {
    return <ErrorPage error={contractError} data-cy="error-page" />
  }

  // Wait until the atom values are set before rendering the view
  if (!Object.keys(claim).length || (!isExternalClaim && !Object.keys(contract).length)) {
    return (
      <div className="width-100 height-100 flex justify-content-center align-items-center">
        <Dots color={COLOR.BLUE[800]} data-cy="spinner" />
      </div>
    )
  }

  let view = <ProductProtectionClaimView data-cy="product-protection-claim-view" />
  if (isShippingProtectionClaim) {
    view = <ShippingProtectionClaimView data-cy="shipping-protection-claim-view" />
  } else if (isExternalClaim) {
    view = <ExternalClaimView data-cy="external-claim-view" />
  }

  return (
    <>
      <ClaimHeader contract={contract} claim={claim} />
      <Switch>
        <Route exact path="/store/claims/:claimId">
          {view}
        </Route>
        <Route exact path={`${url}/history`}>
          <div>Coming soon!</div>
        </Route>
        <Redirect to="/404" />
      </Switch>
    </>
  )
}
