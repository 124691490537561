// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Uoa3e6_gDB5AglD-It_sf {\n    align-items: center;\n    border-style: solid;\n    border-color: var(--neutral-300);\n    border-radius: 4px;\n    border-width: 1px;\n    background-color: var(--white);\n    display: flex;\n    font-size: 14px;\n    gap: 16px;\n    line-height: 19px;\n    padding: 14px;\n    color: var(--black);\n}\n\n.Uoa3e6_gDB5AglD-It_sf:hover {\n    background-color: var(--neutral-100);\n    border-color: var(--blue-800);\n    text-decoration: none;\n}\n\n._31-qr3OsPijuIkmFhOt80p {\n    border-radius: 4px;\n}", ""]);
// Exports
exports.locals = {
	"button": "Uoa3e6_gDB5AglD-It_sf",
	"help": "_31-qr3OsPijuIkmFhOt80p"
};
module.exports = exports;
