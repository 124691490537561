// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1JQ-kAx-ve7ECyLxjd6AFW {\n    display: flex;\n    flex-flow: row wrap;\n}\n\n.fimFGXWyFGXh5MS6hJdO1 {\n    flex: 1 0 100%;\n}\n\n._2oQ78-_Vv1ygRYfz42TH3v {\n    font-size: 18px;\n    margin-bottom: 8px;\n}\n\n._1skEAcjOHS_ShGCBBHm97K {\n    flex-grow: 1;\n}\n\n._3Fh7rVLunxhH7WdhJ0KBKD {\n    margin-top: 8px;\n    flex: 1 0 100%;\n}\n/* universal selector for the list items mapped */\n/* that proceeds an adjacent element. */\n/* Source: https://alistapart.com/article/axiomatic-css-and-lobotomized-owls/ */\n._3Fh7rVLunxhH7WdhJ0KBKD > * + * {\n    margin-top: 16px;\n}\n\n@media (min-width: 1025px) {\n    .fimFGXWyFGXh5MS6hJdO1 {\n        flex: 0 1 348px;\n        margin-right: 46px;\n    }\n\n    ._3Fh7rVLunxhH7WdhJ0KBKD {\n        margin-top: 0;\n        flex: 1 0 50%;\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"flex-wrapper": "_1JQ-kAx-ve7ECyLxjd6AFW",
	"header": "fimFGXWyFGXh5MS6hJdO1",
	"header-text": "_2oQ78-_Vv1ygRYfz42TH3v",
	"details": "_1skEAcjOHS_ShGCBBHm97K",
	"details-column": "_3Fh7rVLunxhH7WdhJ0KBKD"
};
module.exports = exports;
