// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2Onfn7SwgE1dstVSSlBBzI {\n    padding-bottom: 12px;\n    width: 541px;\n}\n\n._2x8DjoLrltKWMLFnHXc5Ec {\n    padding-right: 24px;\n    padding-top: 24px;\n}\n", ""]);
// Exports
exports.locals = {
	"input": "_2Onfn7SwgE1dstVSSlBBzI",
	"button": "_2x8DjoLrltKWMLFnHXc5Ec"
};
module.exports = exports;
