import React, { useCallback, useState } from 'react'
import { useAtom, useSetAtom } from 'jotai/react'
import { Input, InputType, OpenInNew } from '@extend/zen'
import { CreateContractsModal } from './create-contracts-modal'
import styles from './create-contracts-modal-order-search.module.css'
import { useLazyGetProductQuery } from '../../../../queries/products'
import {
  modalStepAtom,
  planDetailsAtom,
  productAtom,
  productDetailsAtom,
} from '../../../../atoms/create-contracts-modal'
import { CreateContractsModalStep } from '../../../../constants/create-contracts-modal-step'

export const CreateContractsModalProductsSearch = () => {
  const [product, setProduct] = useAtom(productAtom)
  const setModalStep = useSetAtom(modalStepAtom)
  const setPlanDetails = useSetAtom(planDetailsAtom)
  const [referenceId, setReferenceId] = useState(product?.referenceId || '')
  const [productNotFound, setProductNotFound] = useState(false)
  const [errorMsg, setErrorMsg] = React.useState<string | null>(null)
  const setProductDetails = useSetAtom(productDetailsAtom)

  const { mutateAsync: getProduct, isLoading } = useLazyGetProductQuery()

  const handleNext = useCallback(
    async (event?: React.FormEvent) => {
      if (event) {
        event.preventDefault()
      }

      if (!referenceId) {
        return
      }

      // if the user navigated back to this step and the product is the same skip the API call
      if (referenceId === product?.referenceId) {
        setModalStep(CreateContractsModalStep.ProductDetails)
        return
      }

      // this is either the first time the user is entering the product reference ID
      // or they have navigated back and updated the product reference ID
      setErrorMsg(null)
      try {
        const foundProduct = await getProduct(referenceId)
        if (foundProduct) {
          setProductNotFound(false)
          setProduct(foundProduct)
          setProductDetails({})
          setModalStep(CreateContractsModalStep.ProductDetails)
        } else {
          setProductNotFound(true)
        }
      } catch (e) {
        setErrorMsg('An unexpected error occurred. Please try again later.')
      }
    },
    [
      referenceId,
      product,
      setModalStep,
      setErrorMsg,
      getProduct,
      setProductNotFound,
      setProduct,
      setProductDetails,
    ],
  )

  const handleOnChange = useCallback((event) => {
    setReferenceId(event.target.value)
    setProductNotFound(false)
    setErrorMsg(null)
    // In case the user navigated back to this step and changed the product,
    // clear the plan details since those might not be valid anymore
    setPlanDetails(undefined)
  }, [])

  return (
    <CreateContractsModal
      subHeading="Find the product reference ID in your category."
      primaryButton={{
        text: 'Next',
        onClick: handleNext,
        isDisabled: !referenceId || productNotFound || isLoading,
        isProcessing: isLoading,
      }}
      secondaryButton={{
        text: 'Back',
        onClick: () => {
          setModalStep(CreateContractsModalStep.OrderDetails)
          // Clear the error message if the user navigates back
          setErrorMsg(null)
        },
      }}
      altButton={{
        text: 'View Products',
        icon: OpenInNew,
        onClick: () => {
          window.open('/store/products', '_blank')
        },
      }}
      errorMsg={errorMsg}
    >
      <form onSubmit={handleNext}>
        <div className={styles.input}>
          <Input
            type={InputType.text}
            label="Reference ID"
            errorFeedback="Invalid ID"
            isError={productNotFound}
            id="reference-id"
            data-cy="reference-id-input"
            onChange={handleOnChange}
            value={referenceId}
            autoFocus
          />
        </div>
        {/* Submit button is required to allow for submission via Enter key */}
        <button type="submit" style={{ display: 'none' }} />
      </form>
    </CreateContractsModal>
  )
}
