import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { EXTEND_API_HOST } from '@helloextend/client-constants'
import type { clientTypes } from '@extend-entitlements-mgmt/client'
import { useCallback } from 'react'
import { useAtomValue } from 'jotai/react'
import { v3AccessTokenAtom } from '../atoms/auth'

const ENTITLEMENTS_CACHE_KEY = 'Entitlements'
const ENTITLEMENTS_VERSION: clientTypes.EntitlementVersions = 'latest'

export function useSearchEntitlementsQuery({
  contractId,
  enabled,
}: {
  contractId: string
  enabled?: boolean
}): UseQueryResult<
  clientTypes.SearchEntitlementsResponseVersionMap[typeof ENTITLEMENTS_VERSION],
  Error
> {
  const accessToken = useAtomValue(v3AccessTokenAtom) || ''

  const queryFn = useCallback(async () => {
    const response = await fetch(
      `https://${EXTEND_API_HOST}/entitlements-management/entitlements?contractId=${contractId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: `application/json; version=${ENTITLEMENTS_VERSION}`,
          'x-extend-access-token': accessToken,
        },
        method: 'GET',
      },
    )
    if (!response.ok) {
      throw new Error('Unable to get entitlements')
    }
    return response.json()
  }, [contractId, accessToken])

  return useQuery({
    queryKey: [ENTITLEMENTS_CACHE_KEY, { id: contractId }],
    queryFn,
    enabled,
  })
}
