// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3j_L1lrtzyKmYv367EUnDO {\n    display: grid;\n    grid-template-rows: repeat(7, 1fr);\n    gap: 8px\n}\n\n._7qhycpSSBEgiBNITBWnoL {\n    display: grid;\n}\n\n.lBQ0FJ3Sb2hPdrD35YUJY {\n    display: grid;\n    grid: 1fr / 1fr 1fr;\n    gap: 16px;\n}\n\n._2nHvgywuPO1qsPTgj80wle {\n    display: grid;\n    grid: 1fr / 3fr 1fr;\n    gap: 16px;\n}\n\n._1LkHyzzJR5OIfh-er8DsHd {\n    display: grid;\n    grid: 1fr / 3fr 1fr 2fr;\n    gap: 16px;\n}\n\n._2U36g9YQawNIfXq4EflPeX {\n    display: grid;\n    grid-template: 1fr / 1fr 1fr;\n}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "_3j_L1lrtzyKmYv367EUnDO",
	"heading": "_7qhycpSSBEgiBNITBWnoL",
	"email-phone-row": "lBQ0FJ3Sb2hPdrD35YUJY",
	"addressline1-row": "_2nHvgywuPO1qsPTgj80wle",
	"addressline2-row": "_1LkHyzzJR5OIfh-er8DsHd",
	"country-picker": "_2U36g9YQawNIfXq4EflPeX"
};
module.exports = exports;
