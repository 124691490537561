// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2r_TqSmLOGGpsZhCPYs2EP {\n    width: 208px;\n    height: 64px;\n    object-fit: contain;\n}\n\n._2i_Q2J9lPt6Sqyb1ybmI4e {\n    line-height: 32px;\n    font-size: 24px;\n}\n\n._3GzGr6eVqc-wa_A8lIeh5K {\n    padding-left: 20px;\n    margin-bottom: 30px;\n    text-align: left;\n}\n\n.h13dnwHfNYv_IrC4fSyxu {\n    font-size: 20px;\n}\n\n._1nzR29OB6lckhtQMkagzyQ {\n    font-size: 12px;\n    padding: 0 32px;\n    margin: 0;\n}\n\n._324sEJD4bCk4Zjco0i_L4W {\n    margin: 16px 0 18px 0;\n    padding: 5px 21px;\n    font-weight: 700;\n    font-size: 16px;\n    line-height: 24px;\n    border-radius: 4px;\n}\n\n._3PBgBjETpf00lIwKPTPnJD {\n    margin: 0;\n    font-size: 8px;\n}\n\n.FH4N2aTMksTl1ZpoOvPyY {\n    height: 12px;\n}\n\n._2Sg7iwASpdymFAkcNK0MfY {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    box-sizing: border-box;\n    padding: 14px 50px 32px 50px;\n    text-align: center;\n    width: 426px;\n}\n\n.XPfKVa7pjJqjGa5nW0hKx {\n    padding: 0 55px 32px 24px;\n    text-align: left;\n    font-size: 11px;\n}\n\n._2f76n0yowpevbUN9jEodhB {\n    margin-bottom: 32px;\n    font-size: 11px;\n    width: 100%;\n}\n\n._1qo6leXYeOpFJ12Afhpuek {\n    color: var(--neutral-800);\n    text-decoration: underline;\n}\n\n._1M9y2H33hJ_CTRHbo7aFm7 {\n    margin: 0;\n    font-size: 11px;\n    line-height: 27px;\n    color: var(--neutral-800);\n}\n\n._2EpQrscnj4J3_8869GQ2yN {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    box-sizing: border-box;\n    width: 100%;\n    margin-bottom: 8px;\n}\n\n._3meYqZkk62MVcvpNg_o7_k {\n    font-size: 12px;\n}\n\n._3e5TCNdP2xsi1m8errSXDl {\n    box-sizing: border-box;\n    padding: 61px;\n    background-color: var(--neutral-100);\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n}\n\n._3e5TCNdP2xsi1m8errSXDl *:not(._2i_Q2J9lPt6Sqyb1ybmI4e):not(.h13dnwHfNYv_IrC4fSyxu) {\n    font-family: attr(data-font-family);\n}\n", ""]);
// Exports
exports.locals = {
	"logo": "_2r_TqSmLOGGpsZhCPYs2EP",
	"header": "_2i_Q2J9lPt6Sqyb1ybmI4e",
	"mock-data": "_3GzGr6eVqc-wa_A8lIeh5K",
	"subheader": "h13dnwHfNYv_IrC4fSyxu",
	"primary-copy": "_1nzR29OB6lckhtQMkagzyQ",
	"mock-button": "_324sEJD4bCk4Zjco0i_L4W",
	"extra-small": "_3PBgBjETpf00lIwKPTPnJD",
	"extend": "FH4N2aTMksTl1ZpoOvPyY",
	"inner": "_2Sg7iwASpdymFAkcNK0MfY",
	"whats-included": "XPfKVa7pjJqjGa5nW0hKx",
	"links": "_2f76n0yowpevbUN9jEodhB",
	"link": "_1qo6leXYeOpFJ12Afhpuek",
	"footer": "_1M9y2H33hJ_CTRHbo7aFm7",
	"row": "_2EpQrscnj4J3_8869GQ2yN",
	"col": "_3meYqZkk62MVcvpNg_o7_k",
	"container": "_3e5TCNdP2xsi1m8errSXDl"
};
module.exports = exports;
