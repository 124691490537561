import type { MerchantsEnterpriseUserRole } from '../types/users'

export const getUserRoleLabel = (
  userRole: MerchantsEnterpriseUserRole | string | null | undefined,
): string => {
  switch (userRole) {
    case 'MerchantPortalAdmin':
      return 'Admin'
    case 'MerchantApiManager':
      return 'API Manager'
    case 'MerchantClaimsAgent':
      return 'Claims Manager'
    case 'MerchantTransactionManager':
      return 'Transaction Manager'
    case 'MerchantPortalViewOnly':
      return 'View Only'
    default:
      return 'User'
  }
}

type RoleDiff = {
  toAdd: string[]
  toRemove: string[]
}
export function diffRoles(newRoles: string[], previousRoles: string[]): RoleDiff {
  return {
    toAdd: newRoles.filter((role) => !previousRoles.includes(role)),
    toRemove: previousRoles.filter((role) => !newRoles.includes(role)),
  }
}

export type RoleOrg = {
  role: string
  orgIds: string[]
}
type RoleOrgDiff = {
  toAdd: RoleOrg[]
  toRemove: RoleOrg[]
}
export function diffRolesR3(newRoleOrgs: RoleOrg[], previousRoleOrgs: RoleOrg[]): RoleOrgDiff {
  const toAdd = []
  const toRemove = []

  // Determine new roleOrgs for grants
  for (const roleOrg of newRoleOrgs) {
    const matchingRoleOrg = previousRoleOrgs.find((ro) => ro.role === roleOrg.role)

    if (matchingRoleOrg) {
      // Get new orgs for particular role
      const newOrgs = roleOrg.orgIds.filter((id) => !matchingRoleOrg.orgIds.includes(id))
      if (newOrgs.length > 0) {
        toAdd.push({ role: roleOrg.role, orgIds: newOrgs })
      }
    } else {
      // The role is new, so it doesn't need to be filtered
      toAdd.push(roleOrg)
    }
  }

  // Determine roleOrgs to be removed
  for (const roleOrg of previousRoleOrgs) {
    const matchingRoleOrg = newRoleOrgs.find((ro) => ro.role === roleOrg.role)

    if (matchingRoleOrg) {
      // Get old orgs for particular role
      const oldOrgs = roleOrg.orgIds.filter((id) => !matchingRoleOrg.orgIds.includes(id))
      if (oldOrgs.length > 0) {
        toRemove.push({ role: roleOrg.role, orgIds: oldOrgs })
      }
    } else {
      // The role is new, so it doesn't need to be filtered
      toRemove.push(roleOrg)
    }
  }

  return {
    toAdd,
    toRemove,
  }
}
