// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".gYhLSeHXD7anV3m2PW7lA {\n    display: flex;\n    flex-flow: row wrap;\n}\n\n._1hJOZmYImmVp36rh-HXAZ3 {\n    flex: 1 0 100%;\n}\n\n._1b2IUKunxtcSPkxLpBDSnx {\n    width: 140px;\n    height: 140px;\n}\n\n._3WSn2Xb1k_H9EiLH7sMAVO {\n    height: 100%;\n    object-fit: cover;\n    object-position: center center;\n    width: 100%;\n}\n\n._3mToTy1YObWwPkeNfQo_kd {\n    flex-grow: 1;\n}\n\n.Ldl2n72MINBVbtF0r714y {\n    margin-top: 8px;\n    flex: 1 0 100%;\n}\n\n/* universal selector for the list items mapped */\n/* that proceeds an adjacent element. */\n/* Source: https://alistapart.com/article/axiomatic-css-and-lobotomized-owls/ */\n.Ldl2n72MINBVbtF0r714y > * + * {\n    margin-top: 16px;\n}\n\n.BzFrdEHLuu0Ca3Vb_8LNH {\n    display: flex;\n    flex-flow: column wrap;\n    margin-top: 32px;\n    align-items: baseline;\n}\n\n.BzFrdEHLuu0Ca3Vb_8LNH > * + * {\n    margin: 16px 0 0 0;\n}\n\n@media (min-width: 1025px) {\n    ._1hJOZmYImmVp36rh-HXAZ3 {\n        flex: 0 1;\n        margin-right: 46px;\n    }\n\n    .Ldl2n72MINBVbtF0r714y {\n        margin-top: 0;\n        flex: 1 0 50%;\n    }\n\n    .BzFrdEHLuu0Ca3Vb_8LNH {\n        flex-flow: row wrap;\n    }\n\n    .BzFrdEHLuu0Ca3Vb_8LNH > * + * {\n        margin: 0 0 0 16px;\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"flex-wrapper": "gYhLSeHXD7anV3m2PW7lA",
	"figure": "_1hJOZmYImmVp36rh-HXAZ3",
	"image-wrapper": "_1b2IUKunxtcSPkxLpBDSnx",
	"image": "_3WSn2Xb1k_H9EiLH7sMAVO",
	"details": "_3mToTy1YObWwPkeNfQo_kd",
	"details-column": "Ldl2n72MINBVbtF0r714y",
	"buttons": "BzFrdEHLuu0Ca3Vb_8LNH"
};
module.exports = exports;
