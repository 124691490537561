// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "strong {\n    font-weight: 700;\n}\n\n._1_ivZDARkhcYOA0DGwjeD_ {\n    display: flex;\n    gap: 10px;\n    align-items: end;\n}\n\n._1_ivZDARkhcYOA0DGwjeD_ > div:first-child {\n    width: 100%;\n}\n\n._1_ivZDARkhcYOA0DGwjeD_ > button {\n    align-self: unset;\n}\n\np {\n    font-weight: 100;\n    line-height: 20px;\n}\n\n.QO2o5yI5oVaNr2JG_A3I0 {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n}", ""]);
// Exports
exports.locals = {
	"input-wrapper": "_1_ivZDARkhcYOA0DGwjeD_",
	"wrapper": "QO2o5yI5oVaNr2JG_A3I0"
};
module.exports = exports;
