import type { FC } from 'react'
import React, { useState } from 'react'
import { Switch, ToastColor, ToastDuration, useToaster } from '@extend/zen'
import { useAtomValue } from 'jotai/react'
import { useUpdateProductMutation } from '../../queries/products'
import { getActiveStoreIdAtom } from '../../atoms/stores'
import { usePermissions } from '../../hooks/use-permissions'
import { Permission } from '../../lib/permissions'
import styles from './product-offer-status.module.css'
import { safeDecodeURIComponent } from '../../utils/encoding'

type ProductOfferStatusProps = {
  title: string
  referenceId: string
  enabled: boolean
  isDisabled?: boolean
}

export const ProductOfferStatus: FC<ProductOfferStatusProps> = ({
  title,
  referenceId,
  enabled,
  isDisabled,
}) => {
  const { toast } = useToaster()
  const storeId = useAtomValue(getActiveStoreIdAtom)
  const [enabledStatus, setEnabledStatus] = useState(enabled)
  const { mutateAsync: updateProduct } = useUpdateProductMutation()
  const { hasPermission } = usePermissions()
  const hasFullProductsPermission = hasPermission(Permission.ProductsFullAccess)

  const editProduct = async (newValue: boolean): Promise<void> => {
    setEnabledStatus(newValue)
    try {
      await updateProduct({
        storeId,
        productId: referenceId,
        version: 'latest',
        data: { enabled: newValue },
      })
      toast({
        message: newValue ? 'Offer display turned on' : 'Offer display turned off',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.blue,
      })
    } catch (err) {
      toast({
        message: 'Offer display could not be toggled. Please try again later',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
      setEnabledStatus(enabled)
    }
  }

  if (!safeDecodeURIComponent(referenceId)) {
    toast({
      message: `Unable to properly display product: ${title}`,
      toastColor: ToastColor.red,
      toastDuration: ToastDuration.short,
    })

    return (
      <div>
        <p>Invalid URI for Product: {title}</p>
      </div>
    )
  }

  // TODO:[MEXP-1320] move isDisabled logic in ProductOfferStatus instead of passing it in as a prop
  return (
    <div className={!hasFullProductsPermission ? styles['read-only'] : ''}>
      <Switch
        isOn={enabledStatus}
        onChange={() => editProduct(!enabledStatus)}
        id={`${referenceId}-enabled-status`}
        isDisabled={isDisabled}
        disabledTooltip="Offers can't be displayed because this product is not warrantable"
      />
    </div>
  )
}
