// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._32_ZErcV66rTynQOgp2SzT {\n    display: flex;\n    flex-direction: column;\n    box-sizing: border-box;\n    position: relative;\n    width: 1108px;\n    padding-bottom: 52px;\n}\n\n._1CelL-ZuZE6KOQ8QzIlc_N {\n    font-size: 18px;\n    color: var(--blue-800);\n    cursor: pointer;\n    white-space: nowrap;\n}\n", ""]);
// Exports
exports.locals = {
	"settings": "_32_ZErcV66rTynQOgp2SzT",
	"shopify": "_1CelL-ZuZE6KOQ8QzIlc_N"
};
module.exports = exports;
