// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".S3r9EoqA68u0X4nst-inb {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n._12f5422Aj3AdeU9Suhw6PX {\n    max-width: 816px;\n    margin: auto;\n}\n\n._12f5422Aj3AdeU9Suhw6PX h2 {\n    text-align: center;\n    margin: 0 auto 32px auto;\n    width: 493px;\n}\n", ""]);
// Exports
exports.locals = {
	"loading": "S3r9EoqA68u0X4nst-inb",
	"wrapper": "_12f5422Aj3AdeU9Suhw6PX"
};
module.exports = exports;
