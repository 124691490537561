// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".pez17iFTkWtEEwymJrGUj {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}", ""]);
// Exports
exports.locals = {
	"loading-container": "pez17iFTkWtEEwymJrGUj"
};
module.exports = exports;
