// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2LqO53NgkWjIzFqm7cERr8 {\n    display: flex;\n    align-items: center;\n    padding-top: 8px;\n}\n\n._2qjvGgltaCFpzmlcTPH3Bm {\n    margin-right: 12px;\n}\n\n._3Vh6BYHC2_LU9lKu8YDKcP:hover {\n    text-decoration: underline;\n}\n", ""]);
// Exports
exports.locals = {
	"info": "_2LqO53NgkWjIzFqm7cERr8",
	"value": "_2qjvGgltaCFpzmlcTPH3Bm",
	"link": "_3Vh6BYHC2_LU9lKu8YDKcP"
};
module.exports = exports;
