// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3yNDKN8Yta87tww5XuLka3 {\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n    min-width: 440px;\n}\n\n._2RH85xMRBFiN54bqxtCJPT {\n    border: 1px solid var(--neutral-200);\n    margin-top: 32px;\n    padding: 20px 40px 40px 40px;\n    min-width: 988px;\n}\n\n._8tBVuRMmT8hElnmCsFIDI {\n    display: flex;\n    flex-direction: column;\n    border: 1px solid var(--neutral-200);\n    margin-top: 32px;\n    padding: 20px 40px 40px 40px;\n    min-width: 988px;\n}\n\n._3TiVNvzx7XdLBJP74nec0S {\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n    width: 100%;\n}\n\n._3bq4_qqBG7k1XcbhjHXUZQ {\n    font-weight: 700;\n    padding-bottom: 10px;\n    padding-top: 32px;\n}\n\n._1L5rScA3w2ksPqycXzvKUs {\n    font-weight: 800;\n    padding-top: 28px;\n    font-size: 14px;\n    line-height: 22px;\n    align-items: center;\n    display: flex;\n}\n\n._1jcVRpRARXE9ad50lXS0HG {\n    width: 262px;\n    margin-top: 24px;\n}\n\n._2lWDlnn5P0YMWV3rhdkbjK {\n    width: 262px;\n    margin-top: 24px;\n}\n\n.nI4TH9fIIz3PEbcf4ai-q {\n    display: flex;\n    margin-top: 24px;\n}\n\n._3m_PDGYNfQA2FE8lobI6jE {\n    width: 262px;\n    margin-right: 16px;\n}\n\n._1M6m7A3PZ5LEC7OjeGr7qs {\n    margin-top: 32px;\n}\n", ""]);
// Exports
exports.locals = {
	"email-fields": "_3yNDKN8Yta87tww5XuLka3",
	"leads-emails": "_2RH85xMRBFiN54bqxtCJPT",
	"customization": "_8tBVuRMmT8hElnmCsFIDI",
	"description": "_3TiVNvzx7XdLBJP74nec0S",
	"input-header": "_3bq4_qqBG7k1XcbhjHXUZQ",
	"preview": "_1L5rScA3w2ksPqycXzvKUs",
	"info": "_1jcVRpRARXE9ad50lXS0HG",
	"input": "_2lWDlnn5P0YMWV3rhdkbjK",
	"row": "nI4TH9fIIz3PEbcf4ai-q",
	"text": "_3m_PDGYNfQA2FE8lobI6jE",
	"top-text": "_1M6m7A3PZ5LEC7OjeGr7qs"
};
module.exports = exports;
