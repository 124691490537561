import { getUserRoleLabel } from './user-roles'
import type { Grant } from '../types/okta'

/**
 Returns a string with all user roles
 @param {Array} array of Grants
 @returns {string} string of all user roles - eg. 'Account Admin, Merchant Owner, User'
*/
export const getAllUserRoles = (grants: Grant[]): string[] => {
  if (!grants || !grants.length) {
    return []
  }

  return grants.map((grant) => getUserRoleLabel(grant.role)).sort()
}

export const UserRoleMappingV3 = {
  MerchantPortalAdmin: getUserRoleLabel('MerchantPortalAdmin'),
  MerchantApiManager: getUserRoleLabel('MerchantApiManager'),
  MerchantClaimsAgent: getUserRoleLabel('MerchantClaimsAgent'),
  MerchantTransactionManager: getUserRoleLabel('MerchantTransactionManager'),
  MerchantPortalViewOnly: getUserRoleLabel('MerchantPortalViewOnly'),
}

export type UserRoleV3 = keyof typeof UserRoleMappingV3

export const isValidRoleV3 = (role: string): boolean => {
  return Object.keys(UserRoleMappingV3).includes(role)
}
